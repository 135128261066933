import { t } from "i18next";
import "./riskprofilepage.css";
import React, { Component } from "react";
import {
  Button,
  NumberSliderView,
  ToggleSingleSelection,
  SideModel,
  ProgressDialog,
  Toast,
  BreadCrumb,
  CircularProgressBar,
} from "../components";
import * as Bl from "../../businesslogic";
import { getRiskProfilePathName, getUserId } from "../common";

const newRiskProfile = {
  userid: "",
  questionid: "",
  choiceid: "",
  questiontext: "",
  choicetext: "",
  questionseqno: "",
};

class RiskProfilePage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const isEditView = params?.isEditView ? params.isEditView : false;
    this.state = {
      selectedIndex: "",
      selectedSlider: 0,
      questions: [],
      answers: [],
      riskProfile: new Bl.RiskProfile({ ...newRiskProfile, userid: getUserId() }),
      isEditView,
      alreadyAnswered: false,
    };
    this.isRenderingAlready = false;
    this.answeredQuestions = [];
    this.userId = getUserId()
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getRiskQuestions();
      this.getUserAnswers();
      setTimeout(() => {
        this.checkIsAllQuesAnsweredAlready();
      }, 2000);
    }
  }

  onClickBackIcon(path) {
    this.props.navigate(path);
  }

  onPressNextButton() {
    const questions = this.state.questions;
    const isLastItem = this.state.selectedSlider === questions?.length - 1;
    if (this.state.alreadyAnswered) {
      this.updateUserAnswers(isLastItem);
    } else {
      this.createUserAnswers(isLastItem);
    }
  }

  onSelectItem(index) {
    const questions = this.state.questions;
    const selectedItem = questions[this.state?.selectedSlider]?.options;
    const selectedOption = selectedItem[index];
    this.setState({ selectedIndex: index, selectedItem: selectedOption });
  }

  /* Check the selected question is already answered
   * If already answered, then get the answer from DB,
   * and find the index of selected choice and display the selected question & selected choice
   * else move to next question with no selected option
   */
  onSelectSlider(index) {
    const answeredQuestions = this.answeredQuestions;
    const isAlreadyAnswered = answeredQuestions.includes(index);
    if (isAlreadyAnswered) {
      this.setState({ showProgress: true }, async () => {
        const data = {
          uuid: this.userId,
        };

        const updatedAnswers = await Bl.RiskProfile.getUserAnswers(data);
        //sort the answers based on questionseqno
        const sortedAnswers = this.sortByQuestionSeqNo(updatedAnswers);
        const options = this.state?.questions[index]?.options;
        const selectedOption = sortedAnswers[index];
        let selectedIndex = "";

        if (options?.length > 0 && selectedOption) {
          selectedIndex = options.findIndex((object) => {
            return (
              object.id === selectedOption.choiceid &&
              object.questionid === selectedOption.questionid
            );
          });
        }

        this.setState({
          answers: sortedAnswers,
          selectedSlider: index,
          selectedIndex,
          alreadyAnswered: isAlreadyAnswered,
          showProgress: false,
        });
      });
    } else {
      answeredQuestions.push(index - 1);
      this.setState({
        selectedSlider: index,
        selectedIndex: "",
        alreadyAnswered: isAlreadyAnswered,
      });
    }
  }

  /*
   * Questions response is in object, but we need array
   * So,in this function get entries from object
   * and prepare keys as questions, and values as options
   */
  prepareQuesNOptions(quesObjs) {
    const quesNoptions = [];
    const items = Object.entries(quesObjs);
    items.forEach((item) => {
      quesNoptions.push({
        question: item[0],
        options: item[1],
        questionseqno: item[1][0].questionseqno,
      });
    });

    //sort the items based on questionseqno
    const sortedQuesNOptions = this.sortByQuestionSeqNo(quesNoptions);

    return sortedQuesNOptions;
  }

  /*
   *  Check all the questions are already answered,
   *  if already answered then navigate to next screen
   */
  checkIsAllQuesAnsweredAlready() {
    const percentage = this.getCompletedPercentage();
    if (percentage === 100) {
      this.showToast(t("Risk Profile: You already answered all the questions"));

      setTimeout(() => {
        this.props.navigate("./riskprofileend");
      }, 4000);
    }
  }

  updateStatusInfo() {
    const date = new Date();
    const updatedDate = date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : '';
    const data = {
      "id": this.userId,
      "is_risk_profile_completed": true,
      "risk_profile_updated_date": updatedDate
    }

    this.setState({ showProgress: true }, () => {
      Bl.RiskProfile.createRiskProfileStatusInfo(data)
        .then(() => {
          this.setState({ showProgress: false, selectedItem: null }, () => {
            this.props.navigate("./riskprofileend");
          })
        }).catch((error) => {
          console.log("RiskProfilePage/updateStatusInfo Error:", error);
          this.showToast(t("someErrorHasOccured"));
        })
    })
  }

  calculateRiskProfile() {
    const data = {
      uuid: this.userId,
    };

    Bl.RiskProfile.getUserRiskProfile(data)
      .then((response) => {
        console.log('risk profile result:', response?.finalriskcategory);
      })
      .catch((error) => {
        console.log("RiskProfilePage/calculateRiskProfile Error:", error);
      });
  }

  /*
   * if there is selectedItem ( any selected option ) then create the answer
   * after creation success if the question is last question ,
   * then move to next screen, else move to next question
   * else display the toast
   */
  createUserAnswers(isLastItem) {
    const selectedItem = this.state.selectedItem;
    if (selectedItem) {
      const newRiskProfile = {
        userid: this.userId,
        questionid: selectedItem.questionid,
        choiceid: selectedItem.id,
        questiontext: selectedItem.questiontext,
        choicetext: selectedItem.choicetext,
        questionseqno: selectedItem.questionseqno,
      };

      const riskProfileObj = new Bl.RiskProfile(newRiskProfile);

      this.setState({ showProgress: true }, () => {
        riskProfileObj
          .createUserAnswers()
          .then((response) => {
            if (isLastItem) {
              this.calculateRiskProfile();
              this.updateStatusInfo();
            } else {
              this.setState({ showProgress: false, selectedItem: null }, () => {
                this.onSelectSlider(this.state.selectedSlider + 1);
              });
            }
          })
          .catch((error) => {
            console.log("RiskProfilePage/createUserAnswers Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      this.showToast(t("pleaseSelectAnyOption"));
    }
  }

  /*
   * if there is selectedItem ( any modified selected option ) then update the answer
   * after update success if the question is last question ,
   * then move to next screen, else move to next question
   * else move to next question
   */
  updateUserAnswers(isLastItem) {
    const { answers, selectedSlider, selectedItem } = this.state;

    if (selectedItem) {
      let prevItem = answers[selectedSlider];
      const riskProfileObj = new Bl.RiskProfile(prevItem);
      riskProfileObj.setChoiceId(selectedItem.id);
      riskProfileObj.setChoiceText(selectedItem.choicetext);

      this.setState({ showProgress: true }, () => {
        riskProfileObj
          .updateUserAnswers()
          .then((response) => {
            if (isLastItem) {
              this.calculateRiskProfile();
              this.updateStatusInfo();
            } else {
              this.setState({ showProgress: false, selectedItem: null }, () => {
                this.onSelectSlider(this.state.selectedSlider + 1);
              });
            }
          })
          .catch((error) => {
            console.log("RiskProfilePage/createUserAnswers Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      this.onSelectSlider(this.state.selectedSlider + 1);
    }
  }

  sortByQuestionSeqNo(list) {
    list.sort(function (a, b) {
      if (a.questionseqno > b.questionseqno) return 1;
      if (a.questionseqno < b.questionseqno) return -1;
      return 0;
    });

    return list;
  }

  /*
   * get questions from api, response is an object,
   * but we need array so prepare it
   */
  getRiskQuestions() {
    this.setState({ showProgress: true }, () => {
      Bl.RiskProfile.getRiskQuestions()
        .then((quesObjs) => {
          const questions = this.prepareQuesNOptions(quesObjs);
          this.setState({
            showProgress: false,
            questions,
          });
        })
        .catch((error) => {
          console.log("RiskProfilePage/getRiskQuestions Error:", error);
          this.showToast(t("someErrorHasOccurred"));
        });
    });
  }

  /*
   * get user answers from api,
   * add the reference for this.answeredQuestions
   * to identify how many questions answered
   */
  getUserAnswers() {
    const data = {
      uuid: this.userId,
    };
    Bl.RiskProfile.getUserAnswers(data)
      .then((result) => {
        //sort the answers based on questionseqno
        const sortedAnswers = this.sortByQuestionSeqNo(result);
        if (this.answeredQuestions?.length === 0 && result?.length > 0) {
          result.forEach((item, index) => {
            this.answeredQuestions.push(index);
          });
        }
        this.setState({
          answers: sortedAnswers,
          selectedSlider: result.length,
        });
      })
      .catch((error) => {
        console.log("RiskProfilePage/getUserAnswers Error:", error);
      });
  }

  getCompletedPercentage() {
    const questions = this.state.questions;
    const answeredQuestions = this.answeredQuestions;
    const percentage = (answeredQuestions?.length / questions?.length) * 100;

    return percentage && percentage !== Infinity ? Math.round(percentage) : 0;
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  renderNavView() {
    const items = getRiskProfilePathName();
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={this.onClickBackIcon.bind(this)}
        onClickItem={this.onClickBackIcon.bind(this)}
      />
    );
  }

  renderCircularProgress() {
    const percent = this.getCompletedPercentage();
    return (
      <div className="rp_circularStyle">
        <CircularProgressBar percent={percent} />
      </div>
    );
  }

  renderTitleView() {
    return (
      <div className="rp_titleView">
        <label className="rp_titleStyle">{t("rphTitle")}</label>
        {this.renderCircularProgress()}
      </div>
    );
  }

  renderQuestionSliderView() {
    const { questions, selectedSlider } = this.state;

    return (
      <div className="rp_sliderView">
        <NumberSliderView
          enableNumbers={this.answeredQuestions}
          sliderContent={questions}
          sliderIndex={selectedSlider}
          onSelectSlider={this.onSelectSlider.bind(this)}
        />
      </div>
    );
  }

  renderQuestionsView() {
    const index = this.state.selectedSlider + 1;
    const questions = this.state.questions;
    const displayQues = questions[this.state?.selectedSlider]?.question;
    const quesTitle = index <= questions.length ? `${t("question")} ${index} .` : t("question");
    return (
      <div className="rp_questionsView">
        <label className="rp_qLabelStyle">{quesTitle}</label>
        <p className="rp_quesStyle">{displayQues}</p>
      </div>
    );
  }

  renderItemView(index) {
    const questions = this.state.questions;
    const options = questions[this.state?.selectedSlider]?.options;
    const displayLabel = options?.[index]?.choicetext;
    return (
      <div className={"rp_itemView"}>
        <label className="rp_itemStyle">{displayLabel}</label>
      </div>
    );
  }

  renderSelectedItemView(index) {
    const questions = this.state.questions;
    const options = questions[this.state?.selectedSlider]?.options;
    const displayLabel = options?.[index]?.choicetext;
    return (
      <div className={"rp_selectedItemView"}>
        <label className="rp_selectedItemStyle">{displayLabel}</label>
      </div>
    );
  }

  renderAnswersView() {
    const questions = this.state.questions;
    const options = questions[this.state?.selectedSlider]?.options;
    const noOfRows = options?.length;
    return (
      <div className="rp_optionsView">
        <label className="rp_oLabelStyle">{t("rphTextOption")}</label>
        <div className="rp_choicesView">
          <ToggleSingleSelection
            noOfRows={noOfRows}
            noOfCols={1}
            selectedIndex={this.state.selectedIndex}
            selectedContentCb={this.renderSelectedItemView.bind(this)}
            contentCb={this.renderItemView.bind(this)}
            onSelectItem={this.onSelectItem.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderQuestionNOptionsView() {
    return (
      <div className="rp_questionsNoptionsView">
        {this.renderQuestionsView()}
        <div className="rp_verticalSeparator" />
        {this.renderAnswersView()}
      </div>
    );
  }

  renderBottomView() {
    return (
      <div className="rp_bottomView">
        <div className="rp_buttonView">
          <Button
            buttonName={t("rppButton")}
            onPressButton={this.onPressNextButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderRiskProfileView() {
    return (
      <div className="rp_riskProfileView">
        {this.renderQuestionSliderView()}
        <div className="rp_separatorStyle" />
        {this.renderQuestionNOptionsView()}
      </div>
    );
  }

  renderContentView() {
    const questions = this.state?.questions;
    const showQuestions = questions?.length > 0;
    return (
      <div className="rp_content">
        {this.renderTitleView()}
        {showQuestions && this.renderRiskProfileView()}
      </div>
    );
  }

  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 4;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  render() {
    return (
      <div className="rp_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderBottomView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default RiskProfilePage;
