import React from "react"
import "./sliderbutton.css"
import expandMore from "../../assets/svgs/expandmore.svg"

class SliderButton extends React.Component {

  onClickSliderButton() {
    if (this.props.onClickSliderButton()) {
      this.props.onClickSliderButton()
    }
  }

  render() {
    return (
      <div className="sb_button_view">
        <img
          src={expandMore}
          alt="expand"
          style={{ width: "30px", cursor: "pointer" }}
          onClick={() => { this.onClickSliderButton() }}
        />
      </div>
    )
  }
}

export default SliderButton
