import React from "react";
import Button from "./button";
import FloatingLabelInput from "./floatinglabelinput";
import './signinform.css';
import Edit from "../../assets/svgs/edit_login.svg";
import OtpView from "./otpview";
import { Link } from "react-router-dom";
import { t } from "i18next";



class SignInForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailId: '',
            phoneNo: '',
            otpArray: ["", "", "", ""],
            isVerifyOtp: false
        }
    }


    onChangeEmailId(value) {
        this.setState({ emailId: value })
    }

    onChangePhoneNo(value) {
        this.setState({ phoneNo: value })
    }

    onChangeOtp(otp) {
        console.log('otp:', otp)
    }

    onClickEdit() {
        this.setState({ isVerifyOtp: false })
    }

    onClickLogin() {
        this.props.navigate('/loginscrn')
    }

    onPressButton() {
        this.setState({ isVerifyOtp: true })
    }

    renderTitleView() {
        const title = this.state.isVerifyOtp ? t('verificationOTP') : t('letsGetStarted');
        return (
            <div className="s_titleView">
                <label className="s_titleStyle">{title}</label>
            </div>
        );
    }

    renderSignInContentView() {
        return (
            <div className="s_contentView">
                {this.renderEmailIdInputView()}
                {this.renderPhoneNoInputView()}
                {this.renderInfoView()}
                {this.renderButtonView()}
                {this.renderLoginLinkView()}
            </div>
        );
    }

    renderDisplayPhoneNoView() {
        return (
            <div className="s_phoneNoView">
                <label className="s_phoneLinkStyle">{t("pleaseEnterOTPSendTo")}</label>
                <label className="s_phoneNoStyle">{`${'+ 91 '}${this.state.phoneNo}`}</label>
                <img src={Edit} alt={Edit} className="s_editStyle" onClick={this.onClickEdit.bind(this)} />
            </div>
        );
    }

    renderOtpView() {
        return (
            <div className="s_otpView">
                <OtpView
                    otp={this.state.otpArray}
                    noOfInputs={4}
                    onChangeOTP={this.onChangeOtp.bind(this)}
                />
            </div>
        );
    }

    renderResendLinkView() {
        return (
            <div className="s_resendLinkView">
                <label className="s_resendLabelStyle">{t("didtReceiveTheCode")}</label>
                <label className="s_resendStyle">{t('resend')}</label>
            </div>
        );
    }

    renderVerifyContentView() {
        return (
            <div className="s_contentView">
                {this.renderDisplayPhoneNoView()}
                {this.renderOtpView()}
                {this.renderResendLinkView()}
                {this.renderButtonView()}
                <div className="s_extraView" />
            </div>
        );
    }

    renderContentView() {
        return (
            <div className="s_contentContainerView">
                {!this.state.isVerifyOtp ?
                    this.renderSignInContentView() :
                    this.renderVerifyContentView()}
            </div>
        );
    }

    renderEmailIdInputView() {
        return (
            <div className="s_inputView">
                <FloatingLabelInput
                    label={t("emailID")}
                    value={this.state.emailId}
                    onChangeValue={this.onChangeEmailId.bind(this)}
                />
            </div>
        );
    }

    renderPhoneNoInputView() {
        return (
            <div className="s_inputView">
                <FloatingLabelInput
                    label={t("mobileNumber")}
                    value={this.state.phoneNo}
                    onChangeValue={this.onChangePhoneNo.bind(this)}
                >
                    <div className="s_codeStyle">{'+ 91'}</div> {/* add +91 label inside FloatingLabelInput */}
                </FloatingLabelInput>
            </div>
        );
    }

    renderInfoView() {
        return (
            <div className="s_infoView">
                <label className="s_infoStyle">{t("youWillReceiveAnOTPToThisNumberForVerification")}</label>
            </div>
        );
    }

    renderButtonView() {
        const buttonName = this.state.isVerifyOtp ? t('verifyOTP') : t('getOTP');
        return (
            <div className="s_buttonView">
                <Button
                    buttonName={buttonName}
                    onPressButton={this.onPressButton.bind(this)}
                />
            </div>
        );
    }

    renderLoginLinkView() {
        return (
            <div className="s_linkView">
                <label className="s_linkLabelStyle">{t("alreadyHaveAnAccount")}</label>
                <div onClick={this.onClickLogin.bind(this)}>
                    <label className="s_linkStyle">{t('login')}</label>
                </div>
            </div>
        );
    }

    renderTermsNConditionView() {
        return (
            <div className="s_labelView">
                <label className="s_labelStyle">
                    {'By continuing, you agree to the '}
                    <a href="https://example.com/terms">terms & conditions</a>
                    {' and '}
                    <a href="https://example.com/privacy">privacy policy</a>
                    {' of Fliber.'}
                </label>
            </div>
        );
    }


    render() {
        return (
            <div className="s_container">
                {this.renderTitleView()}
                {this.renderContentView()}
                {this.renderTermsNConditionView()}
            </div>
        );
    }
}

export default SignInForm;