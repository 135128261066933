import React, { Component } from "react";
import "./banksavingsdetail.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  Header,
  TitleView,
  SideModel,
  Toast,
  ProgressDialog,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getFinancialNonMarketPath } from "../../common";
import * as logger from "../../utils/logger/index";

class BankSavingsDetail extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const investmentData = params?.investmentData ? params.investmentData : {};
    const isEditView = params?.isEditView ? params.isEditView : false;
    this.state = {
      investmentsData: investmentData ? new Bl.Investment(investmentData) : 0,
      bankName: investmentData?.investmentdetails[0]?.bankname
        ? investmentData.investmentdetails[0].bankname
        : "",
      bankAccountNo: investmentData?.investmentdetails[0]?.bankaccountnumber
        ? investmentData.investmentdetails[0].bankaccountnumber
        : "",
      isEditView,
    };
    this.dbData = investmentData
      ? JSON.parse(JSON.stringify(new Bl.Investment(investmentData)))
      : {};
  }

  onSelectItem(path) {
    const localData = new Bl.Investment(this.state.investmentsData);
    localData.revertAllChanges();
    this.setState(
      {
        bankName: this.dbData?.investmentdetails[0]?.bankname
          ? this.dbData.investmentdetails[0].bankname
          : "",
        bankAccountNo: this.dbData?.investmentdetails[0]?.bankaccountnumber
          ? this.dbData.investmentdetails[0].bankaccountnumber
          : "",
      },
      () => {
        this.props.navigate(path);
      }
    );
  }

  checkIsDataValid(data) {
    let isValid = true;
    const invesmentDetails = data?.investmentdetails[0]
      ? data.investmentdetails[0]
      : "";
    let message = "";
    if (!this.isBankNamevalid(invesmentDetails.bankname)) {
      isValid = false;
      message = t("bankSavingsToastBankName");
    } else if (!this.isBankAccNoValid(invesmentDetails.bankaccountnumber)) {
      isValid = false;
      message = t("bankSavingsToastBankAccNumber");
    } else if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("bankSavingsToastCurrentBalance");
    }
    return { isValid, message };
  }

  isBankNamevalid(bankname) {
    return bankname && bankname.length > 0;
  }

  isBankAccNoValid(bankaccountnumber) {
    return bankaccountnumber && bankaccountnumber.length === 4;
  }
  isAmountValid(amount) {
    return amount && amount > 0;
  }

  prepareBanksData() {
    const fields = {
      bankname: this.state.bankName || '',
      bankaccountnumber: this.state.bankAccountNo || '',
    };
    const bankDetails = [];
    const bankDetail = {};
    let oneFieldAvail = false;
    Object.keys(fields).forEach((key) => {
      if (fields[key] !== '') {
        oneFieldAvail = true;
        bankDetail[key] = fields[key];
      }
    });
    if (oneFieldAvail) {
      bankDetails.push(bankDetail);
    }
    return { bankDetails };
  }

  createUserInvestment(data) {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    investmentObj.setInvestmentDetails(data.bankDetails);
    const isDataValid = this.checkIsDataValid(investmentObj);
    console.log({investmentObj})
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .createInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/banksavingsdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserInvestment(data) {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    investmentObj.setInvestmentDetails(data.bankDetails);
    const isDataValid = this.checkIsDataValid(investmentObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .updateInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/banksavingsdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  onChangeBankName(value) {
    this.setState({ bankName: value }, () => {
      const data = this.prepareBanksData();
      const investment = this.state.investmentsData;
      investment.setInvestmentDetails(data.bankDetails);
    });
  }

  onChangeAccountNo(value) {
    this.setState({ bankAccountNo: value }, () => {
      const data = this.prepareBanksData();
      const investment = this.state.investmentsData;
      investment.setInvestmentDetails(data.bankDetails);
    });
  }

  onChangecurrentValue(value) {
    const investment = this.state.investmentsData;
    investment.setCurrentValue(value ? Number(value) : 0);
    this.forceUpdate();
  }

  onPressSaveButton() {
    const data = this.prepareBanksData();
    if (this.state.isEditView) {
      this.updateUserInvestment(data);
    } else {
      this.createUserInvestment(data);
    }
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

 

  renderNavView() {
    const items = getFinancialNonMarketPath("BankSaving");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        dbData={this.dbData}
        localData={this.state.investmentsData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsFNM1");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    selectionView = false,
    selectedOptions = [],
    type = ""
  ) {
    return (
      <div className="bsd_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
          type={type}
        />
      </div>
    );
  }

  renderBankSavingsDetails() {
    const investmentData = this.state.investmentsData;
    const balance = investmentData?.currentvalue ? investmentData?.currentvalue : "";
    const bankname = this.state.bankName;
    const bankaccountnumber = this.state.bankAccountNo;
    return (
      <div className="bsd_description_view">
        <div className="bsd_input_row">
          {this.renderInputView(
            t("bsInputBankName"),
            bankname,
            this.onChangeBankName.bind(this)
          )}
          {this.renderInputView(
            t("bsInputAccountNo"),
            bankaccountnumber,
            this.onChangeAccountNo.bind(this),
            false,
            false,
            false,
            [],
            "number"
          )}
        </div>
        <div className="bsd_sec_row">
          {this.renderInputView(
            t("bsInputBalance"),
            balance,
            this.onChangecurrentValue.bind(this),
            true
          )}
        </div>
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="bsd_bottomView">
        <div className="bsd_buttonView">
          <div className="bsd_save">
            <Button
              buttonName={t("bsButton1")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderContentView() {
    return (
      <div className="bsd_content_view">
        {this.renderTitleView()}
        {this.renderBankSavingsDetails()}
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="bsd_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default BankSavingsDetail;
