import React, { useState } from "react";
import { Modal } from "rsuite";
import './roleselectormodal.css';
import CloseIcon from '../../assets/svgs/ic_close.svg';
import { Button, FloatingLabelInput } from "../components";
import { t } from "i18next";
import { USER_ROLE } from "../constants";
import { getRoleLabel } from "../common";

const RoleSelectorModal = (props) => {
  const [state, setState] = useState({
    role: t(USER_ROLE.CUSTOMER)
  })

  const onClickClose = () => {
    if (props.hideModal) {
      props.hideModal();
    }
  }

  const onPressSelect = () => {
    const role = getRoleLabel(state.role);
    if (props.onSelectRole) {
      props.onSelectRole(role)
    }
  }

  const onSelectRole = (value) => {
    setState((prevState) => ({
      ...prevState,
      role: value
    }))
  }

  const renderHeaderView = () => {
    return (
      <div className="rsm_headerView">
        <label className="rsm_titleStyle">{t('Select the role for user')}</label>
        <img src={CloseIcon} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderInputView = (
    label,
    value,
    onChange,
    selectionView = false,
    selectedOptions = [],
    className = "",
    type = "text",
    maxLength,
    disabled
  ) => {
    return (
      <div className="rsm_inputView">
        <FloatingLabelInput
          label={label}
          value={value}
          onChangeValue={onChange}
          type={type}
          maxLength={maxLength}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
          className={className}
          disabled={disabled}
        />
      </div>
    );
  }

  const renderInputsView = () => {
    return (
      <div className="rsm_inputsView">
        {renderInputView(t('role'), state.role, onSelectRole, true, [t(USER_ROLE.CUSTOMER), t(USER_ROLE.USER)])}
      </div>
    );
  }

  const renderButtonView = () => {
    return (
      <div className="rsm_buttonView">
        <Button
          buttonName={t('Select Role')}
          onPressButton={onPressSelect}
        />
      </div>
    );
  }

  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      className={'rsm_container'}
    >
      <div className={'rsm_content'}>
        {renderHeaderView()}
        {renderInputsView()}
        {renderButtonView()}
      </div>
    </Modal>
  );
}

export default RoleSelectorModal;