import "./riskprofileslider.css";
const RiskProfileSlider = (props) => {
  const { value, min, max, markStep, handleChange, showAge, showPercentage } = props;
  const marks = [];
  for (let i = min; i <= max; i += markStep) {
    const value = ["C", "MC", "B", "MA", "A"]
    const index = Math.floor((i - min) / markStep);
    marks.push(
      <>
        <div
          key={i}
          className="rps_mark"
          style={{ left: `${((i - min) / (max - min)) * 100}%` }}
        >
          <div className='rps_line-style' />
          <label className='rps_mark-label'>{i === max ? `${i}+` : i}</label>
        </div>
        {index < value.length && (
          <div>
            <label className='rps_mark-label-portions'>{value[index]}</label>
          </div>
        )}
      </>
    );
  }

  function getPosition(value) {
    //value of the slider will be greater than or equal to max value, then calculate position using max value
    const val = value >= max ? max : value;
    const newValue = Number((val - min) * 100 / (max - min));
    const newPosition = 10 - (newValue * 0.2);
    return `calc(${newValue}% + (${newPosition}px))`;
  }

  return (
    <div className='rps_slider'>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        id="rps_slider"
        onChange={handleChange}
        style={{
          backgroundImage: `linear-gradient(to right, #EFB741 0%, #EFB741 ${((value - min) / (max - min)) * 100}%, #E0E0E0 ${((value - min) / (max - min)) * 100}%, #E0E0E0 100%)`
        }}
      />
      {value !== 0 &&
        <div
          className={showAge ? "show-age-value" : "show-value"}
          style={{ left: getPosition(value) }}
        >
          {props.renderCustomLabel ? props.renderCustomLabel(value) :
            <label className="rps_current-value">{showPercentage ? value + '%' : value}</label>}
        </div>}
      <div className='rps_range'>
        {marks}
      </div>
    </div>
  );
}

export default RiskProfileSlider;
