import React, { Component } from "react";
import "./otherfinancialhome.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  TitleView,
  SideModel,
} from "../../components";
import OtherHome from "../../../assets/svgs/fasset/other_home.svg";
import { INVESTMENT_CATEGORY } from "../../constants";
import { getFinancialMarketPath, getUserId } from "../../common";
import * as Bl from "../../../businesslogic";

const newInvestment = {
  investmentcategory: INVESTMENT_CATEGORY.OTHER_MARKET,
  currentvalue: 0,
  annualcontribution: 0,
  maturityyear: 0,
  maturityvalue: 0,
  expectedror: 0,
  userid: '',
  contributiongrowthrate: 0,
  investementtype: "YEAR",
  investmentdetails: [
    {
      isothermarkets: true,
      isfliberadvise: false
    },
  ],
  investmentdescription: "",
  fvatretirement: 0,
  maturityage: 0,
  userage: 0,
  isincludefinancialplan: false
};

class OtherFinancialHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInvestmentData: new Bl.Investment({ ...newInvestment, userid: getUserId() }),
      investmentData: []
    };
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressAddProperty() {
    this.props.navigate("/otherfinancialdetail", {
      state: {
        investmentData: this.state.newInvestmentData,
        isEditView: false,
      },
    });
  }



  renderNavView() {
    const items = getFinancialMarketPath("Others");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const information = "";
    const title = t("others");
    return <TitleView title={title} information={information} />;
  }

  renderProfileView() {
    return (
      <div className="ofh_profile_view">
        <img src={OtherHome} alt={t("others")} className="ofh_icon" />
        <div className="ofh_info_view">
          {t("includeStocksThatYouHaveEarmarkedForRetirement")}
        </div>
        <div className="ofh_add_property">
          <Button
            buttonName={t("addOthers")}
            onPressButton={this.onPressAddProperty.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }

  renderContentView() {
    return (
      <div className="ofh_content_view">
        {this.renderTitleView()}
        {this.renderProfileView()}
      </div>
    );
  }

  render() {
    return (
      <div className="ofh_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default OtherFinancialHome;
