import React, { Component } from 'react';


export default class RiskProfileNeedle extends Component {

    renderNeedle = opts => {
        let x1 = opts.cX,
            y1 = opts.cY - opts.needleWidth / 2,
            x2 = opts.cX,
            y2 = opts.cY + opts.needleWidth / 2,
            x3 = 165,
            y3 = opts.cY;

        let needleAngle = 0;
        let currentValue = opts.currentValue;
        if (currentValue === "Aggressive") {
            needleAngle += 230;
        }
        if (currentValue === "Moderately Aggressive") {
            needleAngle += 185;
        }
        if (currentValue === "Balanced") {
            needleAngle += 130;
        }
        if (currentValue === "Moderately Conservative") {
            needleAngle += 80;
        }
        if (currentValue === "Conservative") {
            needleAngle += 20;
        }

        needleAngle -= 127;
        let needleElm = null;
        if (opts.needleSharp) {
            needleElm = (
                <polygon
                    points={`${x1},${y1} ${x2},${y2} ${x3},${y3}`}
                    fill={opts.needleColor}
                />
            );
        } else {
            needleElm = (
                <line
                    x1={opts.cX}
                    y1={opts.cY}
                    x2={opts.diameter}
                    y2={opts.cY}
                    fill="none"
                    strokeWidth={opts.needleWidth}
                    stroke={opts.needleColor}
                />
            );
        }

        return (
            <g className="needle">
                <g transform={`rotate(${needleAngle} ${opts.cX} ${opts.cY})`}>
                    {needleElm}
                </g>
                <circle
                    cx={opts.cX}
                    cy={opts.cY}
                    r={opts.needleBaseSize}
                    fill={opts.needleBaseColor}
                />
            </g>
        );
    };

    renderDescribeText(value) {
        const displayText = [];
        let yVal = 145;
        const displayValues = value.split(" ");
        displayValues.map((displayValue, index) => {
            displayText.push(
                <text
                    key={index}
                    x={100}
                    y={yVal}
                    fontFamily={'RubikRegular'}
                    fontSize={14}
                    width={"100"}
                    height={"auto"}
                    textAnchor="middle"
                    fill={'#013F60'}
                >
                    {displayValue}
                </text>
            )
            yVal += 20
        })

        return displayText;
    }

    render() {
        let opts = Object.assign({}, this.props);
        let { size, dialWidth } = opts;
        let cX = size / 2;
        let cY = size / 2;
        let radius = (size - 2 * dialWidth) / 2;
        let diameter = 2 * radius;
        let circumference = 2 * Math.PI * radius;
        opts = Object.assign(opts, {
            cX,
            cY,
            radius,
            diameter,
            circumference,
        });

        return (
            <svg
                className={opts.className}
                height={size}
                width={size}
                viewBox={`0 0 ${size} ${size}`}>
                <g transform={`rotate(-90 ${cX} ${cY})`}>{this.renderNeedle(opts)}</g>
                {this.renderDescribeText(opts.currentValue)}
            </svg>
        );
    }
}

RiskProfileNeedle.defaultProps = {
    size: 200,

    dialWidth: 10,
    dialColor: '#eee',

    tickLength: 3,
    tickWidth: 1,
    tickColor: '#cacaca',
    tickInterval: 10,

    maximumValue: 100,
    currentValue: 25,
    progressWidth: 5,
    progressColor: '#3d3d3d',
    progressRoundedEdge: true,
    downProgressColor: 'red',
    progressFont: 'Serif',
    progressFontSize: '40',

    needle: true,
    needleBaseSize: 15,
    needleBaseColor: '#013F60',
    needleWidth: 5,
    needleSharp: false,
    needleColor: '#013F60',
};
