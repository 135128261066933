import React, { Component } from "react";
import { t } from "i18next";
import "./epfdetails.css";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  TitleView,
  ToggleSwitch,
  Toast,
  SideModel,
  ProgressDialog,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getFinancialNonMarketPath } from "../../common";
import * as logger from "../../utils/logger/index";

class EPFDetails extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const investmentData = params?.investmentData ? params.investmentData : "";
    const isEditView = params?.isEditView ? params.isEditView : false;
    this.state = {
      investmentsData: investmentData ? new Bl.Investment(investmentData) : {},
      initialType:
        investmentData &&
          investmentData.investmentdetails &&
          investmentData.investmentdetails[0]?.isEPFO
          ? investmentData.investmentdetails[0].isEPFO
          : false,
      type:
        investmentData &&
          investmentData.investmentdetails &&
          investmentData.investmentdetails[0]?.isEPFO
          ? investmentData.investmentdetails[0].isEPFO
          : false,
      isEditView,
      interestRate: "",
    };
    this.dbData = investmentData
      ? JSON.parse(JSON.stringify(new Bl.Investment(investmentData)))
      : {};
  }

  isStateValueChanged() {
    const { initialType, type } = this.state;
    return initialType !== type;
  }

  onSelectItem(path) {
    const localData = new Bl.Investment(this.state.investmentsData);
    localData.revertAllChanges();
    this.setState(
      {
        type:
          this.dbData &&
            this.dbData.investmentdetails &&
            this.dbData.investmentdetails[0]?.isEPFO
            ? this.dbData.investmentdetails[0].isEPFO
            : false,
      },
      () => {
        this.props.navigate(path);
      }
    );
  }

  isAmountValid(amount) {
    return amount && amount > 0;
  }

  isAnualContribution(annualcontribution) {
    return annualcontribution && annualcontribution > 0;
  }
  isAnualGrowthRateContribution(annualcontributionrate) {
    return annualcontributionrate && annualcontributionrate > 0;
  }
  isEPFInterestRate(expectedror) {
    return expectedror && expectedror > 0;
  }

  handleToggleSwitchChange(selectedItem, isSelected) {
    const investment = this.state.investmentsData;
    investment.setIsIncludeFinancialPlan(isSelected);
    this.forceUpdate();
  }

  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("epfToastBalance");
    } else if (!this.isAnualContribution(data.annualcontribution)) {
      isValid = false;
      message = t("epfToastAnnualContribution");
    } else if (
      !this.isAnualGrowthRateContribution(data.contributiongrowthrate)
    ) {
      isValid = false;
      message = t("epfToastGrowthRate");
    } else if (
      this.state.type === false &&
      !this.isEPFInterestRate(data.expectedror)
    ) {
      isValid = false;
      message = t("epfToastInterestRate");
    }
    return { isValid, message };
  }

  prepareEPFODetails() {
    const fields = {
      isEPFO: this.state.type,
    };
    const epfToggleDetails = [];
    const epfToggleDetail = {};
    let oneFieldAvail = false;
    Object.keys(fields).forEach((key) => {
      if (fields[key] !== "") {
        oneFieldAvail = true;
        epfToggleDetail[key] = fields[key];
      }
    });
    if (oneFieldAvail) {
      epfToggleDetails.push(epfToggleDetail);
    }
    return { epfToggleDetails };
  }
  createUserInvestment(data) {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    investmentObj.setInvestmentDetails(data.epfToggleDetails);
    const isDataValid = this.checkIsDataValid(investmentObj);
    console.log({ investmentObj })
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .createInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/epfdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserInvestment(data) {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    investmentObj.setInvestmentDetails(data.epfToggleDetails);
    const isDataValid = this.checkIsDataValid(investmentObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .updateInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/epfdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  onPressSaveButton() {
    const data = this.prepareEPFODetails();
    if (this.state.isEditView) {
      this.updateUserInvestment(data);
    } else {
      this.createUserInvestment(data);
    }
  }

  onChangeToggleType = (selectedItem, checked) => {
    if (checked) {
      const investment = this.state.investmentsData;
      investment.setExpectedror(0);
    }
    this.setState({ type: checked, interestRate: "" });
  };

  onChangeBalance(value) {
    const investment = this.state.investmentsData;
    investment.setCurrentValue(value ? Number(value) : 0);
    this.forceUpdate();
  }

  onChangeAnnualContribution(value) {
    const investment = this.state.investmentsData;
    investment.setAnnualContribution(value ? Number(value) : 0);
    this.forceUpdate();
  }

  onChangeGrowthrate(value) {
    const investment = this.state.investmentsData;
    investment.setContributionGrowthRate(value ? Number(value) : 0);
    this.forceUpdate();
  }

  onChangeInterestRate(value) {
    const investment = this.state.investmentsData;
    investment.setExpectedror(value ? Number(value) : 0);
    this.forceUpdate();
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  renderNavView() {
    const items = getFinancialNonMarketPath("EPF");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        isStateValueChanged={this.isStateValueChanged()}
        dbData={this.dbData}
        localData={this.state.investmentsData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsFNM4");
    return <TitleView title={title} information={""} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    selectionView = false,
    selectedOptions = [],
    type = ""
  ) {
    return (
      <div className="elo_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
          type={type}
        />
      </div>
    );
  }

  renderToggleSwitch(label, checked, onChange) {
    return (
      <ToggleSwitch
        checked={this.state.type === true}
        checkedChildren={t("epfToggle1")}
        unCheckedChildren={t("epfToggle2")}
        onChangeToggle={onChange}
      />
    );
  }

  renderToggleView(label, checked, onChange) {
    return (
      <div className="elo_toggle_view">
        <label className="elo_toggle_text">{label}</label>
        <ToggleSwitch
          checkedChildren={t("fdToggle1")}
          unCheckedChildren={t("fdToggle2")}
          onChangeToggle={this.handleToggleSwitchChange.bind(this)}
          defaultChecked={checked}
        />
      </div>
    );
  }

  renderInputManual() {
    const investmentData = this.state.investmentsData;
    const balance = investmentData?.currentvalue
      ? investmentData?.currentvalue
      : "";
    const contribution = investmentData?.annualcontribution
      ? investmentData?.annualcontribution
      : "";
    const growthRate = investmentData?.contributiongrowthrate
      ? investmentData?.contributiongrowthrate
      : "";
    const interestRate = investmentData?.expectedror
      ? investmentData?.expectedror
      : "";
    const isEPFO = this.state.type;
    return (
      <div className="elo_expenselist">
        <div className="elo_title_row">
          <div className="elo_title">{t("epfTg1Text")}</div>
          {this.renderToggleSwitch(
            t("check"),
            this.state.type,
            this.onChangeToggleType.bind(this)
          )}
        </div>
        <div className="elo_input_row">
          <div className="elo_fir_row">
            {this.renderInputView(
              t("epfInputBalance"),
              balance,
              this.onChangeBalance.bind(this),
              true
            )}
            {this.renderInputView(
              t("epfInputContribution"),
              contribution,
              this.onChangeAnnualContribution.bind(this),
              true
            )}
          </div>
          <div className="elo_sec_row">
            {this.renderInputView(
              t("epfInputGrowthRate"),
              growthRate,
              this.onChangeGrowthrate.bind(this),
              false,
              true,
              false,
              [],
              "number"
            )}
            {this.renderInputView(
              t("epfInputInterestRate"),
              interestRate,
              isEPFO ? undefined : this.onChangeInterestRate.bind(this),
              false,
              true,
              false,
              [],
              "number"
            )}
          </div>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <div className="elo_bottomView">
        <div className="elo_buttonView">
          <div className="elo_save">
            <Button
              buttonName={t("epfButton1")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  renderContentView() {
    const investment = this.state.investmentsData;
    return (
      <div className="elo_content_view">
        {this.renderTitleView()}
        {this.renderInputManual()}
        <div className="elo_seperatorStyle" />
        <div className="elo_retirement_toggle">
          {this.renderToggleView(t("fdTg1Text"), investment?.isincludefinancialplan)}
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  render() {
    return (
      <div className="elo_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButton()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default EPFDetails;
