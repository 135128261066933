import React from "react";
import { Space, Switch } from "antd";
import "./toggleswitch.css";

const ToggleSwitch = (props) => (
  <Space direction="vertical">
    <Switch
      checked={props.checked}
      checkedChildren={props.checkedChildren}
      unCheckedChildren={props.unCheckedChildren}
      style={{ ...props.style, backgroundColor: "#0E4969"}}
      defaultChecked={props.defaultChecked}
      onChange={(checked, e) => {
        if (props.onChangeToggle) {
          props.onChangeToggle(
            checked === true ? props.checkedChildren : props.unCheckedChildren,
            checked
          );
        }
      }}
      data-app-id="my-app-id"
    />
  </Space>
);

export default ToggleSwitch;
