import React from "react";
import { Message } from "rsuite";
import "./toast.css";

class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCloseMessage() {
    if (this.props.onCloseMessage) {
      this.props.onCloseMessage();
    }
  }
  
  render() {
    return this.props.showToast ? (
      <div className="t_container">
        <Message
          closable
          showIcon={this.props.showIcon}
          type={this.props.type}
          className={"t_messageStyle"}
          onClose={this.onCloseMessage.bind(this)}
        >
          {this.props.toastMessage}
        </Message>
      </div>
    ) : (
      <div />
    );
  }
}

export default Toast;

Toast.defaultProps = {
  showIcon: true,
  type: "info",
  toastMessage: "",
};
