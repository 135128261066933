import React, { Component } from 'react';
import { t } from "i18next";
import './retirementmodalview.css';
import 'rsuite/dist/rsuite.min.css';
import { Modal } from 'rsuite';
import CloseIcon from '../../assets/svgs/ic_close.svg';
import LavislyIcon from '../../assets/svgs/live_lavisly_icon.svg';
import LifeStyle from '../../assets/svgs/mlifestyle_icon.svg';
import LiveModestly from '../../assets/svgs/liveMod_icon.svg';
import { Button } from "../components";

class RetirementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: ''
    }
  }

  onClickCancel() {
    if (this.props.onClickCancel) {
      this.props.onClickCancel()
    }
  }

  onPressNextButton() {
    if (this.props.onPressNextButton) {
      this.props.onPressNextButton(this.state.selectedItem)
    }
  }

  onSelectItem(label) {
    this.setState({ selectedItem: label })
  }

  renderTitle() {
    return (
      <div className='hh_title_view'>
        <label className='hh_title_style'>{t("retirementModalTitle")}</label>
        <img
          src={CloseIcon}
          alt={"cancel"}
          className={"hh_cancel_icon"}
          onClick={this.onClickCancel.bind(this)}
        ></img>
      </div>
    )
  }

  renderItemView(source, label) {
    return (
      <div
        className={'hh_itemStyle'}
        style={{
          borderWidth: label === this.state.selectedItem ? 1 : 0
        }}
        onClick={() => { this.onSelectItem(label) }}
      >
        <img
          src={source}
          alt={label}
          className={"hh_itemIconStyle"}
        ></img>
        <div className={'hh_itemTextStyle'}>{label}</div>
      </div>
    )
  }

  renderItemsView() {
    return (
      <div className="hh_itemsView">
        {this.renderItemView(LavislyIcon, t('retirementModal1'))}
        {this.renderItemView(LifeStyle, t('retirementModal2'))}
        {this.renderItemView(LiveModestly, t('retirementModal3'))}
      </div>
    )
  }

  renderButton() {
    return (
      <div className='hh_nextButton_view'>
        <Button
          buttonName={t('retirementModalButton')}
          onPressButton={this.onPressNextButton.bind(this)}
        />
      </div>
    )
  }

  render() {
    return (
      <Modal
        open={this.props.showModal}
        onClose={this.props.hideModal}
        dialogClassName={'hh_container'}
      >
        <div className={'hh_content'}>
          {this.renderTitle()}
          {this.renderItemsView()}
          {this.renderButton()}
        </div>
      </Modal>
    )
  }
}

export default RetirementModal