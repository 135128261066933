import React, { Component } from "react";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  ProgressDialog,
  Slider,
  TitleView,
  Toast,
  ToggleSwitch,
} from "../components";
import { t } from "i18next";
import "./healthcareexpensepage.css";
import * as Bl from "../../businesslogic";
import { getCurrentYear } from "../utils/datetime";
import { GOAL_CATEGORY, DEFAULT_OFFSET, GOAL_FREQUENCY_TYPE, GOAL_EXPENSE_TYPE } from "../constants";
import { calculate_age, getAdvisorId, getGoalsPathName, getUserDOB, getUserId } from "../common";
import { getCurrentAge, getGoalConfigInfo } from "../utils/common";

const newGoal = {
  userid: '',
  goalcategory: GOAL_CATEGORY.HEALTH_CARE,
  goalstartyear: 0,
  goalendyear: 0,
  goalamountpv: 0,
  goalfrequency: 1,
  advisorid: getAdvisorId(),
  goalfrequencytype: GOAL_FREQUENCY_TYPE.MONTHLY,
  goalbucketcode: "",
  retirewanttype: "",
  monthlyexpenselist: [],
  goaldescription: "",
  goalinflationrate: 0,
  goalamountfv: 0,
  homeloanstatus: false,
  downpayment: 0,
  goaldetails: [],
  goalstartage: 0,
  goalendage: 0,
  userage: 0,
  expensetype: GOAL_EXPENSE_TYPE.LIFE_LONG
};
class HealthCareExpensePage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const isEditView = params?.isEditView ? params.isEditView : false;
    const goalData = params?.goalData;
    this.state = {
      startValue: isEditView ? goalData.goalstartyear : getCurrentYear() + 1,
      endValue: isEditView ? goalData.goalendyear : getCurrentYear() + 2,
      value: isEditView ? goalData.goalamountpv : "",
      inflationRate: isEditView ? goalData.goalinflationrate : 0,
      currentAge: 0,
      type: isEditView && goalData.expensetype === GOAL_EXPENSE_TYPE.ONE_TIME ? goalData.expensetype : GOAL_EXPENSE_TYPE.RECURRING,
      recurringType: isEditView ? goalData.expensetype : GOAL_EXPENSE_TYPE.LIFE_LONG,
      isEditView: isEditView ? isEditView : false,
      newGoalData: isEditView ? new Bl.Goals(goalData) : new Bl.Goals({ ...newGoal, userid: getUserId() }),
    };
    this.isRenderingAlready = false;
    this.dbValue = this.state.newGoalData;
  }
  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      !this.state.isEditView && this.getInflationRate();
      this.getCurrentAge();
    }
  }
  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true, fetchingCurrentAge: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const goalsObj = this.state.newGoalData;
          goalsObj.setUserAge(currentAge);
          this.userage = currentAge;
          this.dbValue.userage = currentAge;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
            fetchingCurrentAge: false
          }, () => {
            this.getDBValue();
          });
        })
        .catch((error) => {
          console.log("HealthCare ExpensePage.js/getCurrentYear Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getInflationRate() {
    //get inflation rate from API and use it in local
    this.setState({ showProgress: true, fetchingInflationRate: true }, () => {
      getGoalConfigInfo(GOAL_CATEGORY.HEALTH_CARE)
        .then((configList) => {
          const configInfo = configList;
          const goalsObj = this.state.newGoalData;
          const inflationRate = Number(configInfo.inflationrate);
          goalsObj.setGoalInflationRate(inflationRate);
          this.inflationRate = inflationRate;
          this.dbValue.goalinflationrate = inflationRate;
          this.setState({
            inflationRate: inflationRate,
            showProgress: false,
            fetchingInflationRate: false
          }, () => {
            this.getDBValue()
          });
        })
        .catch((error) => {
          console.log("HealthcareExpensePage.js/getInflationRate Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getDBValue() {
    if (this.state.fetchingCurrentAge === false) {
      const dbValue = this.state.newGoalData
        ? JSON.parse(JSON.stringify(this.state.newGoalData))
        : {};
      this.setState({ dbValue })
    }
  }

  onSelectItem(path) {
    const localData = new Bl.Goals(this.state.newGoalData)
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeAmountValue(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalAmountPv(value ? Number(value) : 0);
    this.setState({ value });
  }
  onChangeInflationRate(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalInflationRate(value ? Number(value) : 0);
    this.setState({ inflationRate: value, viewExpense: false });
  }

  onSelectRecurringType(selectedType) {
    console.log(selectedType, this.state.type)
    const goals = this.state.newGoalData;
    goals.setExpenseType(selectedType);
    this.setState({ recurringType: selectedType });
  }

  createHealthCareGoal() {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (goalObj.goalstartyear === 0) {
      const currentYear = getCurrentYear();
      const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
      goalObj.setStartYear(this.state.startValue);
      goalObj.setGoalStartAge(startAge)
    }
    if (this.state.type === t("healthcareToggle1") && goalObj.goalendyear === 0) {
      const endYear = getCurrentYear() + 1;
      const endAge = calculate_age(this.state.endValue, endYear, this.state.currentAge)
      goalObj.setEndYear(this.state.endValue);
      goalObj.setGoalEndAge(endAge)
    }

    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        goalObj
          .createGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/goalscategorize", { state: { path: "Healthcare" } });
            });
          })
          .catch((error) => {
            console.log(
              "HealthCareExpensePage/createGoalError:",
              error
            );
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateHealthCareGoal() {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (this.state.type === t("healthcareToggle1") && goalObj.goalendyear === 0) {
      const endYear = getCurrentYear() + 1;
      const endAge = calculate_age(this.state.endValue, endYear, this.state.currentAge)
      goalObj.setEndYear(this.state.endValue);
      goalObj.setGoalEndAge(endAge)
    }
    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        goalObj
          .updateGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/goalscategorize", { state: { path: "Healthcare" } });
            });
          })
          .catch((error) => {
            console.log(
              "HealthCareExpensePage/updateGoal Error:",
              error
            );
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  isStartAndEndYearValid(goalType, startYear, endYear) {
    if (goalType === GOAL_EXPENSE_TYPE.RECURRING &&
      this.state.recurringType === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD) {
      return startYear !== 0 && startYear < endYear;
    } else {
      return startYear !== 0;
    }
  }
  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    const goalType = this.state.type;
    const startYear = data.goalstartyear ? data.goalstartyear : 0;
    const endYear = data.goalendyear ? data.goalendyear : 0;
    if (!this.isStartAndEndYearValid(goalType, startYear, endYear)) {
      isValid = false;
      message = t("healthcareToastYear");
    } else if (!this.isValueValid(data.goalamountpv)) {
      isValid = false;
      message = t("healthcareToastValue");
    }
    return { isValid, message };
  }

  isValueValid(value) {
    return value > 0;
  }

  checkIsEndYearValid(startYear, endYear) {
    return startYear < endYear;
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  onPressSaveButton() {
    if (!this.state.isEditView) {
      this.createHealthCareGoal();
    }
    else {
      this.updateHealthCareGoal();
    }
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const startAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const goals = this.state.newGoalData;
    goals.setStartYear(Number(newValue));
    goals.setGoalStartAge(startAge)
    this.setState({ startValue: Number(newValue) });
  }

  handleChangeEnd(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const endAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const goals = this.state.newGoalData;
    const isValid = this.checkIsEndYearValid(goals.goalstartyear, newValue);
    if (isValid) {
      goals.setEndYear(Number(newValue));
      goals.setGoalEndAge(endAge);
      this.setState({ endValue: Number(newValue) });
    } else {
      this.showToast(t("healthcareToastYear"));
    }
  }

  handleToggleSwitchChange(selectedItem, checked) {
    const goalObj = this.state.newGoalData;
    const frequencyValue =
      selectedItem === t("healthcareToggle2")
        ? GOAL_EXPENSE_TYPE.RECURRING
        : GOAL_EXPENSE_TYPE.ONE_TIME;
    const recurringType = selectedItem === t("healthcareToggle2")
      ? GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD
      : GOAL_EXPENSE_TYPE.LIFE_LONG;
    goalObj.setExpenseType(frequencyValue);
    const endValue = selectedItem === t("healthcareToggle2") ? this.state.endValue : getCurrentYear() + 2;
    goalObj.setEndYear(0);
    this.setState({ type: frequencyValue, endValue, recurringType });
  }

  navigateTo = (path) => {
    window.location.href = path;
  };

  handleClick = (event, index) => {
    event.preventDefault();
    const { pathMap } = this.props;
    const path = pathMap[this.props.items[index]];
    // Call the navigateTo function passed in as a prop with the path as an argument
    this.props.onClick(path);
  };

  renderNavView() {
    const items = getGoalsPathName("Healthcare");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        dbData={this.dbValue}
        localData={this.state.newGoalData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("healthcareTitle");
    return <TitleView title={title} information={t("healthcareInfo")} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="hce_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        ></FloatingLabelInput>
      </div>
    );
  }

  renderSelectionView() {
    return (
      <div className="cge_checkboxOuterView">
        <div className="cge_checkboxView">
          <input
            checked={this.state.recurringType === GOAL_EXPENSE_TYPE.LIFE_LONG}
            type={"checkbox"}
            className={"cge_checkboxStyle"}
            onChange={() => {
              this.onSelectRecurringType(GOAL_EXPENSE_TYPE.LIFE_LONG)
            }}
          />
          <label className="cge_checkboxLabelStyle">{t('healthcareToggle3')}</label>
        </div>
        <div className="cge_checkboxView">
          <input
            checked={this.state.recurringType === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD}
            type={"checkbox"}
            className={"cge_checkboxStyle"}
            onChange={() => {
              this.onSelectRecurringType(GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD)
            }}
          />
          <label className="cge_checkboxLabelStyle">{t('healthcareToggle4')}</label>
        </div>
      </div>
    );
  }

  renderExpenseType() {
    return (
      <div className="hce_description_view">
        {this.renderInputView(
          t("healthcareInput"),
          this.state.value,
          this.onChangeAmountValue.bind(this),
          true
        )}
        <div className="hce_expense">
          <label className="hce_expense_text">{t("healthcareToggle")}</label>
          <div className="hce_toggle">
            <ToggleSwitch
              checked={this.state.type === GOAL_EXPENSE_TYPE.ONE_TIME}
              checkedChildren={t("healthcareToggle1")}
              unCheckedChildren={t("healthcareToggle2")}
              onChangeToggle={this.handleToggleSwitchChange.bind(this)}
            />
          </div>
          {this.state.type !== GOAL_EXPENSE_TYPE.ONE_TIME && this.renderSelectionView()}
        </div>
      </div>
    );
  }

  renderStartYearSlider() {
    const currentYear = getCurrentYear();
    const age = calculate_age(this.state.startValue, currentYear, this.state.currentAge);
    return (
      <div className="edu_start_year_row">
        <label className="edu_start_text">{t("healthcareSliderStart")}</label>
        <div className="edu_slider_content">
          <Slider
            value={this.state.startValue}
            age={age}
            min={currentYear + 1}
            max={currentYear + 21}
            markStep={5}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderEndYearSlider() {
    const currentYear = getCurrentYear();
    const age = calculate_age(this.state.endValue, currentYear, this.state.currentAge);
    return (
      <div className="edu_start_year_row">
        <label className="edu_start_text">{t("healthcareSliderEnd")}</label>
        <div className="edu_slider_content">
          <Slider
            value={this.state.endValue}
            age={age}
            min={currentYear + 2}
            max={currentYear + 22}
            markStep={5}
            handleChange={this.handleChangeEnd.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderInflationView() {
    return (
      <div className="hce_description_view">
        {this.renderInputView(
          t("healthcareInflationRate"),
          this.state.inflationRate,
          this.onChangeInflationRate.bind(this),
          false,
          true
        )}
      </div>
    );
  }

  renderYearView() {
    return (
      <div className="edu_slider_view">
        {this.renderStartYearSlider()}
        {this.state.type === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD &&
          this.state.recurringType === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD &&
          this.renderEndYearSlider()}
      </div>
    );
  }

  renderButton() {
    return (
      <div className="hce_bottomView">
        <div className="hce_buttonView">
          <div className="hce_save">
            <Button
              buttonName={t("healthcareButton")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="hce_content_view">
        {this.renderTitleView()}
        {this.renderExpenseType()}
        {this.renderInflationView()}
        <div className="hce_seperatorStyle" />
        {this.renderYearView()}
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  // renderDrawerContent() {
  //   const headerTitle = t("financialPlan");
  //   const step = 2;
  //   return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  // }
  render() {
    return (
      <div className="hce_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButton()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default HealthCareExpensePage;
