import React, { Component } from "react";
import "./creditcarddetail.css";
import { t } from "i18next";
import * as Bl from "../../../businesslogic";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  Header,
  TitleView,
  SideModel,
  ProgressDialog,
  Toast,
} from "../../components";
import { getLiabilityPathName } from "../../common";
import * as logger from "../../utils/logger/index";

class CreditCardDetail extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const liabilityData = params?.liabilityData ? params.liabilityData : "";
    const isEditView = params?.isEditView ? params.isEditView : false;
    this.state = {
      liabilityData: liabilityData ? new Bl.Liability(liabilityData) : {},
      isEditView,
    };
    this.dbData = liabilityData
      ? JSON.parse(JSON.stringify(new Bl.Liability(liabilityData)))
      : {};
  }
  onClickBackIcon(path) {
    this.props.navigate(path);
  }

  onSelectItem(path) {
    const localData = new Bl.Liability(this.state.liabilityData);
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onPressSaveButton() {
    if (this.state.isEditView) {
      this.updateUserLiability();
    } else {
      this.createUserLiability();
    }
  }

  onChangeDescription(value) {
    const liability = this.state.liabilityData;
    liability.setDescription(value);
    this.forceUpdate();
  }

  onChangeIssuedBy(value) {
    const liability = this.state.liabilityData;
    liability.setLoanIssuedBy(value);
    this.forceUpdate();
  }
  onChangeMaturity(value) {
    const liability = this.state.liabilityData;
    liability.setCurrentValue(value ? Number(value) : 0);
    this.forceUpdate();
  }
  createUserLiability() {
    const liabilityObj = new Bl.Liability(this.state.liabilityData);

    const isDataValid = this.checkIsDataValid(liabilityObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        liabilityObj
          .createLiability()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/creditcarddashboard");
            });
          })
          .catch((error) => {
          logger.error({error})
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  updateUserLiability() {
    const liabilityObj = new Bl.Liability(this.state.liabilityData);
    const isDataValid = this.checkIsDataValid(liabilityObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        liabilityObj
          .updateLiability()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/creditcarddashboard");
            });
          })
          .catch((error) => {
          logger.error({error})
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  isAmountValid(amount) {
    return amount && amount > 0;
  }
  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    if (!data.description) {
      isValid = false;
      message = t("creditToastDescription");
    } else if (!data.loanissuedby) {
      isValid = false;
      message = t("creditToastIssuedBy")
    } else if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("creditToastBalance");
    }
    return { isValid, message };
  }
  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }
  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  renderNavView() {
    const items = getLiabilityPathName("CreditCard");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        dbData={this.dbData}
        localData={this.state.liabilityData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("creditTitle");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="cdetail_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderDescNCardView() {
    const liabilityData = this.state.liabilityData;
    const description = liabilityData?.description
      ? liabilityData.description
      : "";
    const loanissuedby = liabilityData?.loanissuedby
      ? liabilityData.loanissuedby
      : "";
    return (
      <div className="cdetail_description_view">
        {this.renderInputView(
          t("creditInputDescription"),
          description,
          this.onChangeDescription.bind(this)
        )}
        {this.renderInputView(
          t("creditInputLoanIssued"),
          loanissuedby,
          this.onChangeIssuedBy.bind(this)
        )}
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="cdetail_bottomView">
        <div className="cdetail_buttonView">
          <div className="cdetail_save">
            <Button
              buttonName={t("creditButton1")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderBalanceView() {
    const liabilityData = this.state.liabilityData;
    const currentValue = liabilityData?.currentvalue
      ? liabilityData.currentvalue
      : "";
    return (
      <div className="cdetail_description_view">
        {this.renderInputView(
          t("creditInputBalance"),
          currentValue,
          this.onChangeMaturity.bind(this),
          true
        )}
      </div>
    );
  }

  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderContentView() {
    return (
      <div className="cdetail_content_view">
        {this.renderTitleView()}
        {this.renderDescNCardView()}
        {this.renderBalanceView()}
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="cdetail_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default CreditCardDetail;
