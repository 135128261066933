import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class IncomeApi {
  static options(){

    const token = localStorage.getItem('userToken');
    
    if (!token || token.trim() === '') {
        console.log('Token is not present or is empty');
        return Promise.reject(new Error('Token is missing or empty'));
    }
    
    const bearerToken = token.replace(/"/g, ''); 
    const options = {
        headers: {
            'Authorization': `Bearer ${bearerToken}`, 
            'Content-Type': 'application/json'
        }
    };
    
    return options;
}

  static createIncome(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserIncomes/`, 'POST', data, false, this.options())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateIncome(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserIncomes/`, 'PATCH', data, false, this.options())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static deleteIncome(data) {
    return new Promise((resolve,reject) => {
      apiCall(`UserIncomes/by/income/id/?id=${data.id}`,'DELETE', data, false, this.options())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
    })
  }

  static getUserIncomeConfig() {
    return new Promise((resolve, reject) => {
      apiCall(`UserIncomes/get/income/config`, 'GET', false, this.options())
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
    })
  }

  static getUserIncomesByUserId(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserIncomes/by/user/id/${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserIncomesByCategory(data) {
    return new Promise((resolve, reject) => {
      apiCall(
        `UserIncomes/by/income/category/${data.uuid}/${data.category}`,
        Methods.GET
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default IncomeApi;
