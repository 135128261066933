import BackendApi from "../backend/index";

class Networth {
  static getNetworthByUserId(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.NetworthApi.getNetworthByUserId(data)
        .then((list) => {
          resolve(list);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserFinancialDetails(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.NetworthApi.getUserFinancialDetails(data)
        .then((list) => {
          resolve(list)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default Networth;
