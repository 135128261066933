import React, { useRef, useState } from "react";
import "./stocksdashboard.css";
import { t } from "i18next";
import {
  BreadCrumb, Button, TitleView,
  CardDetailContainer, Toast,
  ProgressDialog
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getUserId } from "../../common";
import { INVESTMENT_CATEGORY } from "../../constants";
import { getFormatAmount, getFinancialMarketPath } from "../../common";
import * as logger from "../../utils/logger/index";
import { useEffect } from "react";

const StocksDashBoard = (props) => {
  const [state, setState] = useState({
    showEditView: false,
    showModal: false,
    editing: null,
    title: "",
    investmentData: []
  })
  const isRenderedAlready = useRef(false);


  useEffect(() => {
    if (!isRenderedAlready.current) {
      isRenderedAlready.current = true;
      getUserInvestmentByCategory();
    }
  }, [])

  const onSelectItem = (path) => {
    props.navigate(path);
  }

  const onPressAddMoreView = () => {
    props.navigate("/stockshome");
  }

  const onPressSaveButton = () => {
    props.navigate("/financialdetails");
  }

  const onClickEditIcon = (selectedItem) => {
    props.navigate('/stocksdetailpage', {
      state: { investmentData: selectedItem.item, isEditView: true },
    });
  };

  const onPressNo = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false
    }));
  };

  const onPressYes = (selectedItem) => {
    deleteInvestment(selectedItem.item);
  };

  const getTotalBalance = (list) => {
    const totalArray = [];
    list.forEach((item) => {
      totalArray.push({
        money: item.currentvalue,
      });
    });
    const total = (totalArray.reduce((a, v) => a = a + v.money, 0))
    return total;
  }

  const prepareCardData = (list) => {
    const displayData = [];
    list.forEach((item) => {
      displayData.push({
        title: "Stocks",
        currencyLabel: "Current value",
        money: item.currentvalue,
        period: item.maturityyear,
        item,
      });
    });
    return displayData;
  }

  const hideToast = () => {
    setState((prevState) => ({
      ...prevState,
      toastMessage: "",
      showToast: false
    }));
  }

  const showToast = (toastMessage) => {
    setState((prevState) => ({
      ...prevState,
      toastMessage,
      showToast: true,
      showProgress: false
    }));
    setTimeout(() => {
      hideToast();
    }, 3000);
  }

  const getUserInvestmentByCategory = () => {
    const data = {
      uuid: getUserId(),
      category: INVESTMENT_CATEGORY.STOCKS,
    };

    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }));

    Bl.Investment.getUserInvestmentByCategory(data)
      .then((list) => {
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
          investmentData: list
        }));
      })
      .catch((error) => {
        logger.error({ error })
        showToast(t("someErrorHasOccured"));
      });
  }

  const deleteInvestment = (item) => {
    const investmentObj = new Bl.Investment(item);

    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }));

    investmentObj.deleteInvestment()
      .then(() => {
        const newList = state.investmentData && state.investmentData.filter(e => e.id !== item.id)
        setState((prevState) => ({
          ...prevState,
          investmentData: newList,
          showProgress: false
        }));
      })
      .catch((error) => {
        logger.error({ error })
        showToast(t("someErrorHasOccured"));
      });
  }


  const renderNavView = () => {
    const items = getFinancialMarketPath("STOCK");
    const location = props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={onSelectItem}
        onClickItem={onSelectItem}
      />
    );
  }

  const renderTitleView = () => {
    const title = t('stocks');
    return (
      <TitleView title={title} />
    );
  }

  const renderExpectedValueView = () => {
    const totalBalance = getTotalBalance(state.investmentData)
    const displayAmount = getFormatAmount(totalBalance);
    return (
      <div className="stb_expect_value_view">
        <label className="stb_value_text">
          {t("Total Current Value")}: ₹ {displayAmount}
        </label>
      </div>
    );
  }

  const renderCardView = () => {
    const cardData = prepareCardData(state.investmentData);
    return (
      <div className="stb_card_view">
        {cardData.map((card, index) => (
          <CardDetailContainer
            key={index}
            title={card.title}
            currentValue={card.currencyLabel}
            money={card.money}
            extraContent={card.extraContent}
            onClickEdit={() => onClickEditIcon(card)}
            onPressNo={onPressNo}
            onPressYes={() => onPressYes(card)}

          />
        ))}
      </div>
    );
  }

  const renderContentView = () => {
    return (
      <div className="stb_content_view">
        {renderTitleView()}
        {renderExpectedValueView()}
        {renderCardView()}
      </div>
    );
  }

  const renderButtonsView = () => {
    return (
      <div className="stb_bottomView">
        <div className="stb_buttonView">
          <div className="stb_addmore_button">
            <Button
              buttonName={t("npsDBButton1")}
              emptyButton={true}
              onPressButton={onPressAddMoreView}
            />
          </div>
          <div className="stb_save">
            <Button
              buttonName={t("npsDBButton2")}
              onPressButton={onPressSaveButton}
            />
          </div>
        </div>
      </div>
    );
  }
  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  return (
    <div className="stb_container" >
      {renderNavView()}
      {renderContentView()}
      {renderButtonsView()}
      {renderToastView()}
      {renderProgressDialog()}
    </div >
  );
}

export default StocksDashBoard;
