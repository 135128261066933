import { t } from "i18next";
import * as Bl from "../../businesslogic";
import RiskProfilePage from "./riskprofilepage";
import Global from "../utils/global";
import SuccessModalIcon from "../../assets/svgs/successmodal.svg";
import { Modal } from "rsuite";
import { Button } from "../components";


class RiskProfileUrlPage extends RiskProfilePage {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      showSuccessModal: false,
      answeredAllQues: false
    }
  }

  async componentDidMount() {
    await this.validateRiskProfileUrl()
  }

  onPressOk() {
    this.setState({ showSuccessModal: false })
  }

  onModalClose() {
    this.setState({ showSuccessModal: false })
  }

  /*
   *  Check all the questions are already answered,
   *  if already answered then show the modal
   */
  checkIsAllQuesAnsweredAlready() {
    const percentage = this.getCompletedPercentage();
    if (percentage === 100) {
      this.setState({ showSuccessModal: true, answeredAllQues: true })
    }
  }

  async validateRiskProfileUrl() {
    const pathname = this.props?.location?.pathname;
    const url = pathname.split("/")
    const link = url[2];
    if (link) {
      const data = {
        link: link
      }
      Bl.RiskProfile.validateRiskProfileUrl(data).then(async (response) => {
        if (response.id) {
          Global.isRiskProfileFromLink = true;
          if (response?.id) {
            this.userId = response.id;

            const userDetails = await Bl.User.getUserDetailsById(this.userId);
            const user = userDetails.User;
            if (user.is_risk_profile_completed === true) {
              this.setState({
                showSuccessModal: true
              })
            } else {
              this.deleteAllAnswers();
              this.getRiskQuestions();
            }
          }

        }
      }).catch((error) => {
        console.log("RiskProfileUrlPage/validateRiskProfileUrl Error:", error);
        this.setState({ answeredAllQues: true }, () => {
          alert('Please contact admin team');
        })
      })
    }
  }

  deleteAllAnswers() {
    const riskProfileObj = new Bl.RiskProfile({});
    riskProfileObj.setUserId(this.userId);
    this.setState({ showProgress: true }, () => {
      riskProfileObj
        .deleteUserAnswers()
        .then(() => {
          
        })
        .catch((error) => {
          console.log("RiskProfileEnd/onPressRetakeButton Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  updateStatusInfo() {
    const date = new Date();
    // const updatedDate = date ? `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}` : '';
    const updatedDate = date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : '';

    const data = {
      "id": this.userId,
      "is_risk_profile_completed": true,
      "risk_profile_updated_date": updatedDate
    }

    this.setState({ showProgress: true }, () => {
      Bl.RiskProfile.createRiskProfileStatusInfo(data)
        .then(() => {
          this.setState({
            showProgress: false,
            selectedItem: null,
            showSuccessModal: true,
            answeredAllQues: true
          })
        }).catch((error) => {
          console.log("RiskProfilePage/updateStatusInfo Error:", error);
          this.showToast(t("someErrorHasOccured"));
        })
    })
  }


  renderNavView() {
    return <div style={{ height: 24 }} />
  }

  renderMsg() {
    const successLabel = t('Thank you for your response.');
    const subLabel = t('Our team will contact you shortly to discuss further details.')
    return (
      <div className="rp_modal_dispaly">
        <div className="rp_modal_close">
          <span onClick={this.onModalClose.bind(this)}>&times;</span>
        </div>
        <div className={"rp_modal_msg"}>
          <img src={SuccessModalIcon} alt="SelectedItem" className={"cp_successlIcon"} />
          <label className="rp_modal_heading">{successLabel}</label>
          <label className="rp_modal_subLabel">{subLabel}</label>
        </div>
        <div className="cp_ok">
          <Button
            buttonName={t("OK")}
            onPressButton={this.onPressOk.bind(this)}
          />
        </div>
      </div>
    )
  }

  renderSuccessModal() {
    return (
      <Modal
        open={this.state.showSuccessModal}
        onClose={this.onModalClose.bind(this)}
        size={'m'}
        className={"rp_modal"}
      >
        <div className="rp_modal-content">
          {this.renderMsg()}
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div className="rp_container">
        {!this.state.answeredAllQues && this.renderNavView()}
        {!this.state.answeredAllQues && this.renderContentView()}
        {!this.state.answeredAllQues && this.renderBottomView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {this.state.showSuccessModal && this.renderSuccessModal()}
      </div>
    );
  }
}


export default RiskProfileUrlPage;