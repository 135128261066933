import React, { Component } from "react";
import "./costitemperyear.css";
import InfoIcon from "../../assets/svgs/ic_info.svg";
import EditIcon from "../../assets/svgs/icons_svg/edit_icon.svg";
import { getFormatAmount } from "../common";

class CostItemPerYear extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.viewRef = React.createRef();
  }

  handleClick = () => {
    const { item, handleEdit } = this.props;
    handleEdit(item);
  };

  renderLeftView() {
    return (
      <div className="cipy_left_view">
        <img src={InfoIcon} alt={"Info Icon"} className="cipy_info_icon" />
        <label className="cipy_labelStyle">{this.props.item.label}</label>
      </div>
    );
  }

  renderValue() {
    const { showEditIcon } = this.props;
    const displayAmount = getFormatAmount(this.props.item.cost)
    return (
      <div className="cipy_value">
        <label className="cipy_value_content">₹ {displayAmount}</label>
        {showEditIcon && (
          <img
            src={EditIcon}
            alt="edit_icon"
            className="cipy_edit_icon"
            onClick={this.handleClick.bind(this)}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="cipy_container" ref={this.viewRef}>
        {this.renderLeftView()}
        {this.renderValue()}
      </div>
    );
  }
}

export default CostItemPerYear;
