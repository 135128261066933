import React, { Component } from "react";
import "./portfolioassetallocation.css";
import { t } from "i18next";
import * as Bl from '../../businesslogic';
import {
  AssetAllocationView,
  BreadCrumb,
  Button,
  ToggleSwitch,
  RecordModal,
  Toast,
  ProgressDialog
} from "../components";
import { getUserId, setSelectedUserDetail } from "../common";


const defaultData = [
  {
    equity: []
  },
  {
    debt: []
  },
  {
    others: []
  }
];

class PortfolioAssetAllocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSecureContent: false,
      displayData: defaultData,
      growthData: defaultData,
      secureData: defaultData,
      isPreviewModalOpen: false,
      showToast: false,
      toastMessage: '',
      showProgress: false,
      progressMessage: '',
      assetAllocValues: {},
      assetValues: {},
      sebiCategories: [],
      disableSubmit: true,
      portfolioEffectiveRate: 0
    };
    this.userId = getUserId();
    this.isReloadRequired = true;
  }

  componentDidMount() {
    if (this.isReloadRequired) {
      this.isReloadRequired = false;
      this.getAssetAllocation();
      this.getAllSebiCategories();
    }
  }

  onClickBackIcon(path) {
    this.props.navigate(path);
  }

  onHideModal = () => {
    this.setState({ isPreviewModalOpen: false });
  };

  onPressPreviewSubmit() {
    this.onHideModal();
    this.setState({
      disableSubmit: false
    })
  }

  isDataValid() {
    const data = this.state.displayData;
    const assetValues = this.state.assetValues;

    const equityAssetTotal = assetValues.equity;
    const debtAssetTotal = assetValues.debt;
    const othersAssetTotal = assetValues.others;

    const equityData = data[0].equity?.filter(item => item.sebicategory !== "");
    const debtData = data[1].debt?.filter(item => item.sebicategory !== "");
    const othersData = data[2].others?.filter(item => item.sebicategory !== "");

    const equityTotal = equityData?.reduce((total, item) => total + (item.revisedAllocation ? item.revisedAllocation : item.allocationpercentage), 0);
    const debtTotal = debtData?.reduce((total, item) => total + (item.revisedAllocation ? item.revisedAllocation : item.allocationpercentage), 0);
    const othersTotal = othersData?.reduce((total, item) => total + (item.revisedAllocation ? item.revisedAllocation : item.allocationpercentage), 0);

    const isEquityValid = equityAssetTotal !== 0 ? equityData?.length > 0 && equityTotal === 100 ? true : false : true;
    const isDebtValid = debtAssetTotal !== 0 ? debtData?.length > 0 && debtTotal === 100 ? true : false : true;
    const isOthersValid = othersAssetTotal !== 0 ? othersData?.length > 0 && othersTotal === 100 ? true : false : true;

    return isEquityValid && isDebtValid && isOthersValid;
  }

  onPressSubmitButton() {
    const isValid = this.isDataValid();

    if (isValid) {
      const bucketType = this.state.showSecureContent ? 'sb' : 'gb';

      const consolidatedArray = this.state.displayData?.reduce((result, item) => {
        const keys = Object.keys(item);
        if (keys.length > 0) {
          const key = keys[0];
          result = result.concat(item[key]);
        }
        return result;
      }, []);

      const finalData = consolidatedArray.map(item => {
        const { netAllocation, revisedAllocation, ...rest } = item;
        rest.allocationpercentage = revisedAllocation !== '' ? Number(revisedAllocation) : Number(rest.allocationpercentage);
        return rest;
      });

      const data = {
        "data": finalData
      }

      console.log('finalData:', finalData)

      this.setState({ showProgress: true }, () => {
        Bl.AccountPortfolioRule.createAccountPortfoliRule(this.userId, bucketType, data)
          .then(async (result) => {
            const rearrangedData = this.prepareDataByGroups(result.data);
            let growthData = [];
            let secureData = [];
            if (this.state.showSecureContent) {
              growthData = this.state.growthData;
              secureData = rearrangedData;
            } else {
              growthData = rearrangedData;
              secureData = this.state.secureData;
            }

            const userId = getUserId();
            const userDetail = await Bl.User.getUserDetailsById(userId)
            setSelectedUserDetail(userDetail.User)
            this.showToast('Portfolio Allocation data updated')
            this.setState({ displayData: rearrangedData, growthData, secureData, showProgress: false })
          }).catch(error => {
            console.log('PortfolioAssetAllocation/onPressSubmitButton:', error.message)
            this.showToast(error.message)
          })
      })
    } else {
      this.showToast('Please make sure all the categories have 100% of allocation')
    }

  }

  onPressCalculateRate() {
    const consolidatedArray = this.state.displayData.reduce((result, item) => {
      const keys = Object.keys(item);
      if (keys.length > 0) {
        const key = keys[0];
        result = result.concat(item[key]);
      }
      return result;
    }, []);

    const percentage = consolidatedArray.map(item => Number(item.netAllocation));
    const sebicategory = consolidatedArray.map(item => item.sebicategory)

    const data = {
      "data": [
        {
          "Percentage": percentage,
          "sebicategory": sebicategory
        }
      ]
    }
    console.log('data for calculate effective rate:', data)
    this.setState({
      showProgress: true,
    }, () => {
      Bl.AccountPortfolioRule.calculateEffectiveRate(data).then((result) => {
        this.setState({ showProgress: false, portfolioEffectiveRate: result.effectiverate, isPreviewModalOpen: true })
      }).catch(error => {
        console.log(error)
        this.setState({ showProgress: false })
      })
    });
  }

  onChangeInput() {
    this.setState({ disableSubmit: true })
  }

  onClickDelete(item, index) {
    const data = this.state.displayData;
    let currentData = [];
    switch (item.assetclass) {
      case "EQUTY":
        currentData = data[0].equity;
        break;
      case "DEBT ":
        currentData = data[1].debt;
        break;
      default:
        currentData = data[2].others;
        break;
    }

    const newData = currentData?.filter((el) => el.sebicategory !== item.sebicategory);
    if (item.assetclass === "EQUTY") {
      data[0].equity = newData;
    } else if (item.assetclass === "DEBT ") {
      data[1].debt = newData;
    } else if (item.assetclass !== "EQUTY" && item.assetclass !== "DEBT ") {
      data[2].others = newData;
    }
    this.setState({ displayData: data, disableSubmit: true });
  }

  onPressOk(item, selectedItem) {
    const data = this.state.displayData;
    let currentData = [];
    switch (item.assetclass) {
      case "EQUTY":
        currentData = data[0].equity;
        break;
      case "DEBT ":
        currentData = data[1].debt;
        break;
      default:
        currentData = data[2].others;
        break;
    }

    currentData.push(item);

    if (item.assetclass === "EQUTY") {
      data[0].equity = currentData;
    } else if (item.assetclass === "DEBT ") {
      data[1].debt = currentData;
    } else if (item.assetclass !== "EQUTY" && item.assetclass !== "DEBT ") {
      data[2].others = currentData;
    }

    this.setState({ displayData: data, disableSubmit: true });
  }

  handleToggleSwitchChange(selectedItem, checked) {
    const displayData = checked ? this.state.secureData : this.state.growthData;
    const assetAllocValues = this.state.assetAllocValues;
    let assetValues = {};
    if (checked) {
      assetValues.equity = assetAllocValues.sb_equityAlloPer
      assetValues.others = assetAllocValues.sb_commodityAlloPer
      assetValues.debt = assetAllocValues.sb_debtAlloPer
    } else {
      assetValues.equity = assetAllocValues.gb_equityAlloPer
      assetValues.others = assetAllocValues.gb_commodityAlloPer
      assetValues.debt = assetAllocValues.gb_debtAlloPer
    }
    this.setState({ showSecureContent: checked, displayData, assetValues });
  }

  prepareDataByGroups(data) {
    const assetValues = this.state.assetValues;
    const equityArray = data?.filter(item => item.assetclass === "EQUTY");
    const debtArray = data?.filter(item => item.assetclass === "DEBT ");
    const othersArray = data?.filter(item => item.assetclass !== "EQUTY" && item.assetclass !== "DEBT ");

    if (equityArray?.length === 0) {
      equityArray.push({
        "sebicategory": "",
        "allocationpercentage": "",
        "assetclass": "EQUTY",
        "sebicategorydescription": "",
        "netAllocation": "",
        "revisedAllocation": ""
      })
    } else {
      equityArray.map(item => {
        item.netAllocation = Number(item.allocationpercentage * ((assetValues.equity / 100).toFixed(2))).toFixed(2);
        item.revisedAllocation = '';
        return item;
      });
    }

    if (debtArray?.length === 0) {
      debtArray.push({
        "sebicategory": "",
        "allocationpercentage": "",
        "assetclass": "DEBT ",
        "sebicategorydescription": "",
        "netAllocation": "",
        "revisedAllocation": ""
      })
    } else {
      debtArray.map(item => {
        item.netAllocation = Number(item.allocationpercentage * ((assetValues.debt / 100).toFixed(2))).toFixed(2);
        item.revisedAllocation = '';
        return item;
      });
    }

    if (othersArray?.length === 0) {
      othersArray.push({
        "sebicategory": "",
        "allocationpercentage": "",
        "assetclass": "",
        "sebicategorydescription": "",
        "netAllocation": "",
        "revisedAllocation": ""
      })
    } else {
      othersArray.map(item => {
        item.netAllocation = Number(item.allocationpercentage * ((assetValues.others / 100).toFixed(2))).toFixed(2);
        item.revisedAllocation = '';
        return item;
      });
    }

    const newData = [
      {
        equity: equityArray
      },
      {
        debt: debtArray
      },
      {
        others: othersArray
      }
    ]
    console.log({ newData })
    return newData;
  }

  getAssetAllocation() {
    const data = {
      uuid: this.userId,
    };
    this.setState({ showProgress: true }, () => {
      Bl.CashLadder.getAssetAllocationDetails(data)
        .then((response) => {
          if (response) {
            let assetAllocValues = {};
            let assetValues = {};

            if (response.growth_bucket) {
              response.growth_bucket.forEach((growth) => {
                if (growth.assetclass === "EQUTY") {
                  assetAllocValues.gb_equityAlloPer = growth.allocationpercentage;
                  assetValues.equity = growth.allocationpercentage;
                } else if (growth.assetclass === "CMODT") {
                  assetAllocValues.gb_commodityAlloPer = growth.allocationpercentage;
                  assetValues.others = growth.allocationpercentage;
                } else if (growth.assetclass === "DEBT") {
                  assetAllocValues.gb_debtAlloPer = growth.allocationpercentage;
                  assetValues.debt = growth.allocationpercentage;
                }
              });
            }

            if (response.secure_bucket) {
              response.secure_bucket.forEach((secure) => {
                if (secure.assetclass === "EQUTY") {
                  assetAllocValues.sb_equityAlloPer = secure.allocationpercentage;
                } else if (secure.assetclass === "CMODT") {
                  assetAllocValues.sb_commodityAlloPer = secure.allocationpercentage;
                } else if (secure.assetclass === "DEBT") {
                  assetAllocValues.sb_debtAlloPer = secure.allocationpercentage;
                }
              });
            }

            this.setState({
              assetAllocValues,
              assetValues
            });

            this.getGrowthBucketData();
            this.getSecureBucketData();
          } else {
            // Handle the case when the response is not as expected
            this.showToast(t("Invalid response received"));
          }
        })
        .catch((error) => {
          console.error({ error });
          this.showToast(t("Some error has occurred"));
        });
    });

  };

  getGrowthBucketData() {
    const data = {
      uuid: this.userId
    }
    this.setState({ showProgress: true }, () => {
      Bl.AccountPortfolioRule.getGrowthBucketValues(data).then(result => {
        console.log('growthbucket data:', result)
        const rearrangedData = this.prepareDataByGroups(result);
        this.setState({ displayData: rearrangedData, growthData: rearrangedData, showProgress: false })
      }).catch(error => {
        console.log('portfolioassetallocation/getGrowthBucketData:', error)
        this.showToast(error.message)
      })
    })
  }

  getSecureBucketData() {
    const data = {
      uuid: this.userId
    }
    this.setState({ showProgress: true }, () => {
      Bl.AccountPortfolioRule.getSecureBucketValues(data).then(result => {
        console.log('securebucket data:', result)
        const rearrangedData = this.prepareDataByGroups(result);
        this.setState({ secureData: rearrangedData, showProgress: false });
      }).catch(error => {
        console.log('portfolioassetallocation/getSecureBucketData:', error)
        this.showToast(error.message)
      })
    })
  }

  getAllSebiCategories() {
    Bl.AccountPortfolioRule.getAllSebiCategoryValues().then(categoryList => {
      const sortedData = categoryList.sort((a, b) => a.mstarcategory?.localeCompare(b.mstarcategory));
      this.setState({ sebiCategories: sortedData })
    }).catch(error => {
      console.log('accountportfoliorule/getAllSebiCategories:', error)
    })
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  renderNavView() {
    const items = [
      { name: "Financial Plan", path: "/financial" },
      { name: "Portfolio Asset Allocation", path: "/portfolioassetallocation" },
    ];
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={this.onClickBackIcon.bind(this)}
      />
    );
  }

  renderToggleSelectionView() {
    return (
      <div className="cf_toggleView">
        <ToggleSwitch
          checkedChildren={t("Growth Bucket")}
          unCheckedChildren={t("Secure Bucket")}
          onChangeToggle={this.handleToggleSwitchChange.bind(this)}
        />
      </div>
    );
  }

  renderTitleView() {
    const title = this.state.showSecureContent ? t("Secure Bucket") : t("Growth Bucket");
    return (
      <div className="aaa_titleView">
        <label className="aaa_titleStyle">{title}</label>
        {this.renderToggleSelectionView()}
      </div>
    );
  }

  renderAssetAllocationView() {
    return (
      <AssetAllocationView
        data={this.state.displayData}
        assetValues={this.state.assetValues}
        sebiCategories={this.state.sebiCategories}
        onChangeInput={this.onChangeInput.bind(this)}
        onClickDelete={this.onClickDelete.bind(this)}
        onPressOk={this.onPressOk.bind(this)}
      />
    );
  }


  renderModalData() {
    const effectiverate = this.state.portfolioEffectiveRate
    return (
      <div className="aaa_modal_view">
        <label className="aaa_label">{'Your portfolio effective rate is '}</label>
        <label className="aaa_value">{effectiverate}</label>
      </div>
    );
  }

  renderModalView() {
    const { isPreviewModalOpen } = this.state;
    const buttonNames = { one: t("configuration Button : Submit") };
    const title = this.state.showSecureContent
      ? t("Secure Bucket")
      : t("Growth Bucket");
    const dialogClassName = "aaa-dialog-class";

    return (
      isPreviewModalOpen && (
        <RecordModal
          open={isPreviewModalOpen}
          onClose={this.onHideModal.bind(this)}
          onPressYes={this.onPressPreviewSubmit.bind(this)}
          title={title}
          buttonNames={buttonNames}
          dialogClassName={dialogClassName}
        >
          {this.renderModalData()}
        </RecordModal>
      )
    );
  }

  renderContentView() {
    return (
      <div className="aaa_content_view">
        {this.renderTitleView()}
        {this.renderAssetAllocationView()}
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="aaa_bottomView">
        <div className="aaa_bigButtonView">
          <Button
            buttonName={t("Calculate Portfolio Effective Rate")}
            emptyButton={true}
            onPressButton={this.onPressCalculateRate.bind(this)}
          />
        </div>
        <div className="aaa_buttonView">
          <Button
            disabled={this.state.disableSubmit}
            buttonName={t("Submit")}
            onPressButton={this.onPressSubmitButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  render() {
    return (
      <div className="aaa_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {this.renderModalView()}
      </div>
    );
  }
}

export default PortfolioAssetAllocation;