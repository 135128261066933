import React, { Component } from "react";
import { t } from "i18next";
import {
  Button,
  CashLadderFinancialView,
  ChartView,
  GoalFulFillmentView,
  ProgressDialog,
  RiskProfileDialler,
  Toast,
  ToggleSwitch,
} from "../components";
import "./dashboard.css";
import * as Bl from "../../businesslogic";
import * as logger from "../utils/logger/index";
import {
  getFormatAmount,
  getRiskProfileDescription,
  getUserDetails,
  getUserId,
  prepareCashLadderValues,
  prepareGoalFullfilmentValues,
} from "../common";
import DataSplitBars from "./datasplitbars";

function formatDate(dateStr) {
  const options = { month: "short", day: "numeric", year: "numeric" };
  const date = new Date(dateStr);
  return date.toLocaleDateString("en-US", options);
}


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSecureContent: false,
      showCashLadder: false,
      status: "",
      networthValue: 0,
      liabilityValue: 0,
      investmentValue: 0,
      cashLadderValues: [],
      showToast: false,
      toastMessage: "",
      showProgress: false,
      progressMessage: "",
      goalAlloctaionValues: [],
      filterOption: {},
      isAbsoluteReturn: true,
      accountDetails: {
        "id": "",
        "amountinvested": 0,
        "xirr": 0,
        "currentvalue": 0,
        "investorname": "",
        "pancard": "",
        "absolutereturn": 0
      },
      userPanNumber: ''
    };
    this.viewRef = null;
    this.isRenderedAlready = false;
    this.userId = getUserId();
  }

  componentDidMount() {
    if (!this.isRenderedAlready) {
      this.isRenderedAlready = true;
      this.getUserData();
      this.getUserRiskProfile();
      this.getNetworthByUserId();
      this.getCashLadderValues();
      this.getAssetAllocation();
      this.getGoalAllocation();
      this.getGrowthBucketData();
      this.getSecureBucketData();
    }
  }

  handleToggleSwitchChange = (selectedItem, checked) => {
    this.setState((prevState) => ({ ...prevState, showCashLadder: checked }));
  };

  handlePortfolioReturnChange(selectedItem, checked) {
    this.setState({ isAbsoluteReturn: checked })
  }

  handleBucketSwitchChange(selectedItem, checked) {
    const displayTargetData = checked ? this.state.secureData : this.state.growthData;
    const displayActualData = checked ? this.state.sbActualData : this.state.gbActualData;
    const assetAllocValues = this.state.assetAllocValues;
    let assetValues = {};
    if (checked) {
      assetValues.equity = assetAllocValues.sbequity
      assetValues.others = assetAllocValues.sbpthers
      assetValues.debt = assetAllocValues.sbdebt
    } else {
      assetValues.equity = assetAllocValues.gbequity
      assetValues.others = assetAllocValues.gbpthers
      assetValues.debt = assetAllocValues.gbdebt
    }
    this.setState({ showSecureContent: checked, displayTargetData, assetValues, displayActualData });
  }

  onPressSearchApply(selectedOption, searchValue, searchMaxValue) {
    if (searchMaxValue) {
      if (searchValue?.length > 0) {
        this.setState({
          filterOption: {
            option: selectedOption,
            minValue: searchValue,
            maxValue: searchMaxValue,
          },
        });
      } else {
        this.setState({
          filterOption: {},
        });
      }
    } else {
      if (searchValue?.length > 0) {
        this.setState({
          filterOption: {
            option: selectedOption,
            value: searchValue,
          },
        });
      } else {
        this.setState({
          filterOption: {},
        });
      }
    }
  }

  prepareGoalAllocationData = () => {
    const data = this.state.goalAlloctaionValues;
    const searchObj = this.state.filterOption;

    if (Object.keys(searchObj).length > 0) {
      const result = data.filter((obj) => {
        if (searchObj.option === "goalCategory") {
          return obj.goalCategory.includes(searchObj.value);
        } else if (
          searchObj.option === "age" ||
          searchObj.option === "amount"
        ) {
          let key = searchObj.option;
          let minValue = Number(searchObj.minValue);
          let maxValue = Number(searchObj.maxValue);
          return obj[key] >= minValue && obj[key] <= maxValue;
        }
      });

      if (result) {
        return result;
      } else {
        return [];
      }
    } else {
      return data;
    }
  };

  prepareActualDataBasedOnCategory(data) {
    const sebiCategory = Object.values(data.sebicategory);
    const percentage = Object.values(data.Percentage);
    const sebiDesc = Object.values(data.sebicategorydescription);
    const currentValue = Object.values(data.currentvalue);

    const actualData = sebiCategory?.map((category, index) => ({
      sebicategory: sebiCategory[index],
      allocationpercentage: percentage[index],
      sebicategorydescription: sebiDesc[index],
      currentvalue: currentValue[index]
    }))

    return actualData;
  }

  prepareActualValues(data) {
    const equtyData = this.prepareActualDataBasedOnCategory(data?.EQUTY);
    const debtData = this.prepareActualDataBasedOnCategory(data?.DEBT);
    const otherData = this.prepareActualDataBasedOnCategory(data?.OTHER);
    const assetTotal = data?.assetclass_level;
    const totalValue = {}
    for (let key in assetTotal.assetclass) {
      if (assetTotal.assetclass.hasOwnProperty(key)) {
        let assetClass = assetTotal.assetclass[key]?.trim();
        let percentage = assetTotal.Percentage[key];
        totalValue[assetClass] = percentage;
      }
    }

    const actualData = {
      EQUTY: equtyData,
      DEBT: debtData,
      OTHER: otherData,
      totalValue
    }

    return actualData;

  }

  getUserData() {
    const userData = getUserDetails();
    this.setState({ userPanNumber: userData.pannumber }, () => {
      this.getAccountDetails();
    });
  }

  getGoalAllocation() {
    const data = {
      uuid: this.userId
    };
    this.setState({
      showProgress: true,
    });
    Bl.CashLadder.getCashLadderGoalAllocation(data)
      .then((response) => {
        if (response) {
          const goalAlloctaionValues = prepareGoalFullfilmentValues(response);
          this.setState({
            showProgress: false,
            goalAlloctaionValues: goalAlloctaionValues,
          });
        }
      })
      .catch((error) => {
        logger.error({ error });
        this.showToast(t("Some error has occured"));
      });
  }

  getGrowthBucketData() {
    const data = {
      uuid: this.userId
    }
    this.setState({ showProgress: true }, () => {
      Bl.AccountPortfolioRule.getGrowthBucketValues(data).then(result => {
        console.log('growthbucket data:', result)
        this.setState({ displayTargetData: result, growthData: result, showProgress: false })
      }).catch(error => {
        console.log('dashboard/getGrowthBucketData:', error)
        this.showToast(error.message)
      })
    })
  }

  getSecureBucketData() {
    const data = {
      uuid: this.userId
    }
    this.setState({ showProgress: true }, () => {
      Bl.AccountPortfolioRule.getSecureBucketValues(data).then(result => {
        console.log('securebucket data:', result)
        this.setState({ secureData: result, showProgress: false })
      }).catch(error => {
        console.log('dashboard/getGrowthBucketData:', error)
        this.showToast(error.message)
      })
    })
  }

  getAssetAllocation() {
    const data = {
      uuid: this.userId,
    };
    this.setState({
      showProgress: true,
    }, () => {
      Bl.CashLadder.getAssetAllocationDetails(data)
        .then((response) => {
          if (response) {
            const data = {
              "sbequity": 0,
              "sbdebt": 0,
              "sbpthers": 0,
              "gbequity": 0,
              "gbdebt": 0,
              "gbpthers": 0
            }
            const assetValues = {};

            if (response.growth_bucket) {
              response.growth_bucket?.forEach((growth) => {
                if (growth.assetclass === "EQUTY") {
                  data.gbequity = growth.allocationpercentage;
                  assetValues.equity = growth.allocationpercentage;
                } else if (growth.assetclass === "CMODT") {
                  data.gbpthers = growth.allocationpercentage;
                  assetValues.others = growth.allocationpercentage;
                } else if (growth.assetclass === "DEBT") {
                  data.gbdebt = growth.allocationpercentage;
                  assetValues.debt = growth.allocationpercentage;
                }
              });
            }

            if (response.secure_bucket) {
              response.secure_bucket?.forEach((secure) => {
                if (secure.assetclass === "EQUTY") {
                  data.sbequity = secure.allocationpercentage;
                } else if (secure.assetclass === "CMODT") {
                  data.sbpthers = secure.allocationpercentage;
                } else if (secure.assetclass === "DEBT") {
                  data.sbdebt = secure.allocationpercentage;
                }
              });
            }
            this.setState({ assetAllocValues: data, assetValues })

          } else {
            // Handle the case when the response is not as expected
            this.showToast(t("Invalid response received"));
          }
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast(t("Some error has occurred"));
        });
    });
  };


  getCashLadderValues() {
    const data = {
      uuid: this.userId
    };
    this.setState({
      showProgress: true,
    }, () => {
      Bl.Dashboard.getPermanentCashladderValues(data)
        .then((response) => {
          if (response) {
            const cashLadderValues = prepareCashLadderValues(response[0]);
            this.setState({
              showProgress: false,
              cashLadderValues: cashLadderValues,
            });
          }
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getUserRiskProfile() {
    const data = {
      uuid: this.userId,
    };
    this.setState({
      showProgress: true,
    });
    Bl.RiskProfile.getUserRiskProfile(data)
      .then((response) => {
        const categoryType = response?.finalriskcategory;
        const status = getRiskProfileDescription(categoryType);
        this.setState((prevState) => ({
          ...prevState,
          showProgress: false,
          status: status,
        }));
      })
      .catch((error) => {
        logger.error({ error });
        this.showToast(t("User did not answer the question"));
      });
  }

  getNetworthByUserId() {
    const data = {
      uuid: this.userId,
    };
    this.setState({ showProgress: true }, () => {
      Bl.Networth.getNetworthByUserId(data)

        .then((list) => {
          this.setState({
            showProgress: false,
            networthValue: list.Networth,
            liabilityValue: list.liabilities,
            investmentValue: list.investment,
            lastUpdatedDate: list.last_updated,
          });
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getAccountDetails() {
    const data = {
      pancard: this.state.userPanNumber
    };
    Bl.Dashboard.getAccountDetails(data)
      .then((result) => {
        console.log('Acc Details:', result)
        const sbActualValues = result?.Dashboard?.sballocation ? this.prepareActualValues(result?.Dashboard?.sballocation) : [];
        const gbActualValues = result?.Dashboard?.gballocation ? this.prepareActualValues(result?.Dashboard?.gballocation) : [];
        this.setState({
          accountDetails: result.Dashboard,
          displayActualData: gbActualValues,
          sbActualData: sbActualValues,
          gbActualData: gbActualValues
        })
      })
      .catch((error) => {
        logger.error({ error });
        this.showToast(t("someErrorHasOccured"));
      });
  }

  onPressComingSoon() { }

  onClickViewDetails() {
    this.props.navigate("/financialdetails");
  }

  calculatePercentage(value, total) {
    return Math.round((value / total) * 100);
  }

  hideToast() {
    this.setState({
      toastMessage: "",
      showToast: false,
    });
  };

  showToast = (toastMessage) => {
    this.setState({
      toastMessage,
      showToast: true,
      showProgress: false,
    }, () => {
      setTimeout(() => {
        this.hideToast();
      }, 3000);
    });
  };

  renderDoughnutChartView() {
    const totalValue = this.state.investmentValue + this.state.liabilityValue;
    const investmentPercentage = this.calculatePercentage(
      this.state.investmentValue,
      totalValue
    );
    const liabilityPercentage = this.calculatePercentage(
      this.state.liabilityValue,
      totalValue
    );
    const chartData = {
      datasets: [
        {
          data: [investmentPercentage, liabilityPercentage],
          backgroundColor: ["#145079", "#F9CF81"],
        },
      ],
      labels: ["Assets", "Liabilities"],
    };
    return (
      <div className={"db_chart_doughnut_OuterView"}>
        <ChartView
          type={"doughnut"}
          title={t("financialJourney")}
          xAxisName={t("Age In Years")}
          chartData={chartData}
        />
      </div>
    );
  }

  renderNetWorthView() {
    const networthValue = getFormatAmount(this.state.networthValue);
    const formattedDate = formatDate(this.state.lastUpdatedDate);
    return (
      <div className="db_networth_view">
        <div className="db_valueNchartView">
          <div className="db_newtworth_col">
            <div className="db_networth_title">{t("yourNetWorth")}</div>
            <div className="db_networth_amt">{`₹ ${networthValue}`}</div>
            <label className="db_date_view">
              {`${t("Last Updated: ")} ${formattedDate}`}
            </label>
          </div>
          {this.renderDoughnutChartView()}
        </div>
        <div className="db_link" onClick={this.onClickViewDetails.bind(this)}>
          {t("viewDetailsUpdate")}
        </div>
      </div>
    );
  }

  renderGaugeView() {
    const value = this.state.status;
    return (
      <div className={"db_gauge_chart"}>
        <label className="db_gauge_name">
          {t("Dashboard: Your Risk Profile")}
        </label>
        <div className="db_gauge_style">
          <RiskProfileDialler
            value={value}
            needleColor={"#013F60"}
            needleBaseColor={"#013F60"}
          />
        </div>
      </div>
    );
  }

  renderToggleView() {
    return (
      <div className="db_toggle_view">
        <ToggleSwitch
          checked={this.state.isAbsoluteReturn === true}
          checkedChildren={t("Absolute Return")}
          unCheckedChildren={t("XIRR")}
          onChangeToggle={this.handlePortfolioReturnChange.bind(this)}
        />
      </div>
    );
  }

  renderPortBalanceView() {
    const accDetails = this.state.accountDetails;
    const investedAmount = accDetails?.amountinvested ? getFormatAmount(accDetails.amountinvested) : 0;
    const currentValue = accDetails?.currentvalue ? getFormatAmount(accDetails.currentvalue) : 0;
    return (
      <div className="db_port_balance_text">
        <div className="db_port_balance_title">{t("portfolioBalance")}</div>
        <div className="db_port_content">
          <label className="db_port_balance_label">{t("Amount Invested")}</label>
          <label className="db_port_bal_colon">{" : "}</label>
          <label className="db_port_balance_label">{` ₹ ${investedAmount}`}</label>
        </div>
        <div className="db_port_content">
          <label className="db_port_balance_label">{t("Current Market Value")}</label>
          <label className="db_port_bal_colon">{" : "}</label>
          <label className="db_port_balance_label">{` ₹ ${currentValue}`}</label>
        </div>
      </div>
    );
  }

  renderPortFolio() {
    const panNumber = this.state.userPanNumber;
    const accDetails = this.state.accountDetails;
    const portfolioReturn = this.state.isAbsoluteReturn ? accDetails?.absolutereturn : accDetails?.xirr;
    const portfolioReturnVal = portfolioReturn ? portfolioReturn : 0;
    return (
      <div className="db_port_view">
        <label className="db_port_title">
          {t("Dashboard: Your Fliber MF Portfolio")}
        </label>

        <div className="db_port_balance_view">
          <div className="db_port_balance_container">
            {this.renderPortBalanceView()}
            <div className="db_separatorStyle"></div>
            <div className="db_port_balance_text">
              <div className="db_port_balance_title">{t("Portfolio Return")}</div>
              <div className="db_port_balance">
                <label>{`${portfolioReturnVal} %`}</label>
                <div className="db_port_toggle">{this.renderToggleView()}</div>
              </div>
            </div>
          </div>
        </div>

        <a
          className={"db_link_portfolio"}
          href={`https://fliber.themfbox.com/investor/portfolio-fliber?pan=${panNumber}`}>
          {t("View Your Portfolio Details")}
        </a>
      </div>
    );
  }

  renderToggleViewCashLadder = () => {
    return (
      <div className="cf_toggleView">
        <ToggleSwitch
          checkedChildren={t("CashLadder: Income / Expense")}
          unCheckedChildren={t("CashLadder")}
          onChangeToggle={this.handleToggleSwitchChange}
        />
      </div>
    );
  };

  renderToggleSelectionView() {
    return (
      <div className="cf_toggleView">
        <ToggleSwitch
          checkedChildren={t("Growth Bucket")}
          unCheckedChildren={t("Secure Bucket")}
          onChangeToggle={this.handleBucketSwitchChange.bind(this)}
        />
      </div>
    );
  }

  renderBucketTitleView() {
    const displayTitle = this.state.showSecureContent ? t("Secure Bucket") : t("Growth Bucket");
    return (
      <div className="db_bucketTitleView">
        <label className="db_bucketTitleStyle">{t("Target Vs Actual")}</label>
        <label className="db_bucketTitleStyle">{displayTitle}</label>
        {this.renderToggleSelectionView()}
      </div>
    );
  }

  renderBucketInfo() {
    const targetData = this.state.displayTargetData;
    const actualData = this.state.displayActualData;
    const assetValues = this.state.assetValues;
    const targetEquityAllocations = targetData?.filter(item => item.assetclass === 'EQUTY');
    const actualEquityAllocations = actualData?.EQUTY;
    const targetDebtAllocations = targetData?.filter(item => item.assetclass === 'DEBT ');
    const actualDebtAllocations = actualData?.DEBT;
    const targetOthersAllocations = targetData?.filter(item => item.assetclass !== 'EQUTY' && item.assetclass !== 'DEBT ');
    const actualOthersAllocations = actualData?.OTHER;

    return (
      <div className="db_bucket_container">
        {this.renderBucketTitleView()}
        <DataSplitBars
          title={'Equity'}
          targetTotal={assetValues?.equity}
          actualTotal={actualData?.totalValue?.EQUTY}
          target={targetEquityAllocations}
          actual={actualEquityAllocations}
        />
        <DataSplitBars
          title={'Debt'}
          targetTotal={assetValues?.debt}
          actualTotal={actualData?.totalValue?.DEBT}
          target={targetDebtAllocations}
          actual={actualDebtAllocations}
        />
        <DataSplitBars
          title={'Others'}
          targetTotal={assetValues?.others}
          actualTotal={actualData?.totalValue?.OTHER}
          target={targetOthersAllocations}
          actual={actualOthersAllocations}
        />
      </div>
    );
  }

  renderGoalfulfillmentView = () => {
    const preparedGoalData = this.prepareGoalAllocationData();
    return (
      <div className={"db_goalView"}>
        <GoalFulFillmentView
          goalAllocationValues={preparedGoalData}
          onPressApply={this.onPressSearchApply.bind(this)}
        />
      </div>
    );
  };

  renderSimulation() {
    return (
      <div className="db_sim_view">
        <div className="db_sim_text">
          {t("Dashboard: Financial Plan Simulation")}
        </div>
        <div className="db_button_sim">
          <Button
            buttonName={t("DashboardButton: Coming Soon")}
            onPressButton={this.onPressComingSoon.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderFinancialJourney() {
    return (
      <div className="db_financialView">
        <CashLadderFinancialView
          cashLadderValues={this.state.cashLadderValues}
          showDisclaimer={false}
        />
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="db_content_view">
        <div className="db_content">
          {this.renderNetWorthView()}
          {this.renderPortFolio()}
        </div>
        <div className="db_line_chart_view">
          {this.renderFinancialJourney()}
          {this.renderGaugeView()}
        </div>
        <div className="db_disclaimerView">
          <label className="db_disclaimerStyle">{"This is an illustrative scenario plan with respect to your financial goals and details that you have provided to us. This may vary from actuals and Fliber or Lookinglaz Technologies LLP does not guarantee any returns whatsoever. "}</label>
        </div>
        <label className="db_port_name">Portfolio Asset Allocation</label>
        {this.renderBucketInfo()}
        {this.renderGoalfulfillmentView()}
        {this.renderSimulation()}
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="db_container">
        {this.renderContentView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default Dashboard;
