import React from 'react';
import './datasplitbars.css';
import { t } from 'i18next';
import { CircularProgressBar } from '../components';

const DataSplitBars = (props) => {


  const getAllLabels = () => {
    let allLabels = props?.target?.concat(props?.actual);
    let uniqueLabelsMap = new Map();

    allLabels?.forEach(item => {
      const sebicategory = item?.sebicategory;

      // Check if the sebicategory is not empty and not already in the Map
      if (sebicategory !== "" && !uniqueLabelsMap?.has(sebicategory)) {
        uniqueLabelsMap?.set(sebicategory, item);
      }
    });

    // Convert Map values to an array
    let uniqueLabels = Array.from(uniqueLabelsMap?.values());
    
    return uniqueLabels;
  }

  const getSplitBarColors = (string) => {
    const colors = [
      '#E74C3C', '#DCAC00', '#F69698', '#FDBE90', '#FFDC84',
      '#E9EB87', '#7EC296', '#324155', '#FFC700', '#6C8FF7',
      '#FFF4CF', '#FFCA7A', '#FFE8AE', '#BDC3C7', '#FEC77D',
      '#FDECE9', '#BDD9CD', '#AFC9E2', '#E4EEE5', '#D6E511',
      '#CBDBEB', '#D1DBE5', '#EADEC7', '#F37775', '#EDE7B4',
      '#E8D8E1', '#0E4969', '#0173B0', '#85BBD6', '#77AECA',
      '#5B95B3', '#3E7C9C', '#7F7FFF', '#EEF8FF', '#FFBFBF',
      '#74A56B', '#BFFFBF', '#D9F3FF', '#84B7C2', '#29A54E'
    ];

    let hash = 0;

    // Compute the hash value of the string
    for (let i = 0; i < string?.length; i++) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Get the index within the range of distinctiveColors array
    const index = Math.abs(hash) % colors.length;

    // Return the color at the generated index
    return colors[index];
  }

  const renderSubLabelsView = () => {
    return (
      <div className='sb_subLabelsView'>
        <label className='sb_subLabelStyle'>{t("Target")}</label>
        <label className='sb_subLabelStyle'>{t("Actual")}</label>
        <label className='sb_subLabelStyle'>{ }</label>
      </div>
    );
  }

  const renderDisplayTargetValues = () => {
    return (
      <div className="sb_valuesView">
        <div className="split-bar">
          {props?.target?.map((value, index) => (
            <div
              key={index}
              className="bar-segment"
              style={{
                width: `${value.allocationpercentage}%`,
                backgroundColor: getSplitBarColors(value.sebicategory)
              }}
              title={`${value.allocationpercentage}%`}
            />
          ))}
        </div>
      </div>
    );
  }

  const renderDisplayActualValues = () => {
    return (
      <div className="sb_valuesView">
        <div
          className="split-bar"
        >
          {props?.actual?.map((value, index) => (
            <div
              key={index}
              className="bar-segment"
              style={{
                width: `${value.allocationpercentage}%`,
                backgroundColor: getSplitBarColors(value.sebicategory)
              }}
              title={`${(value.allocationpercentage).toFixed(2)}%`}
            />
          ))}
        </div>
      </div>
    );
  }

  const renderLabelsDisplayView = () => {
    const labels = getAllLabels();
    const showLabels=labels?.length>0;
    return (
     showLabels? <div className={"db_labelDisplayView"}>
        {labels?.map((value, index) => (
          <div key={index} className='db_labelNtextView'>
            <div
              className={'db_labelViewStyle'}
              style={{
                backgroundColor: getSplitBarColors(value?.sebicategory)
              }}
            />
            <label className='db_labelTextStyle'>{value?.sebicategorydescription}</label>
          </div>
        ))}
      </div>
      :<></>
    );
  }

  const renderChartLabelDisplayView = (label, tileColor) => {
    return (
      <div className='db_labelDisplayView'>
        <div
          className={'db_labelViewStyle'}
          style={{
            backgroundColor: tileColor
          }}
        />
        <label className='db_labelTextStyle'>{label}</label>
      </div>
    );
  }

  const renderDoughnutChartView = () => {
    return (
      <div className={"db_chartOuterView"}>
        <CircularProgressBar
          percent={Number(props.targetTotal)}
          strokeWidth={12}
          strokeColor={'#6C8FF7'} />
        <CircularProgressBar
          percent={Number(props.actualTotal)}
          strokeWidth={12}
          strokeColor={'#FEC64D'} />
        <div>
          {renderChartLabelDisplayView(t('Target'), '#6C8FF7')}
          {renderChartLabelDisplayView(t('Actual'), '#FEC64D')}
        </div>
      </div>
    );
  }

  return (
    <div className="sb_container">
      <label className='sb_titleStyle'>{props.title}</label>
      {renderSubLabelsView()}
      <div className='sb_targetNactualView'>
        {renderDisplayTargetValues()}
        {renderDisplayActualValues()}
        {renderLabelsDisplayView()}
      </div>
      {renderDoughnutChartView()}
    </div>
  );
};

export default DataSplitBars;
