import React, { Component } from "react";
import {
  Button,
  FloatingLabelInput,
  ProgressDialog,
  TitleView,
  Toast,
} from "../components";
import { t } from "i18next";
import * as Bl from '../../businesslogic';
import "./configuration.css";
import { getSelectedUserData, getUserRole, resetSelectedUserDetail, resetUserCashLadderDetail } from "../common";
import { USER_ROLE } from "../constants";
import { Modal } from "rsuite";
import CloseIcon from "../../assets/svgs/ic_close.svg";

class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreviewModalOpen: false,
      previewData: {},
      configData: {},
      dbData: {},
      showToast: false,
      showProgress: false
    };
  }

  componentDidMount() {
    //When admin came out from the user screens,remove selectedUserDetail from localstorage
    if (getSelectedUserData()?.id) {
      resetSelectedUserDetail();
      resetUserCashLadderDetail();
    }

    this.getConfigValues();
  }

  onChange(key, value) {
    const configData = this.state.configData;
    configData[key] = value;
    this.setState({ configData });
  }

  onPressPreviewButton() {
    this.setState({
      isPreviewModalOpen: true,
    });
  }

  onHideModal = () => {
    this.setState({ isPreviewModalOpen: false });
  };

  onPressSubmitButton() {
    const configData = this.state.configData;

    const newData = {
      retirementage: Number(configData.retirementage),
      lifeexpectancy: Number(configData.lifeexpectancy),
      generalinflation: Number((configData.generalinflation / 100).toFixed(2)),
      fdror: Number((configData.fdror / 100).toFixed(2)),
      epfror: Number((configData.epfror / 100).toFixed(2)),
      ppfror: Number((configData.ppfror / 100).toFixed(2)),
      equityindexror: Number((configData.equityindexror / 100).toFixed(2)),
      debtindexror: Number((configData.debtindexror / 100).toFixed(2)),
      realestateror: Number((configData.realestateror / 100).toFixed(2)),
      postretirementror: Number((configData.postretirementror / 100).toFixed(2)),
      npslumwithdrawal: Number(configData.npslumwithdrawal),
      npsyear: Number(configData.npsyear),
      npsannuityrate: Number((configData.npsannuityrate / 100).toFixed(2)),
      sip_growth: Number((configData.sip_growth / 100).toFixed(2)),
      securebucketror: Number((configData.securebucketror / 100).toFixed(2)),
      securebucketduration: Number(configData.securebucketduration),
      commodityror: Number((configData.commodityror / 100).toFixed(2)),
      livemodestlyinflation: Number((configData.livemodestlyinflation / 100).toFixed(2)),
      livelavishlyinflation: Number((configData.livelavishlyinflation / 100).toFixed(2)),
      vacationinflation: Number((configData.vacationinflation / 100).toFixed(2)),
      healthinflation: Number((configData.healthinflation / 100).toFixed(2)),
      educationinflation: Number((configData.educationinflation / 100).toFixed(2)),
      weddinginflation: Number((configData.weddinginflation / 100).toFixed(2)),
      drift_percentage: Number((configData.drift_percentage / 100).toFixed(2)),
      drift_duration: Number(configData.drift_duration),
      "id": "2ac4a0b2-c4a1-11ec-80c8-02fc9d5aa1f3"
    }

    console.log({ newData })
    this.setState({ showProgress: true }, () => {
      Bl.Common.updateConfigInfo(newData).then((response) => {
        this.setState({
          showProgress: false,
          previewData: {},
          isPreviewModalOpen: false,
        });
        this.showToast('Configuration updated successfully')
      }).catch((error) => {
        console.error('Error fetching config data:', error);
        this.showToast('Unable to update the config details')
      });
    })

  }

  getConfigValues() {
    this.setState({ showProgress: true }, () => {
      Bl.Common.getCommonConfigInfo()
        .then((configList) => {
          const configData = configList && configList.length > 0 ? configList[0] : {};
          console.log({ configData })
          const modifiedData = {
            retirementage: configData.retirementage,
            lifeexpectancy: configData.lifeexpectancy,
            generalinflation: (configData.generalinflation * 100).toFixed(2),
            fdror: (configData.fdror * 100).toFixed(2),
            epfror: (configData.epfror * 100).toFixed(2),
            ppfror: (configData.ppfror * 100).toFixed(2),
            equityindexror: (configData.equityindexror * 100).toFixed(2),
            debtindexror: (configData.debtindexror * 100).toFixed(2),
            realestateror: (configData.realestateror * 100).toFixed(2),
            postretirementror: (configData.postretirementror * 100).toFixed(2),
            npslumwithdrawal: configData.npslumwithdrawal,
            npsyear: configData.npsyear,
            npsannuityrate: (configData.npsannuityrate * 100).toFixed(2),
            sip_growth: (configData.sip_growth * 100).toFixed(2),
            securebucketror: (configData.securebucketror * 100).toFixed(2),
            securebucketduration: configData.securebucketduration,
            commodityror: (configData.commodityror * 100).toFixed(2),
            livemodestlyinflation: (configData.livemodestlyinflation * 100).toFixed(2),
            livelavishlyinflation: (configData.livelavishlyinflation * 100).toFixed(2),
            vacationinflation: (configData.vacationinflation * 100).toFixed(2),
            healthinflation: (configData.healthinflation * 100).toFixed(2),
            educationinflation: (configData.educationinflation * 100).toFixed(2),
            weddinginflation: (configData.weddinginflation * 100).toFixed(2),
            drift_percentage: (configData.drift_percentage * 100).toFixed(2),
            drift_duration: configData.drift_duration
          }
          this.setState({
            configData: modifiedData,
            dbData: JSON.parse(JSON.stringify(modifiedData)),
            showProgress: false
          });
        })
        .catch((error) => {
          console.error('Error fetching config data:', error);
          this.showToast('Unable to get the config details')
        });
    })
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  renderTitleView() {
    const title = t("configuration: Title");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="con_retInput">
        <FloatingLabelInput
          label={label}
          value={value || value === 0 ? value : ''}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderInputSection(label, value, key, onChange, showPercentage = false) {
    return (
      <div className="con_item_Name" key={key}>
        {this.renderInputView(
          label,
          value,
          (value) => onChange(key, value),
          false,
          showPercentage
        )}
      </div>
    );
  }

  renderConfigurationView() {
    const configData = this.state.configData;
    const inputSections = [
      {
        label: "Retirement Age",
        key: 'retirementage',
        value: configData.retirementage,
        showPercentage: false
      },
      {
        label: "Life Expectancy",
        key: 'lifeexpectancy',
        value: configData.lifeexpectancy,
        showPercentage: false
      },
      {
        label: "General Inflation",
        key: 'generalinflation',
        value: configData.generalinflation,
        showPercentage: true
      },
      {
        label: "FD ror",
        key: 'fdror',
        value: configData.fdror,
        showPercentage: true
      },
      {
        label: "EPF ror",
        key: 'epfror',
        value: configData.epfror,
        showPercentage: true
      },
      {
        label: "PPF ror",
        key: 'ppfror',
        value: configData.ppfror,
        showPercentage: true
      },
      {
        label: "Real Estate ror",
        key: 'realestateror',
        value: configData.realestateror,
        showPercentage: true
      },
      {
        label: "Post Retirement ror",
        key: 'postretirementror',
        value: configData.postretirementror,
        showPercentage: true
      },
      {
        label: "NPS Lumpsum Withdrawal",
        key: 'npslumwithdrawal',
        value: configData.npslumwithdrawal,
        showPercentage: false
      },
      {
        label: "NPS Year",
        key: 'npsyear',
        value: configData.npsyear,
        showPercentage: false
      },
      {
        label: "NPS Annuity Rate",
        key: 'npsannuityrate',
        value: configData.npsannuityrate,
        showPercentage: true
      },
      {
        label: "Sip Growth",
        key: 'sip_growth',
        value: configData.sip_growth,
        showPercentage: true
      },
      {
        label: "Secure Bucket ror",
        key: 'securebucketror',
        value: configData.securebucketror,
        showPercentage: true
      },
      {
        label: "Secure Bucket Duration",
        key: 'securebucketduration',
        value: configData.securebucketduration,
        showPercentage: false
      },
      {
        label: "Drift Percentage",
        key: 'drift_percentage',
        value: configData.drift_percentage,
        showPercentage: true
      },
      {
        label: "Drift Duration",
        key: 'drift_duration',
        value: configData.drift_duration,
        showPercentage: false
      },
      {
        label: "Live Modestly Inflation",
        key: 'livemodestlyinflation',
        value: configData.livemodestlyinflation,
        showPercentage: true
      },
      {
        label: "Live Lavishly Inflation",
        key: 'livelavishlyinflation',
        value: configData.livelavishlyinflation,
        showPercentage: true
      },
      {
        label: "Health Inflation",
        key: 'healthinflation',
        value: configData.healthinflation,
        showPercentage: true
      },
      {
        label: "Education Inflation",
        key: 'educationinflation',
        value: configData.educationinflation,
        showPercentage: true
      },
      {
        label: "Vacation Inflation",
        key: 'vacationinflation',
        value: configData.vacationinflation,
        showPercentage: true
      },
      {
        label: "Wedding Inflation",
        key: 'weddinginflation',
        value: configData.weddinginflation,
        showPercentage: true
      },
      {
        label: "Equity Index ror",
        key: 'equityindexror',
        value: configData.equityindexror,
        showPercentage: true
      },
      {
        label: "Debt Index ror",
        key: 'debtindexror',
        value: configData.debtindexror,
        showPercentage: true
      },
      {
        label: "Commodity ror",
        key: 'commodityror',
        value: configData.commodityror,
        showPercentage: true
      },
    ];

    return (
      <div className="con_name_view">
        {inputSections.map(({ label, value, key, showPercentage }) =>
          this.renderInputSection(
            label,
            value,
            key,
            this.onChange.bind(this),
            showPercentage
          )
        )}
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="con_bottomView">
        <div className="con_preview_button">
          <Button
            buttonName={t("configuration Button : Preview")}
            emptyButton={true}
            onPressButton={this.onPressPreviewButton.bind(this)}
          />
        </div>

        <div className="con_submit">
          <Button
            buttonName={t("configuration Button : Submit")}
            onPressButton={this.onPressSubmitButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  prepareConfigItems(configData) {
    return [
      {
        label: "Retirement Age",
        value: configData.retirementage,
        key: "retirementAge",
      },
      {
        label: "Life Expectancy",
        value: configData.lifeexpectancy,
        key: "lifeexpectancy",
      },
      {
        label: "General Inflation",
        value: configData.generalinflation,
        key: "generalinflation",
      },
      {
        label: "FD ror",
        value: configData.fdror,
        key: "fdror"
      },
      {
        label: "EPF ror",
        value: configData.epfror,
        key: "epfror"
      },
      {
        label: "PPF ror",
        value: configData.ppfror,
        key: "ppfror"
      },
      {
        label: "Equity Index ror",
        value: configData.equityindexror,
        key: "equityindexror",
      },
      {
        label: "Debt Index ror",
        value: configData.debtindexror,
        key: "debtindexror",
      },
      {
        label: "Commodity ror",
        value: configData.commodityror,
        key: "commodityror",
      },
      {
        label: "Real Estate ror",
        value: configData.realestateror,
        key: "realestateror",
      },
      {
        label: "Post Retirement ror",
        value: configData.postretirementror,
        key: "postretirementror",
      },
      {
        label: "NPS Lumpsum Withdrawal",
        value: configData.npslumwithdrawal,
        key: "npslumwithdrawal",
      },
      {
        label: "NPS Year",
        value: configData.npsyear,
        key: 'npsyear',
      },
      {
        label: "NPS Annuity Rate",
        value: configData.npsannuityrate,
        key: "npsannuityrate",
      },
      {
        label: "Sip Growth",
        value: configData.sip_growth,
        key: 'sip_growth',
      },
      {
        label: "Secure Bucket ror",
        value: configData.securebucketror,
        key: "securebucketror",
      },
      {
        label: "Secure Bucket Duration",
        value: configData.securebucketduration,
        key: "securebucketduration",
      },
      {
        label: "Drift Percentage",
        value: configData.drift_percentage,
        key: "drift_percentage",
      },
      {
        label: "Drift Duration",
        value: configData.drift_duration,
        key: "drift_duration",
      },
      {
        label: "Live Modestly Inflation",
        value: configData.livemodestlyinflation,
        key: "livemodestlyinflation",
      },
      {
        label: "Live Lavishly Inflation",
        value: configData.livelavishlyinflation,
        key: "livelavishlyinflation",
      },
      {
        label: "Vacation Inflation",
        value: configData.vacationinflation,
        key: "vacationinflation",
      },
      {
        label: "Health Inflation",
        value: configData.healthinflation,
        key: "healthinflation",
      },
      {
        label: "Education Inflation",
        value: configData.educationinflation,
        key: "educationinflation",
      },
      {
        label: "Wedding Inflation",
        value: configData.weddinginflation,
        key: "weddinginflation",
      },
    ];
  }

  renderModalData() {
    const configData = this.state.configData;
    const configItems = this.prepareConfigItems(configData);

    return (
      <div className="con_modal_view">
        <div className="con_modal_title_view">
          <div></div>
          <label className="con_modal_title_style">{t("configuration: Title")}</label>
          <img
            src={CloseIcon}
            alt={"cancel"}
            className={"con_cancel_icon"}
            onClick={this.onHideModal.bind(this)}
          ></img>
        </div>
        <table className="con_table_view">
          {configItems.map((item, index) => {
            const isUpdated = item.value !== this.state.dbData[item.key.toLowerCase()];
            return (
              <tr
                key={index}
                className={isUpdated ? "con_updatedData" : "con_dbData"}
              >
                <td className="con_label">{item.label}</td>
                <td>:</td>
                <td className="con_value">{item.value}</td>
              </tr>
            );
          })}
        </table>
      </div>
    );
  }

  renderModalView() {
    return (
      <Modal
        open={this.state.isPreviewModalOpen}
        onClose={this.onHideModal.bind(this)}
        className={"con-dialog-class"}
      >
        {this.renderModalData()}
      </Modal>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  };

  renderContentView() {
    return (
      <div className="con_content">
        {this.renderTitleView()}
        <div className="con_view">{this.renderConfigurationView()}</div>
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
  render() {
    return (
      <div className="con_container">
        {this.renderContentView()}
        {this.renderButtonsView()}
        {this.state.isPreviewModalOpen && this.renderModalView()}
      </div>
    );
  }
}

export default Configuration;
