import { useRef, useState ,useEffect} from 'react';
import { t } from 'i18next';
import { Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './index.css';
import * as Bl from "../../../../businesslogic";
import {
  ProgressDialog,
  Toast
} from '../../../components';
import Close from '../../../../assets/svgs/websvgs/close.svg';


const ReportModal = (props) => {
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [state, setState] = useState({
    
    showToast: false,
    toastMessage: '',
    showProgress: false,
    progressMessage: '',
  });

useEffect(() => {
    const fetchComplaints = async () => {
      try {
        const responses = await Bl.Support.getMonthEndingComplaints();
        const complaints = responses?.data || [];    
        setRowData(complaints);
      } catch (error) {
        console.error('Error fetching complaints:', error);
        setRowData([]);
      }
    };

    fetchComplaints(); 
  }, []);

  const onClickClose = () => {
    if (props.hideModal) {
      props.hideModal()
    }
  }

  const columnDefs = [
  {
    headerName: 'Received From',
    field: 'interactionsource',
    flex: 2, 
    minWidth: 150, 
    resizable: true,
  },
  {
    headerName: 'Pending at the end of last month',
    field: 'Pending_Last_Month',
    cellRenderer: ({ data }) => <label>{data.Pending_Last_Month}</label>,
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Pending at the end of the month',
    field: 'Pending_End_Of_Month',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Received',
    field: 'Received',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Resolved',
    field: 'Resolved',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Total Pending',
    field: 'Total_Pending',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Pending Complaints > 3',
    field: 'Pending_Complaints_3_Months',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  }
];
 

  const showToast = (toastMessage) => {
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }


  const renderHeaderView = (label) => {
    return (
      <div className='rm_headerView'>
        <label className='rm_headingStyle'>{label}</label>
        <img className='rm_closeIconStyle' src={Close} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderTableView = () => {
    return (
      <div className='rm_tableView'>
        <div className="ag-theme-alpine" style={{ height: 400, width: 950 }}>
          <AgGridReact
            ref={gridRef}
           rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={{
              wrapHeaderText: true,
              autoHeaderHeight: true,
            }}
            onGridReady={params => {
              params.api.sizeColumnsToFit();
            }}
          >
          </AgGridReact>
        </div>
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  const renderFormContent = () => {
    return (
      <div className={'rm_content'}>
        {renderHeaderView(t('Complaint Report'))}
        {renderTableView()}
      </div>
    )
  }

  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      dialogClassName={'rm_container'}
      size={"md"}
    >
      <div>
        {renderFormContent()}
        {state.showToast && renderToastView()}
        {state.showProgress && renderProgressDialog()}
      </div>
    </Modal>
  );
}

export default ReportModal;