import React, { Component } from "react";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  Slider,
  TitleView,
  Toast,
  ProgressDialog,
  CostItemPerYear,
  ToggleSwitch
} from "../components";
import "./vehicleexpensepage.css";
import * as Bl from "../../businesslogic";
import { GOAL_CATEGORY, DEFAULT_OFFSET, GOAL_FREQUENCY_TYPE, GOAL_EXPENSE_TYPE } from "../constants";
import { calculate_age, calculate_year, getAdvisorId, getGoalsPathName, getUserDOB, getUserId } from "../common";
import { getCurrentYear } from "../utils/datetime";
import { getGoalConfigInfo, getCurrentAge } from "../utils/common";
const newGoal = {
  userid: '',
  goalcategory: GOAL_CATEGORY.VEHICLE,
  goalstartyear: 0,
  goalendyear: 0,
  goalamountpv: 0,
  goalfrequency: 1,
  advisorid: getAdvisorId(),
  goalfrequencytype: GOAL_FREQUENCY_TYPE.YEARLY,
  goalbucketcode: "",
  retirewanttype: "",
  monthlyexpenselist: [],
  goaldescription: "",
  goalinflationrate: 0,
  goalamountfv: 0,
  homeloanstatus: false,
  downpayment: 100,
  goaldetails: [],
  goalstartage: 0,
  goalendage: 0,
  userage: 0,
  expensetype:GOAL_EXPENSE_TYPE.ONE_TIME
};
class VehicleExpensePage extends Component {
  constructor(props) {
    super(props); const params = props.location.state;
    const isEditView = params?.isEditView ? params.isEditView : false;
    const goalData = params?.goalData;
    this.state = {
      startValue: isEditView ? goalData.goalstartyear : getCurrentYear() + 1,
      startYear: getCurrentYear() + 1,
      description: isEditView ? goalData.goaldescription : "",
      cost: isEditView ? goalData.goalamountpv : "",
      inflationRate: isEditView ? goalData.goalinflationrate : 0,
      currentAge: 0,
      viewExpense: false,
      homeLoanStatus: isEditView ? goalData.homeloanstatus : false,
      downpayment: isEditView ? goalData.downpayment : 100,
      flowAmount: 0,
      isEditView: isEditView ? isEditView : false,
      newGoalData: isEditView ? new Bl.Goals(goalData) : new Bl.Goals({ ...newGoal, userid: getUserId() }),
    };
    this.costPerYearViewRef = React.createRef();
    this.inflationRate = 0;
    this.isRenderingAlready = false;
    this.dbValue = this.state.newGoalData;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      !this.state.isEditView && this.getInflationRate();
      this.getCurrentAge();
    }
  }

  onSelectItem(path) {
    const localData = new Bl.Goals(this.state.newGoalData)
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeDescription(value) {
    const goals = this.state.newGoalData;
    goals.setDescription(value);
    this.setState({ description: value });
  }

  onChangePropertyCost(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalAmountPv(value ? Number(value) : 0);
    this.setState({ cost: value, viewExpense: false });
  }

  onChangeIncreasePrice(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalInflationRate(value ? Number(value) : 0);
    this.setState({ inflationRate: value, viewExpense: false });
  }

  getInflationRate() {
    //TODO get inflation rate from API and use that
    this.setState({ showProgress: true, fetchingInflationRate: true }, () => {
      getGoalConfigInfo(GOAL_CATEGORY.VEHICLE)
        .then((configList) => {
          const configInfo = configList;
          const goalsObj = this.state.newGoalData;
          goalsObj.setGoalInflationRate(configInfo.inflationrate);
          this.dbValue.goalinflationrate = configInfo.inflationrate;
          this.inflationRate = configInfo.inflationrate;
          this.setState({
            inflationRate: configInfo.inflationrate,
            showProgress: false,
            fetchingInflationRate: false
          }, () => {
            this.getDBValue()
          });
        })
        .catch((error) => {
          console.log("VehicleExpensePage.js/getInflationRate Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true, fetchingCurrentAge: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const goalsObj = this.state.newGoalData;
          goalsObj.setUserAge(currentAge);
          this.userage = currentAge;
          this.dbValue.userage = currentAge;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
            fetchingCurrentAge: false
          }, () => {
            this.getDBValue();
          });
        })
        .catch((error) => {
          console.log("VehicleExpensePage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getDBValue() {
    if(this.state.fetchingInflationRate === false && this.state.fetchingCurrentAge === false){
      const dbValue = this.state.newGoalData
      ? JSON.parse(JSON.stringify(this.state.newGoalData))
      : {};
      this.setState({dbValue})
    }
  }

  isValueValid(value) {
    return value > 0;
  }
  isInflationValid(data) {
    let isValid = true;

    if (!data || data.toString().length <= 0) {
      isValid = false;
    }
    return isValid;
  }

  checkIsDataValid(data, skipDescriptionValidation = false) {
    let isValid = true;
    let message = "";
    if (!skipDescriptionValidation && !data.goaldescription) {
      isValid = false;
      message = t("vehicleToastDescription")
    } else if (!this.isValueValid(data.goalamountpv)) {
      isValid = false;
      message = t("vehicleToastCost");
    } else if (!this.isInflationValid(data.goalinflationrate)) {
      isValid = false;
      message = t("vehicleToastInflationPercentage");
    }
    return { isValid, message };
  }

  refreshData() {
    newGoal.goalinflationrate = this.inflationRate;
    newGoal.userage = this.userage;
    this.setState({
      description: "",
      cost: "",
      inflationRate: this.inflationRate,
      startValue: getCurrentYear() + 1,
      downpayment: 100,
      homeLoanStatus: false,
      viewExpense: false,
      showButtons: false,
      newGoalData: new Bl.Goals({ ...newGoal, userid: getUserId() }),
    });
  }

  navigateToNextScreen() {
    this.props.navigate("/goalscategorize", { state: { path: "Vehicle" } });
  }
  createVehicleGoal(callbackfn) {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (goalObj.goalstartyear === 0) {
      const currentYear = getCurrentYear();
      const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
      goalObj.setStartYear(this.state.startValue);
      goalObj.setGoalStartAge(startAge)
    }
    // Removed front & back white spaces in Description
    if (goalObj.goaldescription) {
      const goaldescription = goalObj.goaldescription.trim();
      goalObj.setDescription(goaldescription);
    }
    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true, viewExpense: false }, () => {
        goalObj
          .createGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log("VehicleExpensePage/createVehicleGoal Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateVehicleGoal(callbackfn) {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    // Removed front & back white spaces in Description
    if (goalObj.goaldescription) {
      const goaldescription = goalObj.goaldescription.trim();
      goalObj.setDescription(goaldescription);
    }
    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true, viewExpense: false }, () => {
        goalObj
          .updateGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log("VehicleExpensePage/updateVehicleGoal Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }
  prepareData(data) {
    const sliderContent = [];
    data.map((item) =>
      sliderContent.push({
        year: calculate_year(item.cashflowage),
        money: item.flowamount,
      })
    );
    return sliderContent;
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const startAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const goals = this.state.newGoalData;
    goals.setStartYear(newValue);
    goals.setGoalStartAge(startAge)
    this.setState({ startValue: newValue, viewExpense: false });
  }

  handleChangeEnd(event) {
    const value = event.target.value;
    const goals = this.state.newGoalData;
    goals.setDownPayment(value);
    this.setState({ downpayment: value });
  }

  handleToggleSwitchChange(selectedItem) {
    const goals = this.state.newGoalData;
    const homeLoanStatus = selectedItem === t("vehicleToggle1") ? true : false;
    goals.setHomeLoanStatus(homeLoanStatus);
    this.setState({ homeLoanStatus: homeLoanStatus });
  }

  handleSelectSlider(index) {
    this.setState({ selectedSliderIndex: index });
  }

  onPressSaveButton() {
    const { showButtons } = this.state;
    if (!showButtons) {
      return;
    }
    if (!this.state.isEditView) {
      this.createVehicleGoal(this.navigateToNextScreen.bind(this));
    }
    else {
      this.updateVehicleGoal(this.navigateToNextScreen.bind(this));
    }
  }

  onPressViewExpense() {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (goalObj.goalstartyear === 0) {
      const currentYear = getCurrentYear();
      const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
      goalObj.setStartYear(this.state.startValue);
      goalObj.setGoalStartAge(startAge)
      this.setState({ startYear: getCurrentYear() + 1 });
    }
    const isDataValid = this.checkIsDataValid(goalObj, true);

    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        goalObj
          .viewExpectedAmt()
          .then((data) => {
            this.setState({
              flowAmount: data.result[0].flowamount,
              showProgress: false,
              viewExpense: true,
              showButtons: true
            }, () => {
              this.costPerYearViewRef.current.scrollIntoView({ behavior: "smooth" });
            });
          })
          .catch((error) => {
            console.log("VehicleExpensePage/onPressViewExpense Error:", error);
            this.showToast(t('Some error has occurred'));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  onPressSaveAndAdd() {
    const { showButtons } = this.state;
    if (!showButtons) {
      return;
    }
    this.createVehicleGoal(this.refreshData.bind(this));
  }


  renderNavView() {
    const items = getGoalsPathName("Vehicle");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        dbData={this.dbValue}
        localData={this.state.newGoalData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("vehicleTitle");
    return <TitleView title={title} information={""} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="veh_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderVehicleExpenseDetails() {
    return (
      <div className="veh_description_view">
        <div className="veh_input_row">
          {this.renderInputView(
            t("vehicleInputDescription"),
            this.state.description,
            this.onChangeDescription.bind(this)
          )}
          {this.renderInputView(
            t("vehicleInputCost"),
            this.state.cost,
            this.onChangePropertyCost.bind(this),
            true
          )}
        </div>
        <div className="veh_input_sec_row">
          {this.renderInputView(
            t("vehicleInputInflationRate"),
            this.state.inflationRate,
            this.onChangeIncreasePrice.bind(this),
            false,
            true
          )}
        </div>
      </div>
    );
  }

  renderYearView() {
    const currentYear = getCurrentYear();
    const age = calculate_age(this.state.startValue, currentYear, this.state.currentAge);
    return (
      <div className="veh_slider_view">
        <div className="veh_start_year_row">
          <label className="veh_start_text">
            {t("vehicleSlider1")}
          </label>
          <div className="veh_slider_content">
            <Slider
              value={this.state.startValue}
              age={age}
              min={currentYear + 1}
              max={currentYear + 11}
              markStep={2}
              handleChange={this.handleChangeStart.bind(this)}
              showAge={true}
              showPercentage={false}
            />
          </div>
        </div>
      </div>
    );
  }

  renderCostItemPerYear() {
    const label = `${t("vehicleCostYear")} ${this.state.startValue}`;
    return (
      <div className="veh_cost_item_view" ref={this.costPerYearViewRef}>
        <CostItemPerYear
          item={{
            label: label,
            cost: this.state.flowAmount,
          }}
        />
      </div>
    );
  }

  renderDownPaymentPerYear() {
    const label = `${t("vehicleDownPayment")} ${
      this.state.startValue
    }`;
    const cost = (this.state.flowAmount * this.state.downpayment) / 100;
    return (
      <div className="veh_cost_item_view">
        <CostItemPerYear
          item={{
            label: label,
            cost: cost,
          }}
        />
      </div>
    );
  }

  renderDownPaymentView() {
    return (
      <div className="veh_start_year_row">
        <label className="veh_start_text">
          {t("vehicleSlider2")}
        </label>
        <div className="veh_slider_content">
          <Slider
            value={this.state.downpayment}
            min={0}
            max={100}
            markStep={20}
            handleChange={this.handleChangeEnd.bind(this)}
            showAge={false}
            showPercentage={true}
          />
        </div>
        {this.state.viewExpense && this.renderDownPaymentPerYear()}
      </div>
    );
  }

  renderHomeLoanView() {
    return (
      <div className="veh_money_inflation">
        <div className="veh_frequency_row">
          <label className="veh_expense_frequency_text">
            {t("vehicleToggleHomeLoan")}
          </label>
          <ToggleSwitch
            checked={this.state.homeLoanStatus === true}
            checkedChildren={t("vehicleToggle1")}
            unCheckedChildren={t("vehicleToggle2")}
            onChangeToggle={this.handleToggleSwitchChange.bind(this)}
          />
        </div>
        {this.state.homeLoanStatus && this.renderDownPaymentView()}
      </div>
    );
  }

  renderButtonsView() {
    const { showButtons } = this.state;
    return (
      <div className="veh_bottomView">
        {!this.state.isEditView && <div className="veh_buttonView">
          <div>
            <label
              onClick={this.onPressSaveAndAdd.bind(this)}
              className="veh_link_text"
              disabled={!showButtons}
              style={{ opacity: showButtons ? 1 : 0.5 }}
            >
              {t("vehicleButtonLink")}
            </label>
          </div>
        </div>}
        <div className="veh_expense_button">
          <Button
            buttonName={t("vehicleButton1")}
            emptyButton={true}
            onPressButton={this.onPressViewExpense.bind(this)}
          />
        </div>
        <div className="veh_save">
          <Button
            buttonName={t("vehicleButton2")}
            onPressButton={this.onPressSaveButton.bind(this)}
            disabled={!showButtons}
            style={{ opacity: showButtons ? 1 : 0.5 }}
          />
        </div>
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="veh_content_view">
        {this.renderTitleView()}
        {this.renderVehicleExpenseDetails()}
        <div className="veh_seperatorStyle" />
        {this.renderYearView()}
        {this.state.viewExpense && this.renderCostItemPerYear()}
        <div className="veh_seperatorStyle" />
        {this.renderHomeLoanView()}
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  // renderDrawerContent() {
  //   const headerTitle = t("financialPlan");
  //   const step = 2;
  //   return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  // }
  render() {
    return (
      <div className="veh_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default VehicleExpensePage;
