import React from "react";
import './assetdetailscard.css';
import Expand from "../../assets/svgs/ic_expand.svg";
import { t } from "i18next";

class AssetDetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  onClickExpandIcon() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  renderLogoView(cardDetails) {
    const source = cardDetails.url;
    return <img src={source} alt="Fund" className={"adc_logoStyle"} />;
  }

  renderTitleView(cardDetails) {
    const title = cardDetails.name;
    return (
      <div className="adc_title_view">
        <label className="adc_titleNamtView">
          <label className="adc_titleStyle">{title}</label>
        </label>
      </div>
    )
  }

  renderDetailsView(cardDetails) {
    return (
      <div className="adc_title_RowView">
        {this.renderRatingView(cardDetails.ratingUrl, cardDetails.rating)}
        {this.renderLabelNValueView(t("sip"), `₹ ${cardDetails.sip}`)}
        {this.renderLabelNValueView(t("lumpsum"), `₹ ${cardDetails.lumpsum}`)}
      </div>
    )
  }

  renderExpandIconView() {
    const isExpanded = this.state.isExpanded;
    return (
      <div
        className="adc_expandIconView"
        onClick={this.onClickExpandIcon.bind(this)}
      >
        <img
          className={isExpanded ? "adc_expand_icon" : "adc_expand_icon_rorate"}
          src={Expand}
          alt={"Expand"}
        />
      </div>
    );
  }
  renderContentHeaderView(cardDetails) {
    return (
      <div className="adc_contentHeader">
        {this.renderLogoView(cardDetails)}
        {this.renderTitleView(cardDetails)}
        {this.renderExpandIconView(cardDetails)}
      </div>
    );
  }

  renderLabelNValueView(label, value) {
    return (
      <div className="adc_labelNvalueView">
        <label className="adc_labelStyle">{label}</label>
        <label className="adc_valueStyle">{value}</label>
      </div>
    );
  }

  renderRatingView(ratingUrl, rating) {
    const ratingView = [];
    for (let index = 0; index < rating; index++) {
      ratingView.push("*");
    }
    return (
      <div className="adc_labelNvalueView">
        <img src={ratingUrl} alt={"Rating"} className={"adc_ratingUrlStyle"} />
        <div className="adc_ratingStyle">{ratingView}</div>
      </div>
    );
  }

  renderExtraInfoView(cardDetails) {
    return (
      <div className="adc_detailsView">
        <div className="adc_separatorStyle"></div>
        <div className="adc_extra_info">
          {this.renderLabelNValueView(
            t("ter"),
            cardDetails.ter
          )}
          {this.renderLabelNValueView(t("category"), cardDetails.category)}
          {this.renderLabelNValueView(
            t("sharpeRatio"),
            cardDetails.sharpeRatio
          )}
        </div>
        <div className="adc_extra_info">
          {this.renderLabelNValueView(
            t("oneYrReturn"),
            cardDetails.oneYrReturn
          )}
          {this.renderLabelNValueView(
            t("threeYrReturn"),
            cardDetails.threeYrReturn
          )}
          {this.renderLabelNValueView(
            t("fiveYrReturn"),
            cardDetails.fiveYrReturn
          )}
        </div>
      </div>
    )
  }

  render() {
    const isExpanded = this.state.isExpanded;
    const cardDetails = this.props.cardDetail;
    return (
      <div className="adc_container">
        {this.renderContentHeaderView(cardDetails)}
        {this.renderDetailsView(cardDetails)}
        {isExpanded && this.renderExtraInfoView(cardDetails)}
      </div>
    );
  }
}

export default AssetDetailsCard;
