import BackendApi from "../backend/index";

class Goals {
  constructor(props) {
    this.userid = props.userid;
    this.goalcategory = props.goalcategory;
    this.retirewanttype = props.retirewanttype;
    this.monthlyexpenselist = props.monthlyexpenselist;
    this.goaldescription = props.goaldescription;
    this.goalstartyear = props.goalstartyear;
    this.goalendyear = props.goalendyear;
    this.goalinflationrate = props.goalinflationrate;
    this.goalamountpv = props.goalamountpv;
    this.goalamountfv = props.goalamountfv;
    this.goalfrequency = props.goalfrequency;
    this.homeloanstatus = props.homeloanstatus;
    this.downpayment = props.downpayment;
    this.advisorid = props.advisorid;
    this.goalfrequencytype = props.goalfrequencytype;
    this.goalbucketcode = props.goalbucketcode;
    this.goaldetails = props.goaldetails;
    this.category_status = props.category_status;
    this.flowamount = props.flowamount;
    this.goal_priority = props.goal_priority;
    this.goalpriority = props.goalpriority;
    this.goaltype = props.goaltype;
    this.goalendage = props.goalendage;
    this.userage = props.userage;
    this.goalstartage = props.goalstartage;
    this.expensetype = props.expensetype;

    this.dbValue = {
      userid: props.userid,
      goalcategory: props.goalcategory,
      retirewanttype: props.retirewanttype,
      monthlyexpenselist: props.monthlyexpenselist,
      goaldescription: props.goaldescription,
      goalstartyear: props.goalstartyear,
      goalendyear: props.goalendyear,
      goalinflationrate: props.goalinflationrate,
      goalamountpv: props.goalamountpv,
      goalamountfv: props.goalamountfv,
      goalfrequency: props.goalfrequency,
      homeloanstatus: props.homeloanstatus,
      downpayment: props.downpayment,
      advisorid: props.advisorid,
      goalfrequencytype: props.goalfrequencytype,
      goalbucketcode: props.goalbucketcode,
      goaldetails: props.goaldetails,
      category_status: props.category_status,
      flowamount: props.flowamount,
      goal_priority: props.goal_priority,
      goalpriority: props.goalpriority,
      goaltype: props.goaltype,
      goalendage: props.goalendage,
      userage: props.userage,
      goalstartage: props.goalstartage,
      expensetype: props.expensetype
    };

    if (props.id) {
      this.id = props.id;
      this.dbValue.id = props.id;
    }
  }

  setGoalId(id) {
    this.id = id;
    this.dbValue.id = id;
  }

  setUserId(userid) {
    this.userid = userid;
  }

  setRetireWantType(retirewanttype) {
    this.retirewanttype = retirewanttype;
  }

  setGoalCategory(goalcategory) {
    this.goalcategory = goalcategory;
  }

  setDescription(goaldescription) {
    this.goaldescription = goaldescription;
  }

  setGoalFrequencyType(goalfrequencytype) {
    this.goalfrequencytype = goalfrequencytype;
  }

  setStartYear(goalstartyear) {
    this.goalstartyear = goalstartyear;
  }

  setEndYear(goalendyear) {
    this.goalendyear = goalendyear;
  }

  setGoalAmountPv(goalamountpv) {
    this.goalamountpv = goalamountpv;
  }

  setGoalAmountFv(goalamountfv) {
    this.goalamountfv = goalamountfv;
  }

  setGoalFrequency(goalfrequency) {
    this.goalfrequency = goalfrequency;
  }

  setGoalInflationRate(goalinflationrate) {
    this.goalinflationrate = goalinflationrate;
  }

  setGoalBucketCode(goalbucketcode) {
    this.goalbucketcode = goalbucketcode;
  }

  setGoalDetails(goaldetails) {
    this.goaldetails = goaldetails;
  }

  setCategoryStatus(category_status) {
    this.category_status = category_status;
  }

  setFlowAmount(flowamount) {
    this.flowamount = flowamount;
  }

  setGoal_Priority(goal_priority) {
    this.goal_priority = goal_priority;
  }

  setGoalPriority(goalpriority) {
    this.goalpriority = goalpriority;
  }

  setGoalType(goaltype) {
    this.goaltype = goaltype;
  }

  setInsertBy(insertby) {
    this.insertby = insertby;
  }

  setInsertTime(inserttime) {
    this.inserttime = inserttime;
  }

  setUpdateBy(updateby) {
    this.updateby = updateby;
  }

  setUpdateTime(updatetime) {
    this.updatetime = updatetime;
  }

  setOperation(operation) {
    this.operation = operation;
  }

  setMonthlyExpenseList(monthlyexpenselist) {
    this.monthlyexpenselist = monthlyexpenselist;
  }

  setDownPayment(downpayment) {
    this.downpayment = downpayment;
  }

  setHomeLoanStatus(homeloanstatus) {
    this.homeloanstatus = homeloanstatus;
  }

  setGoalEndAge(goalendage) {
    this.goalendage = goalendage;
  }

  setUserAge(userage) {
    this.userage = userage;
  }

  setGoalStartAge(goalstartage) {
    this.goalstartage = goalstartage;
  }

  setExpenseType(expensetype) {
    this.expensetype = expensetype
  }

  revertUserId() {
    this.userid = this.dbValue.userid;
  }

  revertGoalCategory() {
    this.goalcategory = this.dbValue.goalcategory;
  }

  revertDescription() {
    this.goaldescription = this.dbValue.goaldescription;
  }

  revertGoalFrequencyType() {
    this.goalfrequencytype = this.dbValue.goalfrequencytype;
  }

  revertStartYear() {
    this.goalstartyear = this.dbValue.goalstartyear;
  }

  revertEndYear() {
    this.goalendyear = this.dbValue.goalendyear;
  }

  revertGoalAmountPv() {
    this.goalamountpv = this.dbValue.goalamountpv;
  }

  revertGoalInflationRate() {
    this.goalinflationrate = this.dbValue.goalinflationrate;
  }

  revertGoalFrequency() {
    this.goalfrequency = this.dbValue.goalfrequency;
  }

  revertGoalBucketCode() {
    this.goalbucketcode = this.dbValue.goalbucketcode;
  }

  revertMonthlyExpenseList() {
    this.monthlyexpenselist = this.dbValue.monthlyexpenselist;
  }

  revertDownPayment() {
    this.downpayment = this.dbValue.downpayment;
  }

  revertHomeLoanStatus() {
    this.homeloanstatus = this.dbValue.homeloanstatus;
  }

  revertGoalEndAge() {
    this.goalendage = this.dbValue.goalendage;
  }

  revertUserAge() {
    this.userage = this.dbValue.userage;
  }

  revertGoalStartAge() {
    this.goalstartage = this.dbValue.goalstartage;
  }

  revertExpenseType(){
    this.expensetype=this.dbValue.expensetype
  }

  revertAllChanges() {
    this.revertUserId();
    this.revertGoalCategory();
    this.revertDescription();
    this.revertGoalFrequencyType();
    this.revertStartYear();
    this.revertEndYear();
    this.revertGoalAmountPv();
    this.revertGoalBucketCode();
    this.revertMonthlyExpenseList();
    this.revertGoalInflationRate();
    this.revertDownPayment();
    this.revertHomeLoanStatus();
    this.revertGoalEndAge();
    this.revertUserAge();
    this.revertGoalStartAge();
    this.revertExpenseType();
  }
  

  static getGoalsByUser(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.GoalsApi.getGoalsByUser(data)
        .then((goalsList) => {
          const goals = [];
          if (goalsList && goalsList.length > 0) {
            goalsList.forEach((item) => {
              const goalObj = new Goals(item);
              goals.push(goalObj);
            });
          }
          resolve(goals);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

 

  static getGoalsByCategory(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.GoalsApi.getGoalsByCategory(data)
        .then((goalsList) => {
          const goals = [];
          if (goalsList && goalsList.length > 0) {
            goalsList.forEach((item) => {
              const goalObj = new Goals(item);
              goals.push(goalObj);
            });
          }
          resolve(goals);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getGoalsConfigInfo() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.GoalsApi.getGoalsConfigInfo()
        .then((configList) => {
          resolve(configList);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateCategoryStatus(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.GoalsApi.updateCategoryStatus(data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createGoal() {
    this.setOperation("STORE");
    this.setInsertBy("Fliber");
    this.setInsertTime(new Date());
    this.setUpdateBy("");

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.GoalsApi.createGoal(this)
        .then((response) => {
          const goalId = response && response.id ? response.id : "";
          this.setGoalId(goalId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateGoal() {
    this.setOperation("STORE");
    this.setInsertBy("Fliber");
    this.setUpdateBy("Fliber");
    this.setUpdateTime(new Date());

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.GoalsApi.updateGoal(this)
        .then((response) => {
          const goalId = response && response.id ? response.id : "";
          this.setGoalId(goalId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteGoal() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.GoalsApi.deleteGoal(this)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  viewExpectedAmt() {
    this.setOperation("VIEW");
    this.setInsertBy("Fliber");
    this.setUpdateBy("Fliber");

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.GoalsApi.viewExpectedAmt(this)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default Goals;
