import React, { Component } from "react";
import "./npshome.css";
import { t } from "i18next";
import NPSProfile from "../../../assets/svgs/nps_home.svg";
import {
  BreadCrumb,
  Button,
  TitleView,
  SideModel,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { INVESTMENT_CATEGORY } from "../../constants";
import { calculate_year, getUserId } from "../../common";
import { getFinancialMarketPath } from "../../common";

const newInvestment = {
  investmentcategory: INVESTMENT_CATEGORY.NPS,
  currentvalue: 0,
  annualcontribution: 0,
  maturityyear: calculate_year(60),
  maturityvalue: 0,
  expectedror: 0,
  userid: '',
  contributiongrowthrate: "",
  investementtype: "YEAR",
  investmentdetails: [],
  investmentdescription: "",
  fvatretirement: 0,
  maturityage: 0,
  userage: 0,
  isincludefinancialplan: false
};

class NPSHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInvestmentData: new Bl.Investment({ ...newInvestment, userid: getUserId() }),
      investmentData: [],
    };
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressSaveButton() {
    this.props.navigate("/npsdetailpage", {
      state: {
        investmentData: this.state.newInvestmentData,
        isEditView: false,
      },
    });
  }

  renderNavView() {
    const items = getFinancialMarketPath("NPS");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsFM2");
    return <TitleView title={title} />;
  }

  renderProfileView() {
    return (
      <div className="nps_profile_view">
        <img
          src={NPSProfile}
          alt={t("networthAssetsFM2")}
          className="nps_icon"
        />
        <div className="nps_save">
          <Button
            buttonName={t("npsButton")}
            onPressButton={this.onPressSaveButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="nps_content_view">
        {this.renderTitleView()}
        {this.renderProfileView()}
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  render() {
    return (
      <div className="nps_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default NPSHome;
