import BackendApi from "../backend/index";

class User {
  constructor(props) {
    this.userid = props.userid;
    this.firstname = props.firstname;
    this.lastname = props.lastname;
    this.middlename = props.middlename;
    this.mobilenumber = props.mobilenumber;
    this.emailid = props.emailid;
    this.ismobileverified = props.ismobileverified;
    this.isemailverified = props.isemailverified;
    this.addressline1 = props.addressline1;
    this.addressline2 = props.addressline2;
    this.city = props.city;
    this.country = props.country;
    this.pincode = props.pincode;
    this.dateofbirth = props.dateofbirth;
    this.gender = props.gender;
    this.retirementage = props.retirementage;
    this.retirementyear = props.retirementyear;
    this.residentstatus = props.residentstatus;
    this.active = props.active;
    this.deviceregistrationid = props.deviceregistrationid;
    this.state = props.state;
    this.maritalstatuscode = props.maritalstatuscode;
    this.uanno = props.uanno;
    this.uanpassword = props.uanpassword;
    this.pannumber = props.pannumber;
    this.lifeexpectancy = props.lifeexpectancy;
    this.spousefirstname = props.spousefirstname;
    this.spouselastname = props.spouselastname;
    this.spousepannumber = props.spousepannumber;
    this.spousedateofbirth = props.spousedateofbirth;
    this.customer_type = props.customer_type;
    this.status = props.status;
    this.password = props.password;
    this.risk_profile = props.risk_profile;
    this.insertby = props.insertby;
    this.inserttime = props.inserttime;
    this.updatetime = props.updatetime;
    this.updateby = props.updateby;
    this.nrd = props.nrd;
    this.role = props.role;
    this.kycstatus = props.kycstatus;
    this.offset = props.offset;
    this.is_risk_profile_completed = props.is_risk_profile_completed;
    this.risk_profile_updated_date = props.risk_profile_updated_date;
    this.lastreviewdate = props.lastreviewdate;
    this.nextriviewdate = props.nextriviewdate;
    this.securitylevel = props.securitylevel;
    this.advisorname = props.advisorname;
    this.advisorid = props.advisorid;
    this.iscashladderupdated = props.iscashladderupdated;
    this.isportfolioalloated = props.isportfolioalloated;
    this.planportfolioratesb = props.planportfolioratesb;
    this.isassetallocated = props.isassetallocated;
    this.planportfoliorategb = props.planportfoliorategb;
    this.intiateportfolioallocation = props.intiateportfolioallocation;
    this.portfolioallocationlock = props.portfolioallocationlock;
    this.lastportfolioallocateddate = props.lastportfolioallocateddate;

    this.dbValue = {
      userid: props.userid,
      firstname: props.firstname,
      lastname: props.lastname,
      middlename: props.middlename,
      mobilenumber: props.mobilenumber,
      emailid: props.emailid,
      insertby: props.insertby,
      inserttime: props.inserttime,
      updatetime: props.updatetime,
      updateby: props.updateby,
      ismobileverified: props.ismobileverified,
      isemailverified: props.isemailverified,
      addressline1: props.addressline1,
      addressline2: props.addressline2,
      city: props.city,
      country: props.country,
      pincode: props.pincode,
      dateofbirth: props.dateofbirth,
      gender: props.gender,
      retirementage: props.retirementage,
      retirementyear: props.retirementyear,
      residentstatus: props.residentstatus,
      active: props.active,
      deviceregistrationid: props.deviceregistrationid,
      state: props.state,
      maritalstatuscode: props.maritalstatuscode,
      uanno: props.uanno,
      uanpassword: props.uanpassword,
      pannumber: props.pannumber,
      lifeexpectancy: props.lifeexpectancy,
      spousefirstname: props.spousefirstname,
      spouselastname: props.spouselastname,
      spousepannumber: props.spousepannumber,
      spousedateofbirth: props.spousedateofbirth,
      customer_type: props.customer_type,
      status: props.status,
      password: props.password,
      risk_profile: props.risk_profile,
      nrd: props.nrd,
      role: props.role,
      kycstatus: props.kycstatus,
      offset: props.offset,
      is_risk_profile_completed: props.is_risk_profile_completed,
      risk_profile_updated_date: props.risk_profile_updated_date,
      lastreviewdate: props.lastreviewdate,
      nextriviewdate: props.nextriviewdate,
      securitylevel: props.securitylevel,
      advisorname: props.advisorname,
      advisorid: props.advisorid,
      iscashladderupdated: props.iscashladderupdated,
      isportfolioalloated: props.isportfolioalloated,
      planportfolioratesb: props.planportfolioratesb,
      isassetallocated: props.isassetallocated,
      planportfoliorategb: props.planportfoliorategb,
      intiateportfolioallocation: props.intiateportfolioallocation,
      portfolioallocationlock: props.portfolioallocationlock,
      lastportfolioallocateddate: props.lastportfolioallocateddate
    };

    if (props.id) {
      this.id = props.id;
      this.dbValue.id = props.id;
    }
  }

  setUserId(id) {
    this.id = id;
    this.dbValue.id = id;
  }

  setFirstName(firstname) {
    this.firstname = firstname;
  }

  setLastName(lastname) {
    this.lastname = lastname;
  }

  setMiddleName(middlename) {
    this.middlename = middlename;
  }

  setEmailId(emailid) {
    this.emailid = emailid;
  }

  setIsEmailVerified(isemailverified) {
    this.isemailverified = isemailverified;
  }

  setIsMobileVerified(ismobileverified) {
    this.ismobileverified = ismobileverified;
  }

  setAddressLine1(addressline1) {
    this.addressline1 = addressline1;
  }

  setAddressLine2(addressline2) {
    this.addressline2 = addressline2;
  }

  setCity(city) {
    this.city = city;
  }

  setCountry(country) {
    this.country = country;
  }

  setMobileNumber(mobilenumber) {
    this.mobilenumber = mobilenumber;
  }

  setPinCode(pincode) {
    this.pincode = pincode;
  }

  setDOB(dateofbirth) {
    this.dateofbirth = dateofbirth;
  }

  setGender(gender) {
    this.gender = gender;
  }

  setRetirementAge(retirementage) {
    this.retirementage = retirementage;
  }

  setRetirementYear(retirementyear) {
    this.retirementyear = retirementyear;
  }

  setResidentStatus(residentstatus) {
    this.residentstatus = residentstatus;
  }

  setActive(active) {
    this.active = active;
  }

  setDeviceRegistrationId(deviceregistrationid) {
    this.deviceregistrationid = deviceregistrationid;
  }

  setState(state) {
    this.state = state;
  }

  setMaritalStatusCode(maritalstatuscode) {
    this.maritalstatuscode = maritalstatuscode;
  }

  setUanno(uanno) {
    this.uanno = uanno;
  }

  setUanPassword(uanpassword) {
    this.uanpassword = uanpassword;
  }

  setPanNumber(pannumber) {
    this.pannumber = pannumber;
  }

  setLifeExpectancy(lifeexpectancy) {
    this.lifeexpectancy = lifeexpectancy;
  }

  setSpouseFirstName(spousefirstname) {
    this.spousefirstname = spousefirstname;
  }

  setSpouseLastName(spouselastname) {
    this.spouselastname = spouselastname;
  }

  setSpousePanNumber(spousepannumber) {
    this.spousepannumber = spousepannumber;
  }

  setSpouseDateOfBirth(spousedateofbirth) {
    this.spousedateofbirth = spousedateofbirth;
  }

  setCustomerType(customer_type) {
    this.customer_type = customer_type;
  }

  setStatus(status) {
    this.status = status;
  }

  setPassword(password) {
    this.password = password;
  }

  setRiskProfile(risk_profile) {
    this.risk_profile = risk_profile;
  }

  setInsertBy(insertby) {
    this.insertby = insertby;
  }

  setInsertTime(inserttime) {
    this.inserttime = inserttime;
  }

  setUpdateBy(updateby) {
    this.updateby = updateby;
  }

  setUpdateTime(updatetime) {
    this.updatetime = updatetime;
  }

  setKycStatus(kycstatus) {
    this.kycstatus = kycstatus;
  }

  setRole(role) {
    this.role = role;
  }

  setNrd(nrd) {
    this.nrd = nrd;
  }

  setOffset(offset) {
    this.offset = offset;
  }

  setIs_risk_profile_completed(is_risk_profile_completed) {
    this.is_risk_profile_completed = is_risk_profile_completed;
  }

  setRisk_profile_updated_date(risk_profile_updated_date) {
    this.risk_profile_updated_date = risk_profile_updated_date;
  }

  setLastReviewDate(lastreviewdate) {
    this.lastreviewdate = lastreviewdate
  }

  setNextReviewDate(nextriviewdate) {
    this.nextriviewdate = nextriviewdate
  }

  setSecurityLevel(securitylevel) {
    this.securitylevel = securitylevel
  }

  setAdvisorName(advisorname) {
    this.advisorname = advisorname
  }

  setAdvisorId(advisorid) {
    this.advisorid = advisorid
  }

  setIsCashladderUpdated(iscashladderupdated) {
    this.iscashladderupdated = iscashladderupdated
  }

  revertUserId() {
    this.userid = this.dbValue.userid;
  }

  revertFirstName() {
    this.firstname = this.dbValue.firstname;
  }

  revertLastName() {
    this.lastname = this.dbValue.lastname;
  }

  revertMiddleName() {
    this.middlename = this.dbValue.middlename;
  }

  revertEmailId() {
    this.emailid = this.dbValue.emailid;
  }

  revertMobileNumber() {
    this.mobilenumber = this.dbValue.mobilenumber;
  }

  revertIsEmailVerified() {
    this.isemailverified = this.dbValue.isemailverified;
  }

  revertIsMobileVerified() {
    this.ismobileverified = this.dbValue.ismobileverified;
  }

  revertAddressLine1() {
    this.addressline1 = this.dbValue.addressline1;
  }

  revertAddressLine2() {
    this.addressline2 = this.dbValue.addressline2;
  }

  revertCity() {
    this.city = this.dbValue.city;
  }

  revertCountry() {
    this.country = this.dbValue.country;
  }

  revertPinCode() {
    this.pincode = this.dbValue.pincode;
  }

  revertDOB() {
    this.dateofbirth = this.dbValue.dateofbirth;
  }

  revertGender() {
    this.gender = this.dbValue.gender;
  }

  revertRetirementAge() {
    this.retirementage = this.dbValue.retirementage;
  }

  revertRetirementYear() {
    this.retirementyear = this.dbValue.retirementyear;
  }

  revertResidentStatus() {
    this.residentstatus = this.dbValue.residentstatus;
  }

  revertActive() {
    this.active = this.dbValue.active;
  }

  revertDeviceRegistrationId() {
    this.deviceregistrationid = this.dbValue.deviceregistrationid;
  }

  revertState() {
    this.state = this.dbValue.state;
  }

  revertMaritalStatusCode() {
    this.maritalstatuscode = this.dbValue.maritalstatuscode;
  }

  revertUanno() {
    this.uanno = this.dbValue.uanno;
  }

  revertUanPassword() {
    this.uanpassword = this.dbValue.uanpassword;
  }

  revertPanNumber() {
    this.pannumber = this.dbValue.pannumber;
  }

  revertLifeExpectancy() {
    this.lifeexpectancy = this.dbValue.lifeexpectancy;
  }

  revertSpouseFirstName() {
    this.spousefirstname = this.dbValue.spousefirstname;
  }

  revertSpouseLastName() {
    this.spouselastname = this.dbValue.spouselastname;
  }

  revertSpousePanNumber() {
    this.spousepannumber = this.dbValue.spousepannumber;
  }

  revertSpouseDateOfBirth() {
    this.spousedateofbirth = this.dbValue.spousedateofbirth;
  }

  revertCustomerType() {
    this.customer_type = this.dbValue.customer_type;
  }

  revertStatus() {
    this.status = this.dbValue.status;
  }

  revertPassword() {
    this.password = this.dbValue.password;
  }

  revertRiskProfile() {
    this.risk_profile = this.dbValue.risk_profile;
  }

  revertKycStatus() {
    this.kycstatus = this.dbValue.kycstatus;
  }

  revertRole() {
    this.role = this.dbValue.role;
  }

  revertNrd() {
    this.nrd = this.dbValue.nrd;
  }

  revertOffset() {
    this.offset = this.dbValue.offset;
  }

  revertIs_risk_profile_completed() {
    this.is_risk_profile_completed = this.dbValue.is_risk_profile_completed;
  }

  revertRisk_profile_updated_date() {
    this.risk_profile_updated_date = this.dbValue.risk_profile_updated_date;
  }

  revertLastReviewDate() {
    this.lastreviewdate = this.dbValue.lastreviewdate
  }

  revertNextReviewDate() {
    this.nextriviewdate = this.dbValue.nextriviewdate
  }

  revertSecurityLevel() {
    this.securitylevel = this.dbValue.securitylevel
  }

  revertAdvisorName() {
    this.advisorname = this.dbValue.advisorname
  }

  revertAdvisorId() {
    this.advisorid = this.dbValue.advisorid
  }

  revertIsCashladderUpdated() {
    this.iscashladderupdated = this.dbValue.iscashladderupdated
  }


  revertAllChanges() {
    this.revertUserId();
    this.revertFirstName();
    this.revertLastName();
    this.revertMiddleName();
    this.revertEmailId();
    this.revertMobileNumber();
    this.revertAddressLine1();
    this.revertAddressLine2();
    this.revertCity();
    this.revertCountry();
    this.revertPinCode();
    this.revertDOB();
    this.revertGender();
    this.revertRetirementAge();
    this.revertRetirementYear();
    this.revertResidentStatus();
    this.revertIsEmailVerified();
    this.revertIsMobileVerified();
    this.revertActive();
    this.revertDeviceRegistrationId();
    this.revertState();
    this.revertMaritalStatusCode();
    this.revertUanno();
    this.revertUanPassword();
    this.revertPanNumber();
    this.revertLifeExpectancy();
    this.revertSpouseFirstName();
    this.revertSpouseLastName();
    this.revertSpousePanNumber();
    this.revertSpouseDateOfBirth();
    this.revertCustomerType();
    this.revertStatus();
    this.revertPassword();
    this.revertRiskProfile();
    this.revertKycStatus();
    this.revertRole();
    this.revertNrd();
    this.revertOffset();
    this.revertIs_risk_profile_completed();
    this.revertRisk_profile_updated_date();
    this.revertLastReviewDate();
    this.revertNextReviewDate();
    this.revertSecurityLevel();
    this.revertAdvisorName();
    this.revertAdvisorId();
    this.revertIsCashladderUpdated();
  }

  createUser() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.createUser(this)
        .then((response) => {
          const Id = response && response.id ? response.id : "";
          this.setUserId(Id)
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  UpdateUser() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.updateUser(this)
        .then((response) => {
          const Id = response && response.id ? response.id : "";
          this.setUserId(Id)
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteUser() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.deleteUser(this)
        .then((response) => {
          resolve();
        }).catch((error) => {
          reject(error);
        })
    })
  }

  static validateVerifyPAN(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.validateVerifyPAN(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  static updateUserStatus(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.updateUserStatus(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserDetails() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.getUserDetails()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserDetailsById(id) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.getUserDetailsById(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static userLogin(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.userLogin(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static userForgetPin(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.userForgetPin(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static verifyEmail(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.verifyEmail(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static changePassword(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.UserApi.changePassword(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}

export default User;
