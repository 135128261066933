import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Bl from "../../businesslogic";
import "./adminriskprofile.css";
import PolicyPicture from "../../assets/svgs/policypicture.svg";
import RiskProfileSlider from "../components/riskprofileslider";
import { BreadCrumb, Button, TitleView } from "../components";
import {
  getRiskProfileDescription,
  getRiskProfilePathName,
  getUserId,
} from "../common";
import RiskProfileEnds from "../../assets/svgs/riskprofile_end.svg";
import * as logger from "../utils/logger/index";

const AdminRiskProfile = (props) => {
  const [state, setState] = useState({
    status: "",
    showToast: false,
    toastMessage: "",
    showProgress: false,
  });
  const userId = getUserId();

  const isRenderedAlready = useRef(false);

  useEffect(() => {
    if (!isRenderedAlready.current) {
      isRenderedAlready.current = true;
      getUserRiskProfile();
    }
  }, []);

  const showToast = (toastMessage) => {
    setState((prevState) => ({
      ...prevState,
      toastMessage,
      showToast: true,
      showProgress: false,
    }));
    setTimeout(() => {
      hideToast();
    }, 3000);
  };

  const hideToast = () => {
    setState((prevState) => ({
      ...prevState,
      toastMessage: "",
      showToast: false,
      abilityResult: 0,
      willingnessResult: 0,
      status: ''
    }));
  };

  const getUserRiskProfile = () => {
    const data = {
      uuid: userId,
    };
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }));
    Bl.RiskProfile.getUserRiskProfile(data)
      .then((response) => {
        const categoryType = response?.finalriskcategory;
        const status = getRiskProfileDescription(categoryType);
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
          status: status,
          abilityResult: response?.ability_score,
          willingnessResult: response?.willingness_score
        }));
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("User did not perform the riskprofile"));
      });
  };

  const onSelectItem = (path) => {
    props.navigate(path);
  };

  const onPressOkayButton = () => {
    props.navigate("/financial")
  }

  const renderNavView = () => {
    const items = getRiskProfilePathName();
    const location = props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={onSelectItem}
        onClickItem={onSelectItem}
      />
    );
  };
  const renderTitleView = () => {
    const title = t("rphTitle");
    return <TitleView title={title} />;
  };
  const renderRiskProfileBalance = () => {
    const status = state.status;
    return (
      <div className="arp_risk_profile">
        <label className="arp_risk_label">{status}</label>
        <img src={RiskProfileEnds} alt={"end"} className="arp_end_icon" />
      </div>
    );
  };

  const renderPolicyPicture = () => {
    return (
      <div className="arp_picture_riskprofile">
        <div className="arp_riskprofile_content">
          {renderRiskProfileBalance()}
        </div>
        <div className="arp_policy_picture">
          <img
            src={PolicyPicture}
            alt="PolicyPicture"
            className="arp_pictureStyle"
          />
        </div>
      </div>
    );
  };

  const renderSliderView = () => {
    return (
      <div className="arp_slider">
        <div className={"arp_slider_ability"}>
          <label className="arp_abilityTitleStyle">{t("Ability")}</label>
          <div className="arp_sliderView">
            <RiskProfileSlider
              value={state?.abilityResult}
              min={6}
              max={21}
              markStep={3}
              handleChange={() => { }}
              showAge={false}
              showPercentage={false}
            />
          </div>
        </div>
        <div className={"arp_slider_willing"}>
          <label className="arp_willingTitleStyle">{t("Willingness")}</label>
          <div className="arp_willingSliderView">
            <RiskProfileSlider
              value={state?.willingnessResult}
              min={7}
              max={27}
              markStep={4}
              handleChange={() => { }}
              showAge={false}
              showPercentage={false}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderButtonView = () => {
    return (
      <div className="arp_bottomView">
        <div className="arp_buttonView">
          <Button buttonName={t("Okay")} onPressButton={onPressOkayButton} />
        </div>
      </div>
    );
  };

  return (
    <div className="arp_container">
      {renderNavView()}
      <div className="arp_content_view">
        {renderTitleView()}
        {renderPolicyPicture()}
        {renderSliderView()}
        {renderButtonView()}
      </div>
    </div>
  );
};

export default AdminRiskProfile;
