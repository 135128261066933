import React, { Component } from "react";
import "./gsshome.css";
import { t } from "i18next";
import GSSProfile from "../../../assets/svgs/fasset/gsshome.svg";
import {
  BreadCrumb,
  Button,
  TitleView,
  SideModel,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { INVESTMENT_CATEGORY } from "../../constants";
import { getFinancialNonMarketPath, getUserId } from "../../common";

const newInvestment = {
  investmentcategory: INVESTMENT_CATEGORY.GSS,
  currentvalue: 0,
  annualcontribution: 0,
  maturityyear: 0,
  maturityvalue: 0,
  expectedror: 0,
  userid: "",
  contributiongrowthrate: 0,
  investementtype: "YEAR",
  investmentdetails: [
    {
      isfliberadvise: false
    },
  ],
  investmentdescription: "",
  fvatretirement: 0,
  maturityage: 0,
  userage: 0,
  isincludefinancialplan: false
};
class GSSHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInvestmentData: new Bl.Investment({...newInvestment, userid: getUserId()}),
      investmentData: [],
    };
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressSaveButton() {
    this.props.navigate("/gssdetailpage", {
      state: {
        investmentData: this.state.newInvestmentData,
        isEditView: false,
      },
    });
  }



  renderNavView() {
    const items = getFinancialNonMarketPath("GSS");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsFNM3");
    return <TitleView title={title} />;
  }

  renderProfileView() {
    return (
      <div className="gss_profile_view">
        <img
          src={GSSProfile}
          alt={t("networthAssetsFNM3")}
          className="gss_icon"
        />
        <div className="gss_save">
          <Button
            buttonName={t("gssButton")}
            onPressButton={this.onPressSaveButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="gss_content_view">
        {this.renderTitleView()}
        {this.renderProfileView()}
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  render() {
    return (
      <div className="gss_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default GSSHome;
