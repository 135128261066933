import BackendApi from "../backend/index";

class CashLadder {
  constructor(props) {
    this.userid = props.userid;
    this.ruleno = props.ruleno;
    this.riskcategory = props.riskcategory;
    this.assetclass = props.assetclass;
    this.allocationpercentage = props.allocationpercentage;
    this.insertby = props.insertby;
    this.inserttime = props.inserttime;
    this.updatetime = props.updatetime;
    this.updateby = props.updateby;

    this.dbValue = {
      userid: props.userid,
      ruleno: props.ruleno,
      riskcategory: props.riskcategory,
      assetclass: props.assetclass,
      allocationpercentage: props.allocationpercentage,
      insertby: props.insertby,
      inserttime: props.inserttime,
      updatetime: props.updatetime,
      updateby: props.updateby,
    };

    if (props.id) {
      this.id = props.id;
      this.dbValue.id = props.id;
    }
  }

  setUserId(userid) {
    this.userid = userid;
  }

  setRuleNo(ruleno) {
    this.ruleno = ruleno;
  }

  setRiskCategoery(riskcategory) {
    this.riskcategory = riskcategory;
  }

  setAssetClass(assetclass) {
    this.assetclass = assetclass;
  }

  setAllocationPercentage(allocationpercentage) {
    this.allocationpercentage = allocationpercentage;
  }

  setInsertBy(insertby) {
    this.insertby = insertby;
  }

  setInsertTime(inserttime) {
    this.inserttime = inserttime;
  }

  setUpdateBy(updateby) {
    this.updateby = updateby;
  }

  setUpdateTime(updatetime) {
    this.updatetime = updatetime;
  }

  setAnswerId(ansId) {
    this.id = ansId;
    this.dbValue.id = ansId;
  }

  revertUserId() {
    this.userid = this.dbValue.userid;
  }

  revertRuleNo() {
    this.ruleno = this.dbValue.ruleno;
  }

  revertRiskCategory() {
    this.riskcategory = this.dbValue.riskcategory;
  }

  revertAssetClass() {
    this.assetclass = this.dbValue.assetclass;
  }

  revertAllocationPercentage() {
    this.allocationpercentage = this.dbValue.allocationpercentage;
  }

  revertAllChanges() {
    this.revertUserId();
    this.revertRuleNo();
    this.revertRiskCategory();
    this.revertAssetClass();
    this.revertAllocationPercentage();
  }

  static createAssetAllocation(userId, data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.createAssetAllocation(userId, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static createCalculateEffectiveRate(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.createCalculateEffectiveRate(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  static createCashLadder(userId, data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.createCashLadder(userId, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  static getCashLadder(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.getCashLadder(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCashLadderWithLumsum(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.getCashLadderWithLumsum(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCashLadderSipOptimization(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.getCashLadderSipOptimization(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCashLadderGoalAllocation(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.getCashLadderGoalAllocation(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCashLadderIncomeAllocation(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.getCashLadderIncomeAllocation(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  static getCashLadderGoalOptimization(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.getCashLadderGoalOptimization(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  static getAssetAllocationDetails(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.getAssetAllocationDetails(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static generateIpsReport(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.generateIpsReport(data)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error?.config?.url);
        })
    })
  }

  static generateAdvisorReport(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.generateAdvisorReport(data)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error?.config?.url);
        })
    })
  }

  static initiateReview(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.initiateReview(data)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error?.config?.url)
        })
    })
  }

  static unlockCashladder(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CashLadderApi.unlockCashladder(data)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error?.config?.url)
        })
    })
  }
}

export default CashLadder;
