import React, { useEffect, useState } from "react";
import { t } from "i18next";
import "./ruleallocationview.css";
import DeleteIcon from "../../assets/svgs/ic_delete.svg";
import { RecordModal } from "../components";

const RuleAllocationView = (props) => {
  const [state, setState] = useState({
    showAddModal: false,
    selectedCategory: props.sebiCategories?.[0]?.mstarcategory,
    selectedCategoryObj: props.sebiCategories?.[0],
    policyAlloc: '',
    selectedItem: [],
    showDeleteModal: false,
    title: "",
    equityData: props?.data[0]?.equity,
    debtData: props?.data[1]?.debt,
    othersData: props?.data[2]?.others,
  })

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      equityData: props?.data[0]?.equity,
      debtData: props?.data[1]?.debt,
      othersData: props?.data[2]?.others,
    }));
  }, [props?.data[0]?.equity, props?.data[1]?.debt, props?.data[2]?.others]);

  const onChangeEquityRevised = (e, index, item, data, percentage) => {
    const value = Number(e.target.value);
    item.revisedAllocation = value;
    item.netAllocation = Number(value * ((percentage / 100).toFixed(2))).toFixed(2);

    data[index] = item;

    setState((prevState) => ({
      ...prevState,
      equityData: data
    }))

    if (props.onChangeInput) {
      props.onChangeInput()
    }
  }

  const onChangeDebtRevised = (e, index, item, data, percentage) => {
    const value = Number(e.target.value);
    item.revisedAllocation = value;
    item.netAllocation = Number(value * ((percentage / 100).toFixed(2))).toFixed(2);

    data[index] = item;

    setState((prevState) => ({
      ...prevState,
      debtData: data
    }))

    if (props.onChangeInput) {
      props.onChangeInput()
    }
  }


  const onChangeOthersRevised = (e, index, item, data, percentage) => {
    const value = Number(e.target.value);
    item.revisedAllocation = value;
    item.netAllocation = Number(value * ((percentage / 100).toFixed(2))).toFixed(2);

    data[index] = item;

    setState((prevState) => ({
      ...prevState,
      othersData: data
    }))

    if (props.onChangeInput) {
      props.onChangeInput()
    }
  }

  const onClickAddCategory = (data, title, percentage) => {
    data.percentage = percentage;
    setState((prevState) => ({
      ...prevState,
      showAddModal: true,
      selectedItem: data,
      selectedCategory: '',
      selectedCategoryObj: {},
      policyAlloc: '',
      title: title
    }))
  }

  const onClickDelete = (item, index) => {
    setState((prevState) => ({
      ...prevState,
      selectedItem: item,
      selectedIndex: index,
      showDeleteModal: true
    }))
  }

  const onPressNo = () => {
    onHideDeleteModal();

  };

  const onPressYes = () => {
    onHideDeleteModal();
    if (props.onClickDelete) {
      props.onClickDelete(state.selectedItem)
    }
  };

  const onSelectCategory = (e) => {
    const value = e.target.value;
    const selectedCategoryObj = props?.sebiCategories?.find(category => category.sebicategorycode === value);
    setState((prevState) => ({
      ...prevState,
      selectedCategory: value,
      selectedCategoryObj
    }))
  }

  const onHideModal = () => {
    setState((prevState) => ({
      ...prevState,
      showAddModal: false,
    }))
  }

  const onHideDeleteModal = () => {
    setState((prevState) => ({
      ...prevState,
      showDeleteModal: false,
    }))
  }
  const onPressOk = () => {
    onHideModal();

    const data = prepareData();
    if (props.onPressOk) {
      props.onPressOk(data, state.selectedItem)
    }
  }

  const onPressCancel = () => {
    onHideModal();
    if (props.onPressCancel) {
      props.onPressCancel()
    }
  }

  const prepareData = () => {
    const categoryObj = Object.keys(state.selectedCategoryObj)?.length > 0 ? state.selectedCategoryObj : props.sebiCategories[0];
    const data =
    {
      "sebicategory": categoryObj.sebicategorycode,
      "allocationpercentage": 0,
      "assetclass": state.selectedItem[0]?.assetclass,
      "sebicategorydescription": categoryObj.mstarcategory,
      "netAllocation": Number(state.policyAlloc * ((state.selectedItem?.percentage / 100).toFixed(2))).toFixed(2),
      "revisedAllocation": "",
      "ruleno": props?.ruleNo,
      "fiveyearcategoryrate": Number(categoryObj.fiveyearcategoryrate),
      "lastupdate": categoryObj.lastupdate
    }

    console.log('newData:', data)
    return data;
  }


  const renderItemView = ({ title, percentage, onClickAddCategory }) => {
    const percentageValue = percentage || percentage === 0 ? `${percentage} %` : '';
    return (
      <tr className="ra_item_view">
        <td className="ra_item_title">{title}</td>
        <td className="ra_item_title">{ }</td>
        <td className="ra_item_title">{ }</td>
        <td className={"ra_item_percent_title"}>{percentageValue}</td>
        <td className="ra_item_title">{ }</td>
        <td className="ra_item_title">{ }</td>
        {props.data?.length > 0 && <td>
          <div className="ra_item_add" onClick={onClickAddCategory}>
            <label className="ra_button_style">{"+ Add Category"}</label>
          </div>
        </td>}
      </tr >
    );
  };

  const renderTableHeaderView = () => {
    return (
      <tr className="ra_header_row">
        <td className={"ra_headerStyle"}>{t("Sebi Category")}</td>
        <td className={"ra_headerStyle"}>{t("Policy allocation %")}</td>
        <td className={"ra_headerStyle"}>{t("Revised allocation")}</td>
        <td className={"ra_headerStyle"}>{t("Portfolio net allocation")}</td>
        <td className={"ra_headerStyle"}>{t("5 yr CAT rate")}</td>
        <td className={"ra_headerStyle"}>{t("Category Updated Date")}</td>
        <td className={"ra_headerStyle"}>{t("Action")}</td>
      </tr>
    );
  };

  const renderInputView = (value, item, index, onChange, data, percentage) => {
    return (
      <input
        type="text"
        className="ra_text_input"
        name="portNetAllocation"
        value={value}
        onChange={(e) => onChange(e, index, item, data, percentage)}
      ></input>
    );
  }

  const renderDeleteView = (item, index) => {
    return (
      <div className="ra_delete" onClick={() => { onClickDelete(item, index) }}>
        <img src={DeleteIcon} alt={"Delete Icon"} />
      </div>
    );
  }

  const renderDataView = ({ data, percentage, onChange }) => {
    return (
      data?.map((item, index) => (
        item.sebicategory && item.sebicategory !== "" &&
        <tr key={index} className={"ra_data_row"}>
          <td className={"ra_data_view"}>{item.sebicategorydescription}</td>
          <td className={"ra_data_view"}>{item.allocationpercentage}</td>
          <td className={"ra_data_view"}>
            {renderInputView(item.revisedAllocation, item, index, onChange, data, percentage)}
          </td>
          <td className={"ra_data_view"}>{item.netAllocation}</td>
          <td className={"ra_data_view"}>
            {Number((item.fiveyearcategoryrate) * 100)?.toFixed(2)}
          </td>
          <td className={"ra_data_view"}>{item.lastupdate}</td>
          <td className={"ra_data_view"}>
            {renderDeleteView(item, index)}
          </td>
        </tr>
      ))
    );
  };

  const renderEquityContentView = () => {
    const equityData = state.equityData?.length > 0 ? state.equityData : [{ assetclass: 'EQUTY' }];
    const assetValues = props.assetValues;
    return (
      <>
        {renderItemView({
          title: t("Equity"),
          percentage: assetValues.equity,
          onClickAddCategory: () => { onClickAddCategory(equityData, "Equity", assetValues.equity) }
        })}
        <div className="ra_separatorStyle" />
        {renderDataView({
          data: equityData,
          percentage: assetValues.equity,
          onChange: onChangeEquityRevised
        })}
      </>
    );
  }

  const renderDebtContentView = () => {
    const debtData = state.debtData?.length > 0 ? state.debtData : [{ assetclass: 'DEBT ' }];
    const assetValues = props.assetValues;
    return (
      <>
        {renderItemView({
          title: t("Debt"),
          percentage: assetValues.debt,
          onClickAddCategory: () => { onClickAddCategory(debtData, "Debt", assetValues.debt) }
        })}
        <div className="ra_separatorStyle" />
        {renderDataView({
          data: debtData,
          percentage: assetValues.debt,
          onChange: onChangeDebtRevised
        })}
      </>
    );
  }


  const renderOthersContentView = () => {
    const otherData = state.othersData?.length > 0 ? state.othersData : [{ assetclass: 'OTHER' }];
    const assetValues = props.assetValues;
    return (
      <>
        {renderItemView({
          title: t("Others"),
          percentage: assetValues.others,
          onClickAddCategory: () => { onClickAddCategory(otherData, "Others", assetValues.others) }
        })}
        <div className="ra_separatorStyle" />
        {renderDataView({
          data: otherData,
          percentage: assetValues.others,
          onChange: onChangeOthersRevised
        })}
      </>
    );
  }

  const renderTableContentView = (props) => {
    return (
      <tbody className="ra_tableContentView">
        {renderEquityContentView()}
        {renderDebtContentView()}
        {renderOthersContentView()}
        <div className="ra_extraView" />
      </tbody>
    );
  }
  const renderTableView = () => {
    return (
      <table className="ra_tableView">
        {renderTableHeaderView()}
        {renderTableContentView()}
      </table>
    );
  };

  const renderModalContentView = () => {
    const categories = props.sebiCategories;
    return (
      <div className="ra_modalContent">
        <select className="ra_categorySelect" value={state?.selectedCategory} onChange={onSelectCategory}>
          {categories?.map((category, index) => (
            <option key={index} value={category.sebicategorycode}>
              {category.mstarcategory}
            </option>
          ))}
        </select>
      </div>
    );
  }

  const renderAddModal = () => {
    const buttonNames = { no: t("Cancel"), yes: t("Ok") };
    return (
      <RecordModal
        open={state.showAddModal}
        onClose={onHideModal}
        onPressNo={onPressCancel}
        onPressYes={onPressOk}
        title={`Add Category For ${state.title}`}
        buttonNames={buttonNames}
        data={props.data}
      >
        {renderModalContentView()}
      </RecordModal>
    );
  }

  const renderDeleteModalView = () => {
    const { showDeleteModal } = state;
    const buttonNames = { no: t("no"), yes: t("yes") };
    const title = t("areYouSureYouWantToDelete?");
    return (
      <RecordModal
        open={showDeleteModal}
        onClose={onHideDeleteModal}
        onPressNo={onPressNo}
        onPressYes={onPressYes}
        title={title}
        buttonNames={buttonNames}
      >
      </RecordModal>
    );
  }

  return (
    <div className="ra_container">
      {renderTableView()}
      {state.showAddModal && renderAddModal()}
      {state.showDeleteModal && renderDeleteModalView()}
    </div>
  );
};

export default RuleAllocationView;