import React, { Component } from 'react';
import './cardcontainer.css';

class CardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  onDragStart(event) {
    if (this.props.onDragStart) {
      this.props.onDragStart(event)
    }
  }

  render() {
    const isDraggable = this.props.draggable ? this.props.draggable : false;
    return (
      <div
        draggable={isDraggable}
        onDragStart={this.onDragStart.bind(this)}
        className='cd_container'
        style={{...this.props.style, cursor: isDraggable ? "move": "pointer"}}
      >
        {this.props.children}
      </div>
    );
  }
}

export default CardContainer;
