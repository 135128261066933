import React, { Component } from "react";
import "./otherloanhome.css";
import { t } from "i18next";
import * as Bl from "../../../businesslogic";
import otherLoan from "../../../assets/svgs/other_loan.svg";
import {
  BreadCrumb,
  Button,
  TitleView,
  SideModel,
} from "../../components";
import { getLiabilityPathName, getUserId } from "../../common";
import { LIABILITIES_CATEGORY } from "../../constants";

const newLiability = {
  userid: "",
  liabilitiescategory: LIABILITIES_CATEGORY.OTHER_LIABILITIES,
  description: "",
  loanissuedby: "",
  maturityyear: 0,
  currentvalue: 0,
  maturityage: 0,
  isincludefinancialplan: false
};

class OtherLoanHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrawer: false,
      liabilityData: [],
      newLiabilityData: new Bl.Liability({...newLiability, userid: getUserId()}),
    };
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressAddOtherLoan() {
    this.props.navigate("/otherloandetail", {
      state: { liabilityData: this.state.newLiabilityData, isEditView: false },
    });
  }

 

  renderNavView() {
    const items = getLiabilityPathName("OtherLiabilities");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("oloanTitle");
    return <TitleView title={title} />;
  }

  renderProfileView() {
    return (
      <div className="ohome_profile_view">
        <img src={otherLoan} alt={t("oloanTitle")} className="ohome_icon" />
        <div className="ohome_info_view">{t("oloanInfo")}</div>
        <div className="ohome_add_property">
          <Button
            buttonName={t("oloanButton")}
            onPressButton={this.onPressAddOtherLoan.bind(this)}
          />
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderContentView() {
    return (
      <div className="ohome_content_view">
        {this.renderTitleView()}
        {this.renderProfileView()}
      </div>
    );
  }

  render() {
    return (
      <div className="ohome_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default OtherLoanHome;
