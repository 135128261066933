import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class CashLadderApi {
  static createAssetAllocation(userId, data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/assetAllocation/create?userid=${userId}`, Methods.POST, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static createCalculateEffectiveRate(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/calculateEffectiveRate`, Methods.POST, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  static createCashLadder(userId, data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/cashladder/create?userid=${userId}`, Methods.POST, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }


  static getAssetAllocationDetails(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/assetAllocation?userid=${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCashLadder(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/cashladder?userid=${data.uuid}&sbeffectiverate=${data.sbeffectiverate}&gbeffectiverate=${data.gbeffectiverate}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCashLadderWithLumsum(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/cashladderWithLumsum?userid=${data.uuid}&lumsum=${data.lumpsum}&sb_lumsum=${data.slump}&monthly_sip=${data.sip}&secure_bucket_sip=${data.ssip}&sbeffectiverate=${data.sbeffectiverate}&gbeffectiverate=${data.gbeffectiverate}&sip_till_age=${data.sipTillAge}&sip_growth=${data.sipGrowth}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCashLadderSipOptimization(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/sipOptimization?userid=${data.uuid}&lumsum=${data.lumpsum}&sb_lumsum=${data.slump}&monthly_sip=${data.sip}&secure_bucket_sip=${data.ssip}&sbeffectiverate=${data.sbeffectiverate}&gbeffectiverate=${data.gbeffectiverate}&sip_till_age=${data.sipTillAge}&sip_growth=${data.sipGrowth}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCashLadderGoalAllocation(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/goalAllocation?userid=${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCashLadderIncomeAllocation(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/incomeAllocation?userid=${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCashLadderGoalOptimization(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/goalOptimization?userid=${data.uuid}&lumsum=${data.lumpsum}&sb_lumsum=${data.slump}&monthly_sip=${data.sip}&secure_bucket_sip=${data.ssip}&sbeffectiverate=${data.sbeffectiverate}&gbeffectiverate=${data.gbeffectiverate}&sip_till_age=${data.sipTillAge}&sip_growth=${data.sipGrowth}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static generateIpsReport(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/generate_pdf?userid=${data.userId}&email=${data.emailId}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        })
    })
  }

  static generateAdvisorReport(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/generate_advisor_report?userid=${data.userId}&email=${data.emailId}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        })
    })
  }

  static initiateReview(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/intiatereview?userid=${data.userId}`, Methods.PATCH)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        })
    })
  }

  static unlockCashladder(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/unlockCashLadder?userid=${data.userId}`, Methods.PATCH)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        })
    })
  }
}

export default CashLadderApi;
