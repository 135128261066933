import React, { Component } from "react";
import "./goalsprocess.css";
import { t } from "i18next";
import { Button } from "../components";
import GoalBase from "./goalbase";
import {  getUserRole} from "../common"

class GoalsProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.userRole = getUserRole();
  }

  onClickStart() {
    if (this.props.onClickStart) {
      this.props.onClickStart();
    }
  }

  onClickGoalProgress(){
    if (this.props.onClickItem) {
      this.props.onClickItem();
    }
  }
  renderButtonView() {
    const buttonName = this.props.buttonName ? this.props.buttonName : t("start");
    return (
      <div className="gp_buttonView">
        <Button
          buttonName={buttonName}
          onPressButton={this.onClickStart.bind(this)}
        />
      </div>
    );
  }

  renderContentView(progress) {
    return (
      <div className={"gp_contentView"}>
        <img
          src={progress.icon ? progress.icon : ""}
          alt={"Logo"}
          className={"gp_iconStyle"}
          width={40}
          height={40}
        />
        <div className={"gp_textView"}>
          <label className="gp_title">{progress.title}</label>
          <label className="gp_info">{progress.info}</label>
        </div>
        <label className="gp_priority_number">{progress.priorityNo}</label>
        {this.props.showStartButton && this.renderButtonView()}
      </div>
    );
  }

  render() {
    const progress = this.props.progress;
    return (
      <div className="gp_container" onClick={this.onClickGoalProgress.bind(this)}>
        {this.renderContentView(progress)}
        <GoalBase color={progress.fill} className={"gp_baseView"} />
      </div>
    );
  }
}

export default GoalsProcess;
