import React, { Component } from "react";
import "./physicalassetdetail.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  Slider,
  TitleView,
  ToggleSwitch,
  CostItemPerYear,
  RecordModal,
  SideModel,
  Toast,
  ProgressDialog,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getCurrentYear } from "../../utils/datetime";
import * as logger from "../../utils/logger/index";
import { getCommonConfigInfo, getCurrentAge } from "../../utils/common";
import { getInvestmentPhysicalPath, calculate_age, getUserDOB, getUserId } from "../../common";
import { DEFAULT_OFFSET } from "../../constants";

class PhysicalAssetDetail extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const fixedAssetsData = params?.fixedAssetsData
      ? params.fixedAssetsData
      : {};
    const isEditView = params?.isEditView ? params.isEditView : false;

    this.state = {
      showModal: false,
      inputValue: "",
      editingItem: null,
      costValue: "15,00,000",
      viewExpense: false,
      selectedOptions: [],
      isViewSaleValueClicked: false,
      interestRate: 0,
      currentAge: 0,
      fixedAssetsData: fixedAssetsData ? new Bl.FixedAsset(fixedAssetsData) : 0,
      isEditView,
    };
    this.isRenderingAlready = false;
    this.costPerYearViewRef = React.createRef();
    this.dbData = fixedAssetsData
      ? JSON.parse(JSON.stringify(new Bl.FixedAsset(fixedAssetsData)))
      : {};
  }

  onSelectItem(path) {
    const localData = new Bl.FixedAsset(this.state.fixedAssetsData);
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      !this.state.isEditView && this.getConfigInfo();
      this.getCurrentAge();
    }
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
          });
        })
        .catch((error) => {
          console.log("OtherPhysicalAssetsPage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }
  getConfigInfo() {
    this.setState({ showProgress: true }, () => {
      getCommonConfigInfo()
        .then((configList) => {
          const configInfo = configList[0];
          const fixedAssetData = this.state.fixedAssetsData;
          fixedAssetData.setAssetInflation((configInfo.generalinflation * 100).toFixed(2));
          this.setState({
            showProgress: false,
          });
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  checkIsDataValid(data, isView = false) {
    let isValid = true;
    let message = "";
    if (!isView && !this.isBankNamevalid(data.fixedassetdescription)) {
      isValid = false;
      message = t("physicalAssetToastDescription");
    } else if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("physicalAssetToastCurrentBalance");
    }
    return { isValid, message };
  }

  isBankNamevalid(bankname) {
    return bankname && bankname.length > 0;
  }

  isAmountValid(amount) {
    return amount && amount > 0;
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  async createUserFixedAssets() {
    const currentYear = getCurrentYear();
    const fixedAssetData = this.state.fixedAssetsData;
    const startValue = fixedAssetData?.targetliquidationyear
      ? fixedAssetData.targetliquidationyear
      : currentYear + 1;
    const targetLiquidationAge = calculate_age(startValue, currentYear, this.state.currentAge)
    const fixedAssetsObj = new Bl.FixedAsset(this.state.fixedAssetsData);

    if (fixedAssetsObj.targetliquidationyear === 0) {
      fixedAssetsObj.setTargetLiquidationYear(getCurrentYear() + 1);
      fixedAssetsObj.setTargetLiquidationAge(targetLiquidationAge)
    }
    //initially asset inflation multiplied by 100 , so at time of store divide by 100
    fixedAssetsObj.assetinflation /= 100;

    const userId = await getUserId();
    fixedAssetsObj.setFixedAssetUuid(userId);

    const isDataValid = this.checkIsDataValid(fixedAssetsObj);
    console.log({ fixedAssetsObj })
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        fixedAssetsObj
          .createFixedAssets()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/physicalassetdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserFixedAssets() {
    const fixedAssetsObj = new Bl.FixedAsset(this.state.fixedAssetsData);
    //initially asset inflation multiplied by 100 , so at time of store divide by 100
    fixedAssetsObj.assetinflation /= 100;
    const isDataValid = this.checkIsDataValid(fixedAssetsObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        fixedAssetsObj
          .updateFixedAssets()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/physicalassetdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  onPressSaveButton() {
    if (this.state.isEditView) {
      this.updateUserFixedAssets();
    } else {
      this.createUserFixedAssets();
    }
  }

  onChangeDescription(value) {
    const fixedAssets = this.state.fixedAssetsData;
    fixedAssets.setFixedAssetDescription(value);
    this.forceUpdate();
  }

  onChangecurrentValue(value) {
    const fixedAssets = this.state.fixedAssetsData;
    fixedAssets.setCurrentValue(value ? Number(value) : 0);
    this.setState({ viewExpense: false });
  }

  onChangeInflationValue(value) {
    const fixedAssets = this.state.fixedAssetsData;
    fixedAssets.setAssetInflation(value ? Number(value) : 0);
    this.setState({ viewExpense: false });
  }

  onPressViewExpense() {
    const fixedObj = new Bl.FixedAsset(this.state.fixedAssetsData);
    const fixedAsset = this.state.fixedAssetsData;
    const period = fixedAsset?.targetliquidationyear
      ? fixedAsset?.targetliquidationyear - getCurrentYear()
      : 1;
    const isDataValid = this.checkIsDataValid(fixedObj, true);

    const data = {
      intial_investment: fixedAsset?.currentvalue,
      interest_rate: fixedAsset?.assetinflation / 100,
      investment_period: period,
      growth_rate: 0,
    };
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        Bl.Common.getFutureValue(data)
          .then((result) => {
            fixedAsset.setExpectedLiquidationValue(result.futureValue);
            this.setState({
              viewExpense: true,
              isViewSaleValueClicked: true,
              showProgress: false,
            }, () => {
              this.costPerYearViewRef.current.scrollIntoView({ behavior: "smooth" });
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentAge = getCurrentYear();
    const targetLiquidationAge = calculate_age(newValue, currentAge, this.state.currentAge);
    const fixedAsset = this.state.fixedAssetsData;
    fixedAsset.setTargetLiquidationYear(newValue);
    fixedAsset.setTargetLiquidationAge(targetLiquidationAge);
    this.setState({ viewExpense: false });
    this.forceUpdate();
  }

  handleEdit = (item) => {
    this.setState({
      showModal: true,
      inputValue: item.cost,
      editingItem: item,
    });
  };

  handleInputChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      inputValue: "",
      editingItem: null,
    });
  };

  handleSaveModal = () => {
    const { inputValue, editingItem } = this.state;
    // Update the cost value of the editingItem with the new input value
    editingItem.cost = inputValue;
    // Close the modal and update the state with the new cost value
    const fixedAssetsData = this.state.fixedAssetsData;
    fixedAssetsData.setExpectedLiquidationValue(Number(inputValue));
    this.setState({
      showModal: false,
      costValue: inputValue,
    });
  };

  toggleSellValue = (selectedItem, checked) => {
    const fixedAssetsData = this.state.fixedAssetsData;
    fixedAssetsData.setIsLiquidated(checked);
    this.setState({ viewExpense: false });
  };

  toggleSellValueFuture = (selectedItem, checked) => {
    const fixedAssetsData = this.state.fixedAssetsData;
    fixedAssetsData.setIsIncludeFinancialPlan(checked);
    this.setState({ viewExpense: false });
  };

  renderNavView() {
    const items = getInvestmentPhysicalPath("OtherPhysicalAssets");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        dbData={this.dbData}
        localData={this.state.fixedAssetsData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsPA4");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    selectionView = false,
    selectedOptions = []
  ) {
    return (
      <div className="pad_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
        />
      </div>
    );
  }

  renderToggleView(label, checked, onChange) {
    return (
      <div className="pad_toggle_view">
        <label className="pad_toggle_text">{label}</label>
        <ToggleSwitch
          checkedChildren={t("opaToggle1")}
          unCheckedChildren={t("opaToggle2")}
          onChangeToggle={onChange}
          defaultChecked={checked}
        />
      </div>
    );
  }

  renderArtDetails() {
    const fixedAssetsData = this.state.fixedAssetsData;
    const balance = fixedAssetsData?.currentvalue
      ? fixedAssetsData?.currentvalue
      : "";
    const description = fixedAssetsData?.fixedassetdescription;
    const includeSellValue = fixedAssetsData?.isliquidated;
    const assetInflation = fixedAssetsData?.assetinflation;
    return (
      <div className="pad_description_view">
        <div className="pad_input_row">
          {this.renderInputView(
            t("opaInputDescription"),
            description,
            this.onChangeDescription.bind(this)
          )}
          {this.renderInputView(
            t("opaInputValue"),
            balance,
            this.onChangecurrentValue.bind(this),
            true
          )}
        </div>
        <div className="pad_sec_row">
          {this.renderInputView(
            t("opaInflationValue"),
            assetInflation,
            this.onChangeInflationValue.bind(this),
            false,
            true
          )}
          {this.renderToggleView(
            t("opaTg1Text"),
            includeSellValue,
            this.toggleSellValue.bind(this)
          )}
        </div>

      </div>
    );
  }

  renderYearView() {
    const fixedAssetsData = this.state.fixedAssetsData;
    const currentYear = getCurrentYear();
    const targetliquidationyear =
      fixedAssetsData && fixedAssetsData.targetliquidationyear
        ? fixedAssetsData.targetliquidationyear
        : getCurrentYear() + 1;
    const age = calculate_age(targetliquidationyear, currentYear, this.state.currentAge);
    return (
      <div className="pad_slider_view">
        <div className="pad_start_year_row">
          <label className="pad_start_text">{t("opaSlider")}</label>
          <div className="pad_slider_content">
            <Slider
              value={targetliquidationyear}
              age={age}
              min={getCurrentYear() + 1}
              max={getCurrentYear() + 51}
              markStep={10}
              handleChange={this.handleChangeStart.bind(this)}
              showAge={true}
            />
          </div>
        </div>
      </div>
    );
  }

  renderCostItemPerYear() {
    const futureValue = this.state.fixedAssetsData?.expectedliquidationvalue;
    return (
      <div className="pad_cost_item_view" ref={this.costPerYearViewRef}>
        <CostItemPerYear
          item={{
            label: t("opaCostYear"),
            cost: futureValue,
          }}
          showEditIcon={true}
          handleEdit={this.handleEdit.bind(this)}
        />
      </div>
    );
  }

  renderEditModal() {
    const { showModal, inputValue } = this.state;
    const title = t("opaCostYear");
    return (
      <RecordModal
        open={showModal}
        onClose={this.handleCloseModal.bind(this)}
        title={title}
        buttonNames={{ one: t("opaModalButton") }}
        onPressYes={this.handleSaveModal.bind(this)}
        showInput={true}
        value={inputValue}
        label={t("opaValue")}
        showRupeeSymbol={true}
        onInputChange={this.handleInputChange.bind(this)}
      />
    );
  }

  renderButtonsView() {
    const fixedAssetsData = this.state.fixedAssetsData;
    const includeSellValue = fixedAssetsData?.isliquidated;
    return (
      <div className="pad_bottomView">
        <div className="pad_buttonView">
          <div className="pad_sale_button">
            <Button
              buttonName={t("opaButton1")}
              emptyButton={true}
              disabled={!includeSellValue}
              onPressButton={this.onPressViewExpense.bind(this)}
            />
          </div>

          <div className="pad_save">
            <Button
              buttonName={t("opaButton2")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderContentView() {
    const fixedAssetsData = this.state.fixedAssetsData;
    const includeSellValue = fixedAssetsData?.isliquidated;
    return (
      <div className="pad_content_view">
        {this.renderTitleView()}
        {this.renderArtDetails()}
        {includeSellValue && (
          <>
            <div className="pad_seperatorStyle" />
            {this.renderYearView()}
            {this.state.viewExpense && this.renderCostItemPerYear()}
            <div className="pad_seperatorStyle" />
            <div className="pad_retirement_toggle">
              {this.renderToggleView(
                t("opaTg2Text"),
                fixedAssetsData?.isincludefinancialplan,
                this.toggleSellValueFuture.bind(this)
              )}
            </div>
            {this.renderEditModal()}
          </>
        )}
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="pad_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default PhysicalAssetDetail;
