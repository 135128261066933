import React, { Component } from "react";
import {
  Button,
  BreadCrumb,
  TitleView,
  SideModel,
} from "../components";
import { t } from "i18next";
import "./riskprofilehome.css";
import RiskProfileStart from "../../assets/svgs/riskprofile_start.svg";
import { getRiskProfilePathName } from "../common";

class RiskProfileHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressSubmitButton() {
    this.props.navigate("/riskprofile");
  }

  renderNavView() {
    const items = getRiskProfilePathName();
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("rphTitle");
    return <TitleView title={title} />;
  }

  renderProfileView() {
    return (
      <div className="rph_profileView">
        <img
          src={RiskProfileStart}
          alt={t("rphButton")}
          className="rph_start_icon"
        />
        <div className="rph_info_view">{t("rphContentInfo")}</div>
        <div className="rph_buttonView">
          <Button
            buttonName={t("rphButton")}
            onPressButton={this.onPressSubmitButton.bind(this)}
          />
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 4;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  render() {
    return (
      <div className="rph_container">
        {this.renderNavView()}
        <div className="rph_content">
          {this.renderTitleView()}
          {this.renderProfileView()}
        </div>
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default RiskProfileHome;
