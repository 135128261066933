import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './supportenquirymodal.css';
import Button from './button';
import FloatingLabelInput from './floatinglabelinput';
import ProgressDialog from './progressdialog';
import Toast from './toast';
import Close from '../../assets/svgs/ic_close.svg';
import Success from "../../assets/svgs/successmodal.svg";
import * as Bl from "../../businesslogic";
import { SUPPORT_TYPE } from '../constants';
import { getUserDetails } from '../common';

const SupportEnquiryModal = (props) => {
  const [state, setState] = useState({
    name: '',
    panNumber: '',
    mobileNumber: '',
    interactionType:'enquiry',
    emailId: '',
    query: '',
    showToast: false,
    toastMessage: '',
        interactionNumber: null,
    showProgress: false,
    progressMessage: '',
  });
  const isRenderedAlready = useRef(false);

  useEffect(() => {
    if (!isRenderedAlready.current) {
      isRenderedAlready.current = true;
      getUserData();
    }
  }, [])

  const onClickClose = () => {
    if (props.hideModal) {
      props.hideModal()
    }
  }

   const onChangeName = (value) => {
  setState((prevState) => ({
    ...prevState,
    name: value
  }))
}
 const onChangeInteractionType = (type) => {
  setState((prevState) => ({
    ...prevState,
    interactionType: type, 
  }));
};

  const onChangeMobileNumber = (value) => {
 
  const numericValue = value.replace(/\D/g, '');
  setState((prevState) => ({
    ...prevState,
    mobileNumber: numericValue
  }));
};

  const onChangeEmailId = (value) => {
    const mailId = value.toLowerCase();
    setState((prevState) => ({
      ...prevState,
      emailId: mailId
    }))
  }

  const onChangeQuery = (event) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      query: value
    }))
  }


  const checkIsDataValid = () => {
  let isValid = true;
  let toastMessage = '';
  if (!state.emailId || state.emailId.length === 0) {
    isValid = false;
    toastMessage = 'Please enter your Email Id';
  } else {
    
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(state.emailId)) {
      isValid = false;
      toastMessage = 'Please enter a valid Email Id';
    }
  }

  return { isValid, toastMessage };
}

  const onPressSend = () => {
    const isDataValid = checkIsDataValid();

  if (isDataValid.isValid) {
    
    const data = {
      "name": state.name,
      "mobile": state.mobileNumber,
      "email": state.emailId,
      "description": state.query,
      "pan": state.panNumber,
      "supporttype": SUPPORT_TYPE.CLIENT,
      "status": "Progress",
      "interactiontype": state.interactionType,
      "isexistingcustomer": false,              
      "interactionopendate": new Date().toISOString().split('T')[0],
      "interactionstatus": "open",              
      "interactionsource": "DIRECT" 
    };
    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }));
    Bl.Support.createSupportQuery(data)
      .then(response => {    
   
         const interactionNumber = response.interactionnumber;    
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
          mobileNumber: '',
          emailId: '',
          panNumber: '',
          query: '',
          interactionType: '', 
          interactionNumber: interactionNumber,
          showResponseView: true
        }));
      })
      .catch(error => {        
        console.error('EnquiryModal/ onPressSend', error);
        showToast(error.message);
      });
  } else {
    
    showToast(isDataValid.toastMessage);
  }
};

  const showToast = (toastMessage) => {
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }

  const getUserData = () => {
    const userData = getUserDetails();
    setState((prevState) => ({
      ...prevState,
      panNumber: userData?.pannumber || ''
    }));
  };

  

  const renderHeaderView = (label) => {
    return (
      <div className='sem_headerView'>
        <label className='sem_headingStyle'>{label}</label>
        <img className='sem_closeIconStyle' src={Close} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderTextAreaView = (label, value, onChange) => {
    return (
      <div className='sem_textAreaView'>
        <label className='sem_textLabelStyle'>{label}</label>
        <textarea
          value={value}
          onChange={onChange}
          className='sem_textAreaStyle'
        />
      </div>
    );
  }
  const renderInputView = (
    label,
    value,
    onChange,    
    showRupeeSymbol = false,
    showPercentage = false,
    type,
    required = false 
  ) => {
    return (
      <div className="com_input">
        <FloatingLabelInput
          label={label}
          value={value}
          onChangeValue={onChange}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          
          type={type}
           required={required} 
        />
      </div>
    );
  };

  const renderInputsView = () => {
    return (
      <div className='com-inputs-content-view'>
        <label className='sem_labelStyle'>{t('Our friendly team would love to hear from you!')}</label>
        <div style={{ display: 'flex',gap: '20px' }} className='com-row-view'>
          {renderInputView(t('Name'), state.name, onChangeName, false, false, 'text')}
          {renderInputView(t('Email ID'), state.emailId, onChangeEmailId, false, false,'email', true)}
          {renderInputView(t('Mobile Number'), state.mobileNumber, onChangeMobileNumber, false, false, 'text')}

        </div>
         <div className='com-checkbox-container'>
        <div className='com-checkbox-item'>
          <input
            type='checkbox'
            id='enquiryCheckbox'
            checked={state.interactionType === 'enquiry'}
            onChange={() => onChangeInteractionType('enquiry')}
          />
          <label htmlFor='enquiryCheckbox'>{t('Enquiry')}</label>
        </div>
        <div className='com-checkbox-item'>
          <input
            type='checkbox'
            id='complaintsCheckbox'
            checked={state.interactionType === 'complaints'}
            onChange={() => onChangeInteractionType('complaints')}
          />
          <label htmlFor='complaintsCheckbox'>{t('Complaints')}</label>
        </div>
      </div>
         
        {renderTextAreaView(t('Write us your query'), state.query, onChangeQuery)}
        <label className='com-sub-label-style'>{t('contact us info')}</label>
      </div>
    );
  }

  const renderButtonView = () => {
    return (
      <div className='sem_buttonView'>
        <Button buttonName={t('Send')} onPressButton={onPressSend} />
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  const renderFormContent = () => {
    return (
      <div className={'sem_formContent'}>
        {renderHeaderView(t('Get in touch'))}
        {renderInputsView()}
        {renderButtonView()}
      </div>
    )
  }

 const renderResponseView = () => {
    return (
      <div className={"com_responseContentView"}>
        {renderHeaderView(t('Get in Touch'))}
        <div className={"com_responseView"}>
          <img src={Success} alt={"success"} className={"com_successStyle"} />
           <label className={"com_responseStyle"}>
          {t(`Thanks for submitting your details. Your interaction number for this interaction is `)}
          <span className={"highlight_number"}>
            {state.interactionNumber}
          </span>
          {t(`. Please quote this number for any future reference to this interaction. Fliber will get back to you soon.`)}
        </label>
        </div>
      </div>
    );
  }

  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      className={'sem_container'}
    >
      <div className='sem_content'>
        {state.showResponseView ? renderResponseView() : renderFormContent()}
        {state.showToast && renderToastView()}
        {state.showProgress && renderProgressDialog()}
      </div>
    </Modal>
  );
}

export default SupportEnquiryModal;