import React, { useState } from "react";
import "./stocksdetailpage.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  TitleView,
  Toast,
  ProgressDialog,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getFinancialMarketPath } from "../../common";
import * as logger from "../../utils/logger/index";

const StocksDetailPage = (props) => {
  const params = props.location.state;
  const investmentData = params?.investmentData ? params.investmentData : {};
  const isEditView = params?.isEditView ? params.isEditView : false;

  const [state, setState] = useState({
    investmentsData: investmentData ? new Bl.Investment(investmentData) : {},
    brokerName: investmentData?.investmentdetails[0]?.brokername
      ? investmentData?.investmentdetails[0]?.brokername
      : "",
    dematAccNo: investmentData?.investmentdetails[0]?.demataccountnumber
      ? investmentData?.investmentdetails[0]?.demataccountnumber
      : "",
    isEditView,
    showToast: false,
    showProgress: false
  });

  const dbData = investmentData
    ? JSON.parse(JSON.stringify(new Bl.Investment(investmentData)))
    : {};

  const onSelectItem = (path) => {
    const localData = new Bl.Investment(state.investmentsData);
    localData.revertAllChanges();

    setState((prevState) => ({
      ...prevState,
      brokerName: dbData?.investmentdetails[0]?.brokername
        ? dbData.investmentdetails[0].brokername
        : "",
      dematAccNo: dbData?.investmentdetails[0]?.demataccountnumber
        ? dbData.investmentdetails[0].demataccountnumber
        : "",
    }))

    props.navigate(path);
  }

  const checkIsDataValid = (data) => {
    let isValid = true;
    const invesmentDetails = data?.investmentdetails[0]
      ? data.investmentdetails[0]
      : "";
    let message = "";
    if (!isBrokerNamevalid(invesmentDetails.brokername)) {
      isValid = false;
      message = t("Please enter valid broker name");
    } else if (!isDematAccNoValid(invesmentDetails.demataccountnumber)) {
      isValid = false;
      message = t("Please enter valid demat account number");
    } else if (!isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("Please enter valid currenct balance");
    }
    return { isValid, message };
  }

  const isBrokerNamevalid = (brokerName) => {
    return brokerName && brokerName.length > 0;
  }

  const isDematAccNoValid = (demataccountnumber) => {
    return demataccountnumber && demataccountnumber.length === 4;
  }

  const isAmountValid = (amount) => {
    return amount && amount > 0;
  }

  const prepareStocksData = () => {
    const fields = {
      brokername: state.brokerName || '',
      demataccountnumber: state.dematAccNo || '',
    };
    const stockDetails = [];
    const stockDetail = {};
    let oneFieldAvail = false;
    Object.keys(fields).forEach((key) => {
      if (fields[key] !== '') {
        oneFieldAvail = true;
        stockDetail[key] = fields[key];
      }
    });
    if (oneFieldAvail) {
      stockDetails.push(stockDetail);
    }
    return { stockDetails };
  }

  const createUserInvestment = (data) => {
    const investmentObj = new Bl.Investment(state.investmentsData);
    investmentObj.setInvestmentDetails(data.stockDetails);
    const isDataValid = checkIsDataValid(investmentObj);
    console.log({ investmentObj })
    if (isDataValid.isValid) {
      setState((prevState) => ({
        ...prevState,
        showProgress: true
      }))

      investmentObj
        .createInvestment()
        .then(() => {
          setState((prevState) => ({
            ...prevState,
            showProgress: false
          }));

          props.navigate("/stocksdashboard");
        })
        .catch((error) => {
          logger.error({ error });
          showToast(t("someErrorHasOccured"));
        });
    } else {
      const toastMessage = isDataValid.message;
      showToast(toastMessage);
    }
  }

  const updateUserInvestment = (data) => {
    const investmentObj = new Bl.Investment(state.investmentsData);
    investmentObj.setInvestmentDetails(data.stockDetails);
    const isDataValid = checkIsDataValid(investmentObj);
    if (isDataValid.isValid) {

      setState((prevState) => ({
        ...prevState,
        showProgress: true
      }))
      investmentObj
        .updateInvestment()
        .then(() => {
          setState((prevState) => ({
            ...prevState,
            showProgress: false
          }))
          props.navigate("/stocksdashboard");
        })
        .catch((error) => {
          logger.error({ error });
          showToast(t("someErrorHasOccured"));
        });
    } else {
      const toastMessage = isDataValid.message;
      showToast(toastMessage);
    }
  }

  const onChangeBrokerName = (value) => {
    setState((prevState) => ({
      ...prevState,
      brokerName: value
    }))

    const data = prepareStocksData();
    const investment = state.investmentsData;
    investment.setInvestmentDetails(data.stockDetails);
  }

  const onChangeAccountNo = (value) => {
    setState((prevState) => ({
      ...prevState,
      dematAccNo: value
    }))

    const data = prepareStocksData();
    const investment = state.investmentsData;
    investment.setInvestmentDetails(data.stockDetails);
  }

  const onChangecurrentValue = (value) => {
    const investment = state.investmentsData;
    investment.setCurrentValue(value ? Number(value) : 0);
    setState((prevState) => ({
      ...prevState,
    }))
  }

  const onPressSaveButton = () => {
    const data = prepareStocksData();
    if (state.isEditView) {
      updateUserInvestment(data);
    } else {
      createUserInvestment(data);
    }
  }

  const hideToast = () => {
    setState((prevState) => ({
      ...prevState,
      toastMessage: "",
      showToast: false
    }))
  }

  const showToast = (toastMessage) => {
    setState((prevState) => ({
      ...prevState,
      toastMessage,
      showToast: true,
      showProgress: false
    }))
    setTimeout(() => {
      hideToast();
    }, 3000);
  }



  const renderNavView = () => {
    const items = getFinancialMarketPath("STOCK");
    const location = props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={onSelectItem}
        onClickItem={onSelectItem}
      />
    );
  }

  const renderTitleView = () => {
    const title = t('stocks');
    return (
      <TitleView title={title} />
    );
  }

  const renderInputView = (
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    selectionView = false,
    selectedOptions = [],
    type = ""
  ) => {
    return (
      <div className="std_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
          type={type}
        />
      </div>
    );
  }

  const renderBankSavingsDetails = () => {
    const investmentData = state.investmentsData;
    const balance = investmentData?.currentvalue ? investmentData?.currentvalue : "";
    const brokerName = state.brokerName;
    const demataccountnumber = state.dematAccNo;
    return (
      <div className="std_description_view">
        <div className="std_input_row">
          {renderInputView(
            t("Broker Name"),
            brokerName,
            onChangeBrokerName
          )}
          {renderInputView(
            t("Enter last 4 digits of Demat Account"),
            demataccountnumber,
            onChangeAccountNo,
            false,
            false,
            false,
            [],
            "number"
          )}
        </div>
        <div className="std_sec_row">
          {renderInputView(
            t("Current balance"),
            balance,
            onChangecurrentValue,
            true
          )}
        </div>
      </div>
    );
  }

  const renderButtonsView = () => {
    return (
      <div className="std_bottomView">
        <div className="std_buttonView">
          <div className="std_save">
            <Button
              buttonName={t("bsButton1")}
              onPressButton={onPressSaveButton}
            />
          </div>
        </div>
      </div>
    );
  }

  const renderContentView = () => {
    return (
      <div className="std_content_view">
        {renderTitleView()}
        {renderBankSavingsDetails()}
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  return (
    <div className="std_container">
      {renderNavView()}
      {renderContentView()}
      {renderButtonsView()}
      {renderToastView()}
      {renderProgressDialog()}
    </div>
  );
}

export default StocksDetailPage;
