import React from "react";
import "./annuityhome.css";
import { t } from "i18next";
import { Header, TitleView, Button, BreadCrumb } from "../../components";
import pensionImage from "../../../assets/svgs/postretmt/investments.svg";
import * as Bl from "../../../businesslogic";
import {
  INCOME_CATEGORY,
  INCOME_FREQUENCY_TYPE,
  INCOME_TYPE,
} from "../../constants";
import { getIncomePathName, getUserId } from "../../common";

const newIncome = {
  incomecategory: INCOME_CATEGORY.PENSION_ANNUITY,
  userid: '',
  frequencytype: INCOME_FREQUENCY_TYPE.YEARLY,
  incomefrequency: 1,
  incomeamount: 0,
  incomestartyear: 0,
  incomeendyear: 0,
  incometype: INCOME_TYPE.RECURRING_PERPETUAL,
  incomegrowthrate: 0,
  incomedescription: "",
  incomestartage: 0,
  incomeendage: 0,
};


class AnnuityHomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newIncomeData: new Bl.Income({ ...newIncome, userid: getUserId() }),
      incomeData: [],
    };

  }

  onSelectItem(path) {
    this.props.navigate(path);
  }
  onPressAddButton() {
    this.props.navigate("/annuitydetails", {
      state: { incomeData: this.state.newIncomeData, isEditView: false },
    });
  }

  renderNavView() {
    const items = getIncomePathName("Annuity");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("annuityTitle");
    return <TitleView title={title} />;
  }

  renderImage() {
    return <img src={pensionImage} alt="Pension" className="ahp_image" />;
  }

  renderDetails() {
    return (
      <span className="ahp_details_text">
        {t("annuityInfo")}
      </span>
    );
  }

  renderButton() {
    return (
      <div id="ahp_button_view">
        <Button
          buttonName={t("annuityButton")}
          onPressButton={this.onPressAddButton.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div id="ahp_container">
        {this.renderNavView()}
        <div id="ahp_inner_container">
          {this.renderTitleView()}
          {this.renderImage()}
          {this.renderDetails()}
          {this.renderButton()}
        </div>
      </div>
    );
  }
}
export default AnnuityHomePage;
