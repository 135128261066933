import React, { Component } from "react";
import "./weddingexpensepage.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  CostItemPerYear,
  FloatingLabelInput,
  ProgressDialog,
  Slider,
  TitleView,
  Toast,
} from "../components";
import { GOAL_CATEGORY, DEFAULT_OFFSET, GOAL_FREQUENCY_TYPE, GOAL_EXPENSE_TYPE } from "../constants";
import { getCurrentYear } from "../utils/datetime";
import * as Bl from "../../businesslogic";
import { calculate_age, getAdvisorId, getGoalsPathName, getUserDOB, getUserId } from "../common";
import { getGoalConfigInfo, getCurrentAge } from "../utils/common";

const newGoal = {
  userid: '',
  goalcategory: GOAL_CATEGORY.WEDDING,
  goalstartyear: 0,
  goalendyear: 0,
  goalamountpv: 0,
  goalfrequency: 1,
  advisorid: getAdvisorId(),
  goalfrequencytype: GOAL_FREQUENCY_TYPE.YEARLY,
  goalbucketcode: "",
  retirewanttype: "",
  monthlyexpenselist: [],
  goaldescription: "",
  goalinflationrate: 0,
  goalamountfv: 0,
  homeloanstatus: false,
  downpayment: 0,
  goaldetails: [],
  goalstartage: 0,
  goalendage: 0,
  userage: 0,
  expensetype:GOAL_EXPENSE_TYPE.ONE_TIME
};

class WeddingExpensePage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const isEditView = params?.isEditView ? params.isEditView : false;
    const goalData = params?.goalData;
    this.state = {
      startValue: isEditView ? goalData.goalstartyear : getCurrentYear() + 1,
      startYear: getCurrentYear() + 1,
      selectedSliderIndex: 0,
      description: isEditView ? goalData.goaldescription : "",
      cost: isEditView ? goalData.goalamountpv : "",
      inflationRate: isEditView ? goalData.goalinflationrate : 0,
      viewExpense: false,
      showButtons: false,
      currentAge: 0,
      isEditView: isEditView ? isEditView : false,
      newGoalData: isEditView ? new Bl.Goals(goalData) : new Bl.Goals({ ...newGoal, userid: getUserId() }),
    };
    this.costPerYearViewRef = React.createRef();
    this.inflationRate = 0;
    this.isRenderingAlready = false;
    this.dbValue = this.state.newGoalData;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      !this.state.isEditView && this.getInflationRate();
      this.getCurrentAge();
    }
  }

  onSelectItem(path) {
    const localData = new Bl.Goals(this.state.newGoalData)
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeDescription(value) {
    const goals = this.state.newGoalData;
    goals.setDescription(value);
    this.setState({ description: value });
  }

  onChangeWeddingCost(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalAmountPv(value ? Number(value) : 0);
    this.setState({ cost: value, viewExpense: false });
  }

  onChangeInflationRate(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalInflationRate(value ? Number(value) : 0);
    this.setState({ inflationRate: value, viewExpense: false });
  }

  isValueValid(value) {
    return value > 0;
  }
  isInflationValid(data) {
    let isValid = true;

    if (!data || data.toString().length <= 0) {
      isValid = false;
    }
    return isValid;
  }

  getInflationRate() {
    //TODO get inflation rate from API and use that
    this.setState({ showProgress: true, fetchingInflationRate: true }, () => {
      getGoalConfigInfo(GOAL_CATEGORY.WEDDING)
        .then((configList) => {
          const configInfo = configList;
          const goalsObj = this.state.newGoalData;
          goalsObj.setGoalInflationRate(configInfo.inflationrate);
          this.dbValue.goalinflationrate = configInfo.inflationrate;
          this.inflationRate = configInfo.inflationrate;
          this.setState({
            inflationRate: configInfo.inflationrate,
            showProgress: false,
            fetchingInflationRate: false
          }, () => {
            this.getDBValue()
          });
        })
        .catch((error) => {
          console.log("WeddingExpensePage.js/getInflationRate Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true, fetchingCurrentAge: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const goalsObj = this.state.newGoalData;
          goalsObj.setUserAge(currentAge);
          this.userage = currentAge;
          this.dbValue.userage = currentAge;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
            fetchingCurrentAge: false
          }, () => {
            this.getDBValue();
          });
        })
        .catch((error) => {
          console.log("WeddingExpensePage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getDBValue() {
    if(this.state.fetchingInflationRate === false && this.state.fetchingCurrentAge === false){
      const dbValue = this.state.newGoalData
      ? JSON.parse(JSON.stringify(this.state.newGoalData))
      : {};
      this.setState({dbValue})
    }
  }
  
  checkIsDataValid(data, skipDescriptionValidation = false) {
    let isValid = true;
    let message = "";

    if (!skipDescriptionValidation && !data.goaldescription) {
      isValid = false;
      message = t("wedToastDescription")
    } else if (!this.isValueValid(data.goalamountpv)) {
      isValid = false;
      message = t("wedToastCost");
    } else if (!this.isInflationValid(data.goalinflationrate)) {
      isValid = false;
      message = t("wedToastInflationPercentage");
    }
    return { isValid, message };
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  refreshData() {
    newGoal.goalinflationrate = this.inflationRate;
    newGoal.userage = this.userage;
    this.setState({
      description: "",
      cost: "",
      inflationRate: this.inflationRate,
      startValue: getCurrentYear() + 1,
      frequencyValue: 1,
      viewExpense: false,
      showButtons: false,
      newGoalData: new Bl.Goals({ ...newGoal, userid: getUserId() }),
    });
  }

  navigateToNextScreen() {
    this.props.navigate("/goalscategorize", { state: { path: "Wedding" } });
  }

  createWeddingGoal(callbackfn) {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (goalObj.goalstartyear === 0) {
      const currentYear = getCurrentYear();
      const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
      goalObj.setStartYear(this.state.startValue);
      goalObj.setGoalStartAge(startAge)
    }
    // Removed front & back white spaces in Description
    if (goalObj.goaldescription) {
      const goaldescription = goalObj.goaldescription.trim();
      goalObj.setDescription(goaldescription);
    }
    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true, viewExpense: false }, () => {
        goalObj
          .createGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log("WeddingExpensePage/createWeddingGoal Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  updateWeddingGoal(callbackfn) {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    // Removed front & back white spaces in Description
    if (goalObj.goaldescription) {
      const goaldescription = goalObj.goaldescription.trim();
      goalObj.setDescription(goaldescription);
    }
    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true, viewExpense: false }, () => {
        goalObj
          .updateGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log("WeddingExpensePage/updateWeddingGoal Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  onPressSaveAndAdd() {
    const { showButtons } = this.state;
    if (!showButtons) {
      return;
    }
    this.createWeddingGoal(this.refreshData.bind(this));
  }

  onPressSaveButton() {
    const { showButtons } = this.state;
    if (!showButtons) {
      return;
    }
    if (!this.state.isEditView) {
      this.createWeddingGoal(this.navigateToNextScreen.bind(this));
    }
    else {
      this.updateWeddingGoal(this.navigateToNextScreen.bind(this));
    }
  }

  onPressViewExpense() {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (goalObj.goalstartyear === 0) {
      const currentYear = getCurrentYear();
      const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
      goalObj.setStartYear(this.state.startValue);
      goalObj.setGoalStartAge(startAge)
      this.setState({ startYear: getCurrentYear() + 1 });
    }
    const isDataValid = this.checkIsDataValid(goalObj, true);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        goalObj
          .viewExpectedAmt()
          .then((data) => {
            this.setState({
              flowAmount: data.result[0].flowamount,
              showProgress: false,
              viewExpense: true,
              showButtons: true
            }, () => {
              this.costPerYearViewRef.current.scrollIntoView({ behavior: "smooth" });
            });
          })
          .catch((error) => {
            console.log("WeddingExpensePage/onPressViewExpense Error:", error);
            this.showToast(t('Some error has occurred'));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const startAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const goals = this.state.newGoalData;
    goals.setStartYear(newValue);
    goals.setGoalStartAge(startAge)
    this.setState({ startValue: newValue, viewExpense: false });
  }

  handleSelectSlider(index) {
    this.setState({ selectedSliderIndex: index });
  }

  renderNavView() {
    const items = getGoalsPathName("Wedding");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        dbData={this.dbValue}
        localData={this.state.newGoalData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("wedTitle");
    return <TitleView title={title} information={""} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="wed_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderWeddingDetails() {
    return (
      <div className="wed_description_view">
        <div className="wed_input_row">
          {this.renderInputView(
            t("wedInputDescription"),
            this.state.description,
            this.onChangeDescription.bind(this)
          )}
          {this.renderInputView(
            t("wedInputCost"),
            this.state.cost,
            this.onChangeWeddingCost.bind(this),
            true
          )}
        </div>
        <div className="wed_input_sec_row">
          {this.renderInputView(
            t("wedInputInflationRate"),
            this.state.inflationRate,
            this.onChangeInflationRate.bind(this),
            false,
            true
          )}
        </div>
      </div>
    );
  }

  renderYearView() {
    const currentYear = getCurrentYear();
    const age = calculate_age(this.state.startValue, currentYear, this.state.currentAge);
    return (
      <div className="wed_slider_view">
        <div className="wed_start_year_row">
          <label className="wed_start_text">
            {t("wedSlider")}
          </label>
          <div className="wed_slider_content">
            <Slider
              value={this.state.startValue}
              age={age}
              min={currentYear + 1}
              max={currentYear + 51}
              markStep={10}
              handleChange={this.handleChangeStart.bind(this)}
              showAge={true}
            />
          </div>
        </div>
      </div>
    );
  }

  renderCostItemPerYear() {
    const label = `${t("wedCostYear")} ${this.state.startValue}`;
    return (
      <div className="wed_cost_item_view" ref={this.costPerYearViewRef}>
        <CostItemPerYear
          item={{
            label: label,
            cost: this.state.flowAmount,
          }}
        />
      </div>
    );
  }

  renderButtonsView() {
    const { showButtons } = this.state;
    return (
      <div className="wed_bottomView">
        {!this.state.isEditView && <div className="wed_buttonView">
          <div>
            <label
              onClick={this.onPressSaveAndAdd.bind(this)}
              className="wed_link_text"
              disabled={!showButtons}
              style={{ opacity: showButtons ? 1 : 0.5 }}
            >
              {t("wedButtonLink")}
            </label>
          </div>
        </div>}
        <div className="wed_expense_button">
          <Button
            buttonName={t("wedButton1")}
            emptyButton={true}
            onPressButton={this.onPressViewExpense.bind(this)}
          />
        </div>
        <div className="wed_save">
          <Button
            buttonName={t("wedButton2")}
            onPressButton={this.onPressSaveButton.bind(this)}
            disabled={!showButtons}
            style={{ opacity: showButtons ? 1 : 0.5 }}
          />
        </div>
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="wed_content_view">
        {this.renderTitleView()}
        {this.renderWeddingDetails()}
        <div className="wed_seperatorStyle" />
        {this.renderYearView()}
        {this.state.viewExpense && this.renderCostItemPerYear()}
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  // renderDrawerContent() {
  //   const headerTitle = t("financialPlan");
  //   const step = 2;
  //   return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  // }
  render() {
    return (
      <div className="wed_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default WeddingExpensePage;
