import React from 'react';
import { Progress } from 'rsuite';
import './circularprogressbar.css'

const CircularProgressBar = ({ percent, strokeColor, strokeWidth }) => {
  return (
    <div className="circle_container">
      <Progress.Circle
        percent={percent ? percent : 0}
        strokeWidth={strokeWidth ? strokeWidth : 6}
        strokeColor={strokeColor ? strokeColor : "#ffc107"}
        trailColor="#ddd"
      />
      {/* <div className="dot" style={{ left: `${percent}%` }}></div> */}
    </div>
  );
};

export default CircularProgressBar;