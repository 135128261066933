import { apiCall } from './apiservice';
import Methods from './methods.json';


class AccountPortfolioRuleApi {


  static getAllSebiCategoryValues() {
    return new Promise((resolve, reject) => {
      apiCall(`Sebimstarcategorymap/category/`, Methods.GET).then((response) => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static getGrowthBucketValues(data) {
    return new Promise((resolve, reject) => {
      apiCall(`AccountPortfolioRule/growth_bucket/${data.uuid}`, Methods.GET).then((response) => {
        if (response) {
          resolve(response.data);
        }
      }).catch(error => {
        reject(error)
      })
    })
  }

  static getSecureBucketValues(data) {
    return new Promise((resolve, reject) => {
      apiCall(`AccountPortfolioRule/secure_bucket/${data.uuid}`, Methods.GET).then((response) => {
        if (response) {
          resolve(response.data);
        }
      }).catch(error => {
        reject(error)
      })
    })
  }

  static createAccountPortfoliRule(userId, bucketType, data) {
    return new Promise((resolve, reject) => {
      apiCall(`AccountPortfolioRule/?userid=${userId}&bucket_type=${bucketType}`, Methods.POST, data).then((response) => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static calculateEffectiveRate(data) {
    return new Promise((resolve, reject) => {
      apiCall(`AccountPortfolioRule/calculateEffectiveRate`, Methods.POST, data).then((response) => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static updateSebicategoryValues(data) {
    return new Promise((resolve, reject) => {
      apiCall(`Sebimstarcategorymap/`, Methods.PATCH, data).then((response) => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default AccountPortfolioRuleApi;