import classNames from 'classnames';
import { Message, toaster } from 'rsuite';


class AlertNotify {
  static getDefaultOptions() {
    const options = {
      showIcon: true,
      closeable: false,
      placement: "bottomEnd",
    };

    return options;
  }

  static getMessageContent(message, type, duration = 5000, options) {
    const { showIcon, closeable, className } = options ? {...this.getDefaultOptions(), ...options} : this.getDefaultOptions();
    return (
      <Message
        type={type}
        showIcon={showIcon}
        closable={duration > 0 ? closeable : true}
        className={classNames("alert-view", className)}
      >
        {message}
      </Message>
    );
  }

  static info(message, duration = 5000, options) {
    const { placement } = options ? options : this.getDefaultOptions();
    toaster.push(
      this.getMessageContent(message, 'info', duration, options),
      { placement, duration }
    );
  }

  static error(message, duration = 5000, options) {
    const { placement } = options ? options : this.getDefaultOptions();
    toaster.push(
      this.getMessageContent(message, 'error', duration, options),
      { placement, duration }
    );
  }

  static warning(message, duration = 5000, options) {
    const { placement } = options ? options : this.getDefaultOptions();
    toaster.push(
      this.getMessageContent(message, 'warning', duration, options),
      { placement, duration }
    );
  }

  static success(message, duration = 5000, options) {
    const { placement } = options ? options : this.getDefaultOptions();
    toaster.push(
      this.getMessageContent(message, 'success', duration, options),
      { placement, duration }
    );
  }
}

export default AlertNotify;