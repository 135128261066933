import { useState } from 'react';
import { t } from 'i18next';
import { Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './index.css';
import * as Bl from '../../../../businesslogic';
import {
  Button,
  FloatingLabelInput,
  ProgressDialog,
  Toast
} from '../../../components';
import Close from '../../../../assets/svgs/websvgs/close.svg';
import SSScoreDialler from './ssscoredialler/ssscoredialler';

const SustainabilityScoreModal = (props) => {
  const [state, setState] = useState({
    currentAge: '',
    retirementAge: '',
    currMonExp: 0,
    healthExp: 0,
    corpusValue: 0,
    postRetInc: 0,
    showToast: false,
    toastMessage: '',
    showProgress: false,
    progressMessage: '',
    resultObj: {},
    showResultModal: false
  });

  const onClickClose = () => {
    if (props.hideModal) {
      props.hideModal()
    }
  }

  const onChangeCurrentAge = (value) => {
    setState((prevState) => ({
      ...prevState,
      currentAge: Number(value)
    }))
  }

  const onChangeRetirementAge = (value) => {
    setState((prevState) => ({
      ...prevState,
      retirementAge: Number(value)
    }))
  }

  const onChangeCurrentMonthExp = (value) => {
    setState((prevState) => ({
      ...prevState,
      currMonExp: Number(value)
    }))
  }

  const onChangeHealthExp = (value) => {
    setState((prevState) => ({
      ...prevState,
      healthExp: Number(value)
    }))
  }

  const onChangeCorpusValue = (value) => {
    setState((prevState) => ({
      ...prevState,
      corpusValue: Number(value)
    }))
  }

  const onChangePostRetInc = (value) => {
    setState((prevState) => ({
      ...prevState,
      postRetInc: Number(value)
    }))
  }

  const onClickRetakeAssessment = () => {
    setState((prevState) => ({
      ...prevState,
      currentAge: '',
      retirementAge: '',
      currMonExp: '',
      healthExp: '',
      corpusValue: '',
      postRetInc: '',
      resultObj: {},
      showResultModal: false
    }))
  }

  const onPressCalculate = () => {
    const params = `current_age=${state.currentAge}&retirnment_age=${state.retirementAge}&currentmonthyexpense=${state.currMonExp}&monthlyhealthexpense=${state.healthExp}&corpusvalue=${state.corpusValue}&postretirementincome=${state.postRetInc}`;
    console.log({ params })
    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }))

    Bl.ScoreCalculation.calculateSustainAbilityScore(params).then(result => {
      setState((prevState) => ({
        ...prevState,
        resultObj: result,
        showProgress: false,
        showResultModal: true
      }))
    }).catch((error) => {
      console.log('onPressCalculate:', error)
      setState((prevState) => ({
        ...prevState,
        showProgress: false
      }))
    })
  }

  const showToast = (toastMessage) => {
    console.log('showToast called')
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }

  const renderInputView = (
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    type
  ) => {
    return (
      <div className="ss_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          type={type}
        />
      </div>
    );
  };

  const renderHeaderView = (label) => {
    return (
      <div className='ss_headerView'>
        <label className='ss_headingStyle'>{label}</label>
        <img className='ss_closeIconStyle' src={Close} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderInputsView = () => {
    return (
      <div className='ss_inputsContentView'>
        <div className='ss_inputsRowView'>
          {renderInputView(t('Current Age'), state.currentAge, onChangeCurrentAge, false, false, 'number')}
          {renderInputView(t('Retirement Age'), state.retirementAge, onChangeRetirementAge, false, false, 'number')}
        </div>
        <label className='ss_labelStyle'>{t('Post Retirement Ongoing Expenses')}</label>
        <div className='ss_inputsRowView'>
          {renderInputView(t('Current Monthly Household Expense'), state.currMonExp, onChangeCurrentMonthExp, true, false)}
          {renderInputView(t('Current Monthly Healthcare Expense'), state.healthExp, onChangeHealthExp, true, false)}
        </div>
        <label className='ss_labelStyle'>{t('Post Retirement Savings & Income')}</label>
        <div className='ss_inputsRowView'>
          {renderInputView(t('Total Value of Your Retirement Savings'), state.corpusValue, onChangeCorpusValue, true, false)}
          {renderInputView(t('Annual Income (Pension/ Rental/ etc.,)'), state.postRetInc, onChangePostRetInc, true, false)}
        </div>
      </div>
    );
  }

  const renderButtonView = () => {
    return (
      <div className='ss_buttonView'>
        <Button buttonName={t('Calculate')} onPressButton={onPressCalculate} />
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  const renderFormContent = () => {
    return (
      <div className={'ss_formContent'}>
        {renderHeaderView(t('Calculate Sustainability Score'))}
        {renderInputsView()}
        {renderButtonView()}
      </div>
    )
  }

  const renderDiallerView = () => {
    const result = state?.resultObj?.sustainabilityScoreDisplay;
    return (
      <div className={'ss_scoreContent'}>
        <SSScoreDialler value={result} />
      </div>
    );
  }

  const renderRetakeView = () => {
    return (
      <div className='ss_retakeView' onClick={onClickRetakeAssessment}>
        <label className='ss_retakeStyle'>{'Retake Score'}</label>
      </div>
    );
  }

  const renderResultContent = () => {
    return (
      <div className={'ss_resultContent'}>
        {renderHeaderView('Your Sustainability Score')}
        {renderDiallerView()}
        {renderRetakeView()}
      </div>
    );
  }

  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      className={state.showResultModal ? 'ss_resultContainer' : 'ss_container'}
    >
      <div className='ss_content'>
        {state.showResultModal ?
          renderResultContent() :
          renderFormContent()
        }
        {state.showToast && renderToastView()}
        {state.showProgress && renderProgressDialog()}
      </div>
    </Modal>
  );
}

export default SustainabilityScoreModal;