import React from "react";
import "./funddetailscardview.css";
import Expand from "../../assets/svgs/ic_expand.svg";
import { t } from "i18next";

class FundDetailsCardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  onClickExpandIcon() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  renderLogoView(cardDetails) {
    const source = cardDetails.url;
    return <img src={source} alt="Fund" className={"fdc_logoStyle"} />;
  }

  renderTitleNAmountView(cardDetails) {
    const title = cardDetails.name;
    const amount = cardDetails.total;
    return (
      <div className="fdc_titleNamtView">
        <label className="fdc_titleStyle">{title}</label>
        <label className="fdc_amtStyle">{`${t("fdcamount")} : ₹ ${amount}`}</label>
      </div>
    );
  }

  renderExpandIconView() {
    const isExpanded = this.state.isExpanded;
    return (
      <div
        className="fdc_expandIconView"
        onClick={this.onClickExpandIcon.bind(this)}
      >
        <img
          className={isExpanded ? "fdc_expand_icon" : "fdc_expand_icon_rorate"}
          src={Expand}
          alt={"Expand"}
        />
      </div>
    );
  }
  renderContentHeaderView(cardDetails) {
    return (
      <div className="fdc_contentHeader">
        {this.renderLogoView(cardDetails)}
        {this.renderTitleNAmountView(cardDetails)}
        {this.renderExpandIconView(cardDetails)}
      </div>
    );
  }

  renderLabelNValueView(label, value) {
    return (
      <div className="fdc_labelNvalueView">
        <label className="fdc_labelStyle">{label}</label>
        <label className="fdc_valueStyle">{value}</label>
      </div>
    );
  }

  renderRatingView(ratingUrl, rating) {
    const ratingView = [];
    for (let index = 0; index < rating; index++) {
      ratingView.push("*");
    }
    return (
      <div className="fdc_labelNvalueView">
        <img src={ratingUrl} alt={"Rating"} className={"fdc_ratingUrlStyle"} />
        <div className="fdc_ratingStyle">{ratingView}</div>
      </div>
    );
  }

  renderContentDetailsView(cardDetails) {
    return (
      <div className="fdc_detailsView">
        <div className="fdc_separatorStyle"></div>
        <div className="fdc_detailsRowView">
          {this.renderLabelNValueView(t("category"), cardDetails.category)}
          {this.renderLabelNValueView(t("units"), cardDetails.units)}
          {this.renderLabelNValueView(
            t("currentValue"),
            cardDetails.currentValue
          )}
        </div>
        <div className="fdc_separatorStyle"></div>
        <div className="fdc_detailsRowView">
          {this.renderLabelNValueView(t("fundNav"), `₹ ${cardDetails.fundNav}`)}
          {this.renderLabelNValueView(
            t("sharpeRatio"),
            cardDetails.sharpeRatio
          )}
          {this.renderRatingView(cardDetails.ratingUrl, cardDetails.rating)}
        </div>
        <div className="fdc_separatorStyle"></div>
        <div className="fdc_detailsRowView">
          {this.renderLabelNValueView(
            t("oneYrReturn"),
            cardDetails.oneYrReturn
          )}
          {this.renderLabelNValueView(
            t("threeYrReturn"),
            cardDetails.threeYrReturn
          )}
          {this.renderLabelNValueView(
            t("fiveYrReturn"),
            cardDetails.fiveYrReturn
          )}
        </div>
      </div>
    );
  }

  render() {
    const isExpanded = this.state.isExpanded;
    const cardDetails = this.props.cardDetail;
    return (
      <div className="fdc_container">
        {this.renderContentHeaderView(cardDetails)}
        {isExpanded && this.renderContentDetailsView(cardDetails)}
      </div>
    );
  }
}

export default FundDetailsCardView;
