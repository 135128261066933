import React, { Component } from 'react';
import { t } from 'i18next';


export default class RRScoreNeedle extends Component {
  getDescribeLabel(value) {
    let describeLabel = '';
    if (value >= 125) {
      describeLabel = t('excellent');
    } else if (value >= 100) {
      describeLabel = t('veryGood');
    } else if (value >= 90) {
      describeLabel = t('good');
    } else if (value >= 75) {
      describeLabel = t('fair');
    } else {
      describeLabel = t('needsAttention');
    }
    return describeLabel;
  }

  renderNeedle = opts => {
    let x1 = opts.cX,
      y1 = opts.cY - opts.needleWidth / 2,
      x2 = opts.cX,
      y2 = opts.cY + opts.needleWidth / 2,
      x3 = 165,
      y3 = opts.cY;

    let needleAngle = 0;
    let currentValue = this.getDescribeLabel(opts.currentValue);
    if (currentValue === t('excellent')) {
      needleAngle += 230;
    }
    if (currentValue === t('veryGood')) {
      needleAngle += 185;
    }
    if (currentValue === t('good')) {
      needleAngle += 130;
    }
    if (currentValue === t('fair')) {
      needleAngle += 80;
    }
    if (currentValue === t('needsAttention')) {
      needleAngle += 20;
    }

    needleAngle -= 127;
    let needleElm = null;
    if (opts.needleSharp) {
      needleElm = (
        <polygon
          points={`${x1},${y1} ${x2},${y2} ${x3},${y3}`}
          fill={opts.needleColor}
        />
      );
    } else {
      needleElm = (
        <line
          x1={opts.cX}
          y1={opts.cY}
          x2={opts.diameter}
          y2={opts.cY}
          fill="none"
          strokeWidth={opts.needleWidth}
          stroke={opts.needleColor}
        />
      );
    }

    return (
      <g className="needle">
        <g transform={`rotate(${needleAngle} ${opts.cX} ${opts.cY})`}>
          {needleElm}
        </g>
        <circle
          cx={opts.cX}
          cy={opts.cY}
          r={opts.needleBaseSize}
          fill={opts.needleBaseColor}
        />
      </g>
    );
  };

  renderValueText(value) {
    const displayValue = value || value === 0 ? `${value} %` : '0 %';
    return (
      <text
        x={100}
        y={140}
        fontFamily={"RubikMedium"}
        fontSize={24}
        width={1}
        textAnchor="middle"
        fill={'#013F60'}>
        {displayValue}
      </text>
    );
  }

  renderDescribeText(value) {
    const label = this.getDescribeLabel(value);
    return (
      <text
        x={100}
        y={160}
        fontFamily={'RubikRegular'}
        fontSize={14}
        width={10}
        textAnchor="middle"
        fill={'#51668A'}>
        {label}
      </text>
    );
  }

  render() {
    let opts = Object.assign({}, this.props);
    let { size, dialWidth } = opts;
    let cX = size / 2;
    let cY = size / 2;
    let radius = (size - 2 * dialWidth) / 2;
    let diameter = 2 * radius;
    let circumference = 2 * Math.PI * radius;
    opts = Object.assign(opts, {
      cX,
      cY,
      radius,
      diameter,
      circumference,
    });

    return (
      <svg
        className={opts.className}
        height={size}
        width={size}
        viewBox={`0 0 ${size} ${size}`}>
        <g transform={`rotate(-90 ${cX} ${cY})`}>{this.renderNeedle(opts)}</g>
        {this.renderValueText(opts.currentValue)}
        {this.renderDescribeText(opts.currentValue)}
      </svg>
    );
  }
}

RRScoreNeedle.defaultProps = {
  size: 200,

  dialWidth: 10,
  dialColor: '#eee',

  tickLength: 3,
  tickWidth: 1,
  tickColor: '#cacaca',
  tickInterval: 10,

  maximumValue: 100,
  currentValue: 25,
  progressWidth: 5,
  progressColor: '#3d3d3d',
  progressRoundedEdge: true,
  downProgressColor: 'red',
  progressFont: 'Serif',
  progressFontSize: '40',

  needle: true,
  needleBaseSize: 15,
  needleBaseColor: '#013F60',
  needleWidth: 5,
  needleSharp: false,
  needleColor: '#013F60',
};
