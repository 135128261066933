import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class DashboardApi {

  static getAccountDetails(data) {
    return new Promise((resolve, reject) => {
      apiCall(`dashboard/{uuid}?pancard=${data.pancard}`, Methods.GET).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  static getPermanentCashladderValues(data) {
    return new Promise((resolve, reject) => {
      apiCall(`CashLadder/permanent?userid=${data.uuid}`, Methods.GET).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default DashboardApi;