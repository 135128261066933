import React from "react";
import './displayalertview.css';
import { t } from "i18next";
import { getUserId, setSelectedUserDetail } from "../common";
import * as Bl from '../../businesslogic';


const DisplayAlertView = (props) => {

  const onPressYes = () => {
    const userId = getUserId();

    const data = {
      userId: userId
    }

    Bl.CashLadder.unlockCashladder(data)
      .then(async (response) => {
        const userDetail = await Bl.User.getUserDetailsById(userId);
        setSelectedUserDetail(userDetail?.User)
        if (props.onPressYes) {
          props.onPressYes();
        }
      }).catch((error) => {
        console.log('Unlock cashladder error:', error)
      })
  }

  return (
    <div className="dav_container">
      <label className="dav_textStyle">{"This cashladder is locked, do you want to unlock it?"}</label>
      <div onClick={onPressYes}>
        <label className="dav_buttonTextStyle">{t('Yes')}</label>
      </div>
    </div>
  );
}

export default DisplayAlertView;