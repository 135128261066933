import React from 'react';
import './togglesingleselection.css';

/* Customisable Toggle selection component.
contentCb is for rendering the unselect view (design as per our need),
selectedContentCb is for rendering the selected view (design as per our need) */

const ToggleSingleSelection = (props) => {

    function onSelectItem(index) {
        if (props.onSelectItem) {
            props.onSelectItem(index);
        }
    }
    const displayViews = [];
    let index = 0;
    for (let rowIndex = 0; rowIndex < props.noOfRows; rowIndex++) {
        const displayRowView = [];
        for (let colIndex = 0; colIndex < props.noOfCols; colIndex++) {
            if (index < props.noOfRows * props.noOfCols) {
                const displayItem = index === props.selectedIndex ?
                    props.selectedContentCb(index) :
                    props.contentCb(index)
                displayRowView.push(
                    <div
                        className={`ts-colStyle ${props.colStyle}`}
                        key={props.noOfCols * rowIndex + colIndex}
                        onClick={() => onSelectItem(props.noOfCols * rowIndex + colIndex)}
                    >
                        {displayItem}
                    </div>
                )
                index++;
            }
        }
        displayViews.push(
            <div className={`ts-rowStyle ${props.rowStyle}`} key={rowIndex}>
                {displayRowView}
            </div>
        );
    }
    return (
        <div className={`ts-viewStyle ${props.viewStyle}`}>
            {displayViews}
        </div>
    );
}

export default ToggleSingleSelection;