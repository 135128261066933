import "./cashladdereditmodal.css";
import { Modal } from "rsuite";
import { AiOutlineClose as Close } from "react-icons/ai";
import FloatingLabelInput from "./floatinglabelinput";
import { useState } from "react";
import Button from "./button";
import { t } from "i18next";
import Toast from "./toast";

const CashLadderEditModal = (props) => {
  const [state, setState] = useState({
    lumpsum: props.lumpsum || props.lumpsum === 0 ? props.lumpsum : '',
    secureLumpsum: props.secureLumpsum || props.secureLumpsum === 0 ? props.secureLumpsum : '',
    growthLumpsum: props.growthLumpsum || props.growthLumpsum === 0 ? props.growthLumpsum : '',
    sip: props.monthlySip || props.monthlySip === 0 ? props.monthlySip : '',
    secureSip: props.secureSip || props.secureSip === 0 ? props.secureSip : '',
    growthSip: props.growthSip || props.growthSip === 0 ? props.growthSip : '',
    currentAge: props.currentAge,
    lifeexpectancy: props.lifeexpectancy,
    sipTillAge: props.sipTillAge || props.sipTillAge === 0 ? props.sipTillAge : '',
    sipGrowth: props.sipGrowth || props.sipGrowth === 0 ? props.sipGrowth : '',
    showToast: false
  });

  const onClickClose = () => {
    if (props.onClickClose) {
      props.onClickClose();
    }
  };

  const onChangeTotalLumpsum = (value) => {
    setState((prevState) => ({
      ...prevState,
      lumpsum: Number(value),
      growthLumpsum: Number(value) === 0 ? 0 : Number(value - prevState.secureLumpsum),
      secureLumpsum: Number(value) === 0 ? 0 : Number(prevState.secureLumpsum)
    }));
  };

  const onChangeSecureLumpsum = (value) => {
    setState((prevState) => ({
      ...prevState,
      secureLumpsum: Number(value),
      growthLumpsum: Number(prevState.lumpsum - value),
    }));
  };

  const onChangeTotalSIP = (value) => {
    setState((prevState) => ({
      ...prevState,
      sip: Number(value),
      growthSip: Number(value) === 0 ? 0 : Number(value - prevState.secureSip),
      secureSip: Number(value) === 0 ? 0 : Number(prevState.secureSip)
    }));
  };

  const onChangeSecureSIP = (value) => {
    setState((prevState) => ({
      ...prevState,
      secureSip: Number(value),
      growthSip: Number(prevState.sip - value),
    }));
  };

  const onPressSaveButton = () => {
    if (props.isLumpsumView) {
      if (props.onPressSaveButton) {
        props.onPressSaveButton(
          state.lumpsum,
          state.secureLumpsum,
          state.growthLumpsum,
          state.sipTillAge,
          state.sipGrowth
        );
      }
    } else {
      const isValid = state.sipTillAge >= props.currentAge && state.sipTillAge < props.lifeexpectancy;
      if (isValid) {
        if (props.onPressSaveButton) {
          props.onPressSaveButton(state.sip, state.secureSip, state.growthSip, state.sipTillAge, state.sipGrowth);
        }
      } else {
        showToast(`SIP till age is greater than your current age (${props.currentAge}) and less than your life expectancy(${props.lifeexpectancy})`)
      }
    }
  };

  const onChangeAge = (value) => {
    setState((prevState) => ({
      ...prevState,
      sipTillAge: Number(value)
    }));
  }

  const onChangeGrowth = (value) => {
    setState((prevState) => ({
      ...prevState,
      sipGrowth: value
    }));
  }

  const hideToast = () => {
    setState((prevState) => ({
      ...prevState,
      toastMessage: "",
      showToast: false,
    }));
  };

  const showToast = (toastMessage) => {
    setState((prevState) => ({
      ...prevState,
      toastMessage,
      showToast: true,
      showProgress: false,
    }));
    setTimeout(() => {
      hideToast();
    }, 3000);
  };


  const renderTitleView = () => {
    const title = props.isLumpsumView
      ? t("CashLadder: Edit Lumpsum")
      : t("CashLadder: Edit Monthly SIP");
    return (
      <div className={"cem_titleView"}>
        <label className={"cem_titleStyle"}>{title}</label>
        <Close className={"cem_iconStyle"} onClick={onClickClose} />
      </div>
    );
  };

  const renderInputView = (
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    style = {},
    type
  ) => {
    return (
      <div className="cem_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          boxStyle={style}
          type={type}
        />
      </div>
    );
  };

  const renderLumpsumInputsView = () => {
    return (
      <div className={"cem_inputsView"}>
        {renderInputView(
          t("CashLadder: Total Lumpsum"),
          state.lumpsum,
          onChangeTotalLumpsum,
          true
        )}
        <label className={"cem_extraTextStyle"}>{" = "}</label>
        {renderInputView(
          t("CashLadder: Secure Lumpsum"),
          state.secureLumpsum,
          onChangeSecureLumpsum,
          true
        )}
        <label className={"cem_extraTextStyle"}>{" + "}</label>
        {renderInputView(
          t("CashLadder: Growth Lumpsum"),
          state.growthLumpsum,
          () => { },
          true,
          false,
          {
            background: "rgba(239, 183, 65, 0.07)",
            borderColor: "#EFB741",
          }
        )}
      </div>
    );
  }

  const renderLumpsumContentView = () => {
    return (
      <div className={"cem_contentView"}>
        {renderLumpsumInputsView()}
      </div>
    );
  };

  const renderSipInputsView = () => {
    return (
      <div className={"cem_inputsView"}>
        {renderInputView(
          t("CashLadder: Total SIP"),
          state.sip,
          onChangeTotalSIP,
          true
        )}
        <label className={"cem_extraTextStyle"}>{" = "}</label>
        {renderInputView(
          t("CashLadder: Secure SIP"),
          state.secureSip,
          onChangeSecureSIP,
          true
        )}
        <label className={"cem_extraTextStyle"}>{" + "}</label>
        {renderInputView(
          t("CashLadder: Growth SIP"),
          state.growthSip,
          () => { },
          true,
          false,
          {
            background: "rgba(239, 183, 65, 0.07)",
            borderColor: "#EFB741",
          }
        )}
      </div>
    );
  }

  const renderSipAgeNGrowthView = () => {
    const label = `* make sure it's greater than your current age (${props.currentAge}) and less than your life expectancy(${props.lifeexpectancy})`
    return (
      <div className="cem_ageNgrowthView">
        <div className="cem_ageView">
          {renderInputView(
            "SIP till age",
            state.sipTillAge,
            onChangeAge,
            false,
            false,
            {},
            'number'
          )}
          <label className="cem_label_text">{label}</label>
        </div>
        <div className="cem_extraTextStyle"></div>
        <div className="cem_ageView">
          {renderInputView(
            "SIP Growth",
            state.sipGrowth,
            onChangeGrowth,
            false,
            true,
          )}
        </div>
      </div>
    );
  }

  const renderSIPContentView = () => {
    return (
      <div className={"cem_contentView"}>
        {renderSipInputsView()}
        {renderSipAgeNGrowthView()}
      </div>
    );
  };

  const renderContentView = () => {
    return props.isLumpsumView
      ? renderLumpsumContentView()
      : renderSIPContentView();
  };

  const renderSaveButtonView = () => {
    return (
      <div className={"cem_buttonView"}>
        <Button
          buttonName={t("CashLadder: Update")}
          onPressButton={onPressSaveButton}
        />
      </div>
    );
  };

  const renderToastView = () => {
    return (
      <Toast showToast={state.showToast} toastMessage={state.toastMessage} />
    );
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      className={"cem_modal_view"}
    >
      <div className={"cem_modal_content"}>
        {renderTitleView()}
        {renderContentView()}
        {renderSaveButtonView()}
        {renderToastView()}
      </div>
    </Modal>
  );
};

export default CashLadderEditModal;
