import React from "react";
import './cashladderdetailstable.css';
import { Table } from "rsuite";
import { currencyFormat } from "../../common";
const { Column, ColumnGroup, HeaderCell, Cell } = Table;

const CashLadderDetailsTable = (props) => {
  const dataArray = props.data;

  const displayDataValue = (value) => {
    let displayValue = ' ';
    if (typeof value === 'string') {
      displayValue = currencyFormat(Number(value));
    } else {
      displayValue = value;
    }

    return displayValue;
  }

  return (
    <Table
      bordered
      cellBordered
      fillHeight
      height={props.tableHeight ? props.tableHeight : 550}
      headerHeight={80}
      data={dataArray}
    >
      if(props.columnGroups){
        props.columnGroups?.map((group, index) => (
          group?.columns?.length > 0 ?
            <ColumnGroup key={index} header={group.header} align={group.align}>
              {group.columns?.map((column, colIndex) => (
                <Column
                  key={colIndex}
                  width={column.width}
                  fixed={column.fixed}
                  resizable={column.resizable}
                  fullText={true}
                >
                  <HeaderCell>
                    <div title={column.header} style={{ width: '100%', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                      {column.header}
                    </div>
                  </HeaderCell>
                  {props.isNegativeDataDisplay ?
                    <Cell dataKey={column.dataKey}>
                      {(rowData) => {
                        const displayValue = displayDataValue(rowData[column.dataKey]);
                        return (
                          <span style={{ color: rowData[column.dataKey] < 0 ? 'red' : '' }}>
                            {displayValue}
                          </span>
                        );
                      }}
                    </Cell>
                    :
                    <Cell dataKey={column.dataKey}>
                    </Cell>}
                </Column>
              ))}
            </ColumnGroup>
            :
            <Column
              width={group.width}
              fixed={group.fixed}
              resizable={group.resizable}
              fullText={false}
            >
              <HeaderCell verticalAlign={'middle'}>
                <div title={group.header} style={{ width: '100%', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                  {group.header}
                </div>
              </HeaderCell>
              {props.isNegativeDataDisplay ?
                <Cell dataKey={group.dataKey}>
                  {(rowData) => {
                    const displayValue = displayDataValue(rowData[group.dataKey]);
                    return (
                      <span style={{ color: rowData[group.dataKey] < 0 ? 'red' : '' }}>
                        {displayValue}
                      </span>
                    );
                  }}
                </Cell>
                :
                <Cell dataKey={group.dataKey}>
                </Cell>
              }
            </Column>
        ))
      }else {
        props.columns?.map((column, colIndex) => (
          <Column
            key={colIndex}
            width={column.width}
            fixed={column.fixed}
            resizable={column.resizable}
            fullText={false}
          >
            <HeaderCell verticalAlign={'middle'}>
              <div title={column.header} style={{ width: '100%', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                {column.header}
              </div>
            </HeaderCell>
            <Cell dataKey={column.dataKey}>
            </Cell>
          </Column>
        ))
      }
    </Table>
  );
};

export default CashLadderDetailsTable;
