import React from "react";
import { Modal } from "rsuite";
import "./recordmodal.css";
import Button from "./button";
import CloseIcon from "../../assets/svgs/ic_close.svg";
import FloatingLabelInput from "./floatinglabelinput";

class RecordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  static defaultProps = {
    dialogClassName: "rm_modal_view",
  };

  render() {
    const {
      onPressNo,
      onPressYes,
      onClose,
      title,
      buttonNames,
      open,
      info,
      showInput,
      label,
      value,
      onInputChange,
      children,
      dialogClassName
    } = this.props;

    const isSingleButton = Boolean(buttonNames.one);

    return (
      <Modal open={open} onClose={onClose} dialogClassName={dialogClassName}>
        <div className="rm_title_view">
          <label className="rm_title_style">{title}</label>
          <img
            src={CloseIcon}
            alt={"cancel"}
            className={"rm_cancel_icon"}
            onClick={onClose}
          ></img>
        </div>
        {showInput && this.renderInputView(label, value, onInputChange, true)}
        {info && <p className="rm_info_view">{info}</p>}
        {children && children}
        <div className="rm_buttonView">
          {isSingleButton ? (
            <div className="rm_single_button">
              <Button buttonName={buttonNames.one} onPressButton={onPressYes} />
            </div>
          ) : (
            <>
              <div className="rm_no_button">
                <Button
                  emptyButton={true}
                  buttonName={buttonNames.no}
                  onPressButton={onPressNo}
                />
              </div>
              <div className="rm_yes_button">
                <Button
                  buttonName={buttonNames.yes}
                  onPressButton={onPressYes}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }

  renderInputView = (
    inputLabel,
    inputValue,
    onInputChange,
    showRupeeSymbol = false,
    showPercentage = false,
  ) => {
    return (
      <div className="rm_input_view">
        <FloatingLabelInput
          label={inputLabel}
          value={inputValue}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onInputChange}
        />
      </div>
    );
  };
}

export default RecordModal;