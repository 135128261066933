import React from 'react';
import './slider.css';

function Slider(props) {
    const { value,age, min, max, markStep, handleChange, showAge, showPercentage } = props;
    const marks = [];
    for (let i = min; i <= max; i += markStep) {
        marks.push(
            <div
                key={i}
                className="mark"
                style={{ left: `${((i - min) / (max - min)) * 100}%` }}
            >
                <div className='line-style' />
                <label className='mark-label'>{showPercentage ? i + '%' : i}</label>
            </div>
        );
    }

    function getPosition(value) {
        const newValue = Number((value - min) * 100 / (max - min));
        const newPosition = 10 - (newValue * 0.2);
        return `calc(${newValue}% + (${newPosition}px))`;
    }

    return (
        <div className='slider'>
            <input
                type="range"
                min={min}
                max={max}
                value={value}
                id="slider"
                onChange={handleChange}
                style={{
                    backgroundImage: `linear-gradient(to right, #EFB741 0%, #EFB741 ${((value - min) / (max - min)) * 100}%, #E0E0E0 ${((value - min) / (max - min)) * 100}%, #E0E0E0 100%)`
                }}
            />
            <div
                className={showAge ? "show-age-value" : "show-value"}
                style={{ left: getPosition(value) }}
            >
                {props.renderCustomLabel ? props.renderCustomLabel(value) :
                <label className="current-value">{showPercentage ? value + '%' : value}</label>}
                {showAge && <label className="current-age">{" Age: " + age}</label>}
            </div>
            <div className='range'>
                {marks}
            </div>
        </div>
    );
}

export default Slider;