import React, { Component } from "react";
import "./vehicleloandashboard.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  Header,
  TitleView,
  Toast,
  ProgressDialog,
  CardDetailContainer,
  SideModel,
} from "../../components";
import * as Bl from "../../../businesslogic";
import {
  getFormatAmount,
  getLiabilityPathName,
  getUserId,
} from "../../common";
import { LIABILITIES_CATEGORY } from "../../constants";
import * as logger from "../../utils/logger/index";

class VehicleLoanDashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liabilityData: [],
    };
    this.isRenderingAlready = false;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getUserLiabilityByCategory();
    }
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }
  
  onPressAddMoreView() {
    this.props.navigate("/vehicleloanhome");
  }

  onPressFinishButton() {
    this.props.navigate("/financialdetails");
  }

  onClickEditIcon = (selectedItem) => {
    this.props.navigate("/vehicleloandetail", {
      state: { liabilityData: selectedItem.item, isEditView: true },
    });
  };

  onPressNo = () => {
    //To Do implement the function
  };

  onPressYes = (selectedItem) => {
    this.deleteLiability(selectedItem.item);
  };

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  getTotalBalance(list) {
    const totalArray = [];
    list.forEach((item) => {
      totalArray.push({
        money: item.currentvalue,
      });
    });
    const total = totalArray.reduce((a, v) => (a = a + v.money), 0);
    return total;
  }
  deleteLiability(item) {
    const liabilityObj = new Bl.Liability(item);
    this.setState({ showProgress: true }, () => {
      liabilityObj
        .deleteLiability()
        .then((response) => {
          const liabilityData = this.state.liabilityData;
          const updatedLiabilityData = liabilityData.filter(
            (liability) => liability.id !== item.id
          );
          this.setState({
            liabilityData: updatedLiabilityData,
            showProgress: false,
          });
        })
        .catch((error) => {
          logger.error({error})
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }
  getUserLiabilityByCategory() {
    const data = {
      uuid: getUserId(),
      category: LIABILITIES_CATEGORY.VEHICLE_LOAN,
    };
    this.setState({ showProgress: true }, () => {
      Bl.Liability.getUserLiabilityByCategory(data)
        .then((list) => {
          this.setState({ showProgress: false, liabilityData: list });
        })
        .catch((error) => {
          logger.error({error})
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  prepareCardData(list) {
    const displayData = [];
    list.forEach((item) => {
      displayData.push({
        title: item.description,
        currencyLabel: "Current O/S Balance",
        money: item.currentvalue,
        period: item.maturityyear,
        item: item,
      });
    });
    return displayData;
  }
 

  renderNavView() {
    const items = getLiabilityPathName("VehicleLoan");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("vehiTitle");
    return <TitleView title={title} information={""} />;
  }

  renderExpectedValueView() {
    const totalBalance = this.getTotalBalance(this.state.liabilityData);
    const displayAmount = getFormatAmount(totalBalance);
    return (
      <div className="vdb_expect_value_view">
        <label className="vdb_value_text">
          {t("vehiTotalValue")}: ₹ {displayAmount}
        </label>
      </div>
    );
  }

  renderCardView() {
    const cardData = this.prepareCardData(this.state.liabilityData);

    return (
      <div className="vdb_card_view">
        {cardData.map((card, index) => (
          <CardDetailContainer
            key={index}
            title={card.title}
            currentValue={card.currencyLabel}
            money={card.money}
            period={card.period}
            extraContent={card.extraContent}
            onClickEdit={() => this.onClickEditIcon(card)}
            onPressNo={this.onPressNo.bind(this)}
            onPressYes={() => this.onPressYes(card)}
          />
        ))}
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="vdb_content_view">
        {this.renderTitleView()}
        {this.renderExpectedValueView()}
        {this.renderCardView()}
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="vdb_bottomView">
        <div className="vdb_buttonView">
          <div className="vdb_addmore_button">
            <Button
              buttonName={t("vehiDBButton1")}
              emptyButton={true}
              onPressButton={this.onPressAddMoreView.bind(this)}
            />
          </div>
          <div className="vdb_save">
            <Button
              buttonName={t("vehiDBButton2")}
              onPressButton={this.onPressFinishButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="vdb_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default VehicleLoanDashBoard;
