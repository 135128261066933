import React from "react";
import "./rentalhome.css";
import { t } from "i18next";
import { Header, TitleView, Button, BreadCrumb, SideModel } from "../../components";
import rentalImage from "../../../assets/svgs/postretmt/rentalincome.svg";
import * as Bl from "../../../businesslogic";
import {
  INCOME_CATEGORY,
  INCOME_FREQUENCY_TYPE,
  INCOME_TYPE,
} from "../../constants";
import { getIncomePathName, getUserId } from "../../common";

const newIncome = {
  incomecategory: INCOME_CATEGORY.RENTAL,
  userid: '',
  frequencytype: INCOME_FREQUENCY_TYPE.YEARLY,
  incomefrequency: 1,
  incomeamount: 0,
  incomestartyear: 0,
  incomeendyear: 0,
  incometype: INCOME_TYPE.RECURRING_PERPETUAL,
  incomegrowthrate: 0,
  incomedescription: "",
  incomestartage: 0,
  incomeendage: 0,
};
class RentalHomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newIncomeData: new Bl.Income({ ...newIncome, userid: getUserId() }),
      incomeData: [],
    };
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressAddButton() {
    this.props.navigate("/rentaldetails", {
      state: { incomeData: this.state.newIncomeData, isEditView: false },
    });
  }



  renderNavView() {
    const items = getIncomePathName("Rental");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("rentalTitle");
    return <TitleView title={title} />;
  }

  renderImage() {
    return <img src={rentalImage} alt="Rental" className="rhp_image" />;
  }

  renderDetails() {
    return <span className="rhp_details_text">{t("rentalInfo")}</span>;
  }

  renderButton() {
    return (
      <div id="rhp_button_view">
        <Button
          buttonName={t("rentalButton")}
          onPressButton={this.onPressAddButton.bind(this)}
        />
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  render() {
    return (
      <div id="rhp_container">
        {this.renderNavView()}
        <div id="rhp_inner_container">
          {this.renderTitleView()}
          {this.renderImage()}
          {this.renderDetails()}
          {this.renderButton()}
          {/* {this.renderDrawerContent()} */}
        </div>
      </div>
    );
  }
}
export default RentalHomePage;
