import * as Bl from "../../businesslogic";
import Global from "./global";
import * as logger from ".././utils/logger/index";

export function getGoalConfigByCategory(goalCategory, configList) {
  let inflationRate = 0;
  switch (goalCategory) {
    case "HLTHC":
      inflationRate = configList.healthinflation;
      break;
    case "KGEDU":
      inflationRate = configList.educationinflation;
      break;
    case "VACCN":
      inflationRate = configList.vacationinflation;
      break;
    case "VEHCL":
      inflationRate = configList.generalinflation;
      break;
    case "REALE":
      inflationRate = configList.realestateror;
      break;
    case "KGWED":
      inflationRate = configList.weddinginflation;
      break;
    case "CUSTG":
      inflationRate = configList.generalinflation;
      break;
    default:
      break;
  }
  return inflationRate;
}

export function getGoalConfigInfo(goalCategory, forceGetFromDb = false) {
  return new Promise((resolve, reject) => {
    //Check the config data is available in Global else get from API
    if (Global?.commonConfigInfo?.length > 0 && !forceGetFromDb) {
      const goalConfig = getGoalConfigByCategory(goalCategory, Global.commonConfigInfo[0]);
      const resultObj = { inflationrate: goalConfig };
      if (resultObj) {
        resultObj.inflationrate = (resultObj.inflationrate * 100).toFixed(2);
      }
      resolve(resultObj);
    } else {
      Bl.Common.getCommonConfigInfo()
        .then((configList) => {
          Global.commonConfigInfo = configList;
          const goalConfig = getGoalConfigByCategory(goalCategory, configList[0]);
          const resultObj = { inflationrate: goalConfig };
          if (resultObj) {
            resultObj.inflationrate = (resultObj.inflationrate * 100).toFixed(2);
          }
          resolve(resultObj);
        })
        .catch((error) => {
          logger.error({ error });
          reject(error);
        });
    }
  });
}

export function getCommonConfigInfo(forceGetFromDb = false) {
  return new Promise((resolve, reject) => {
    //Check the config data is available in Global else get from API
    if (Global?.commonConfigInfo?.length > 0 && !forceGetFromDb) {
      resolve(Global.commonConfigInfo);
    } else {
      Bl.Common.getCommonConfigInfo()
        .then((configList) => {
          Global.commonConfigInfo = configList;
          resolve(configList);
        })
        .catch((error) => {
          logger.error({ error });
          reject(error);
        });
    }
  });
}
export function getCurrentAge(userDOBDetails, forceGetFromDb = false) {
  return new Promise((resolve, reject) => {
    Bl.Common.getCurrentAge(userDOBDetails)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        logger.error({ error });
        reject(error);
      });
  });
}
