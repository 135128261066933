import React, { Component } from 'react';
import { t } from "i18next";
import './financiallandingpage.css';
import { AlertNotify, BreadCrumb, DisplayAlertView, GoalsProcess } from './components';
import RetirementPlan from '../assets/svgs/retirement_icon.svg';
import RoundInlineIcon from '../assets/svgs/goals_landing/Inlinecircle.svg'
import FinancialDetail from '../assets/svgs/gl_FinancialDetails.svg';
import RiskAssessment from '../assets/svgs/gl_RiskAssessment.svg';
import AssetAllocation from '../assets/svgs/gl_AssetAllocation.svg';
import PortfolioConstuction from '../assets/svgs/gl_portfolio.svg';
import { getGoalsPathName, getUserDetails, getUserId, getUserRole, isRestrictedCustomer } from "./common";
import * as Bl from ".././businesslogic";


class FinancialLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActiveUser: false,
      userData: {},
    };
    this.loggedInUser = getUserDetails();
    this.userRole = getUserRole();
    this.userId = getUserId();
  }

  componentDidMount() {
    this.checkIsDataAlreadyAvail();
    this.getUserData();
  }

  onClickBackIcon(path) {
    this.props.navigate(path);
  }

  onClickGoalsStart = async (goalurl, categorizeurl) => {
    if (!this.state.userData?.iscashladderupdated) {
      const available = await this.GoalsAlreadyAvailable();
      if (available) {
        this.props.navigate(categorizeurl);
      } else {
        this.props.navigate(goalurl);
      }
    }
  }

  onClickFinancialStart() {
    if (!this.state.userData?.iscashladderupdated) {
      this.props.navigate('/financialdetails')
    }
  }

  onClickRiskStart() {
    if (isRestrictedCustomer()) {
      this.props.navigate('/riskprofilehome')
    } else {
      this.props.navigate('/adminriskprofile')
    }
  }

  onClickAssetAllocation() {
    if (isRestrictedCustomer()) {
      AlertNotify.info('Admins only have the permission to access these steps')
    } else if (!this.state.userData?.isassetallocated) {
      this.props.navigate('/assetallocdetails')
    } else {
      AlertNotify.info('Asset Allocation already locked')
    }
  }

  onClickPortfolio() {
    if (isRestrictedCustomer()) {
      AlertNotify.info('Admins only have the permission to access these steps')
    } else if (!this.state.userData?.intiateportfolioallocation) {
      AlertNotify.info("Please accept the Cashladder to do portfolio construction")
    } else if (this.state.userData?.intiateportfolioallocation) {
      this.props.navigate('/portfolioassetallocation')
    }
  }

  onClickGenerateCashladder() {
    this.props.navigate('/cashladder')
  }

  onClickViewCustomer() {
    this.props.navigate('/dashboard')
  }

  onPressUpdateCashladder() {
    this.getUserData();
  }

  async GoalsAlreadyAvailable() {
    const data = {
      uuid: this.userId,
    };
    const list = await Bl.Goals.getGoalsByUser(data)
    return list.length > 0;
  }

  async FinancialDetailsAlreadyAvailable() {
    const data = {
      uuid: this.userId,
    };
    const list = await Bl.Networth.getNetworthByUserId(data)
    return list.length > 0;
  }

  async RiskProfileAlreadyCreated() {
    const data = {
      uuid: this.userId,
    };
    const result = await Bl.RiskProfile.getUserRiskProfile(data);
    return result;
  }

  async checkIsDataAlreadyAvail() {
    let isGoalAlreadyAvail = false;
    let isFinancialAlreadyAvail = false;
    let isRiskProfileAlreadyAvail = false;

    try {
      isGoalAlreadyAvail = await this.GoalsAlreadyAvailable();
    } catch (error) {
      console.log('isGoalAlreadyAvail:', error)
    }

    try {
      isFinancialAlreadyAvail = await this.FinancialDetailsAlreadyAvailable();
    } catch (error) {
      console.log('isFinancialAlreadyAvail:', error)
    }

    try {
      isRiskProfileAlreadyAvail = await this.RiskProfileAlreadyCreated();
    } catch (error) {
      console.log('isRiskProfileAlreadyAvail:', error)
    }

    this.setState({ isGoalAlreadyAvail, isFinancialAlreadyAvail, isRiskProfileAlreadyAvail })
  }

  getUserData() {
    const userData = getUserDetails();
    this.setState({ userData, isActiveUser: userData.status })
  }

  renderNavView() {
    const items = getGoalsPathName("FinancialPlan");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={this.onClickBackIcon.bind(this)}
      />
    );
  }

  renderTitleView() {
    const information = " ";
    const title = 'Start your Financial Journey';
    return (
      <div className='fl_title_view'>
        <div className='fl_title_desc_view'>
          <label className="fl_titleStyle">{title}</label>
          <p className="fl_infoStyle">{information}</p>
        </div>
        <img
          src={RetirementPlan}
          alt={"Info Icon"}
          className='fl_retirement_icon'
        />
      </div>
    );
  }

  renderGoalsAndExpenseProgress(props) {
    const progress = {
      icon: RoundInlineIcon,
      title: "Goals And Expenses",
      info: "",
      priorityNo: "01",
      fill: "#EFB741"
    }
    return (
      <GoalsProcess
        progress={progress}
        buttonName={this.state.isGoalAlreadyAvail ? t('Continue') : 'Start'}
        showStartButton={false}
        onClickItem={() => {
          this.onClickGoalsStart(props.goalurl, props.categorizeurl)
        }}
      />
    )
  }

  renderFinancialProgress() {
    const progress = {
      icon: FinancialDetail,
      title: "Financial Details",
      info: "",
      priorityNo: "02",
      fill: "#51668A"
    }
    return (
      <GoalsProcess
        progress={progress}
        buttonName={this.state.isFinancialAlreadyAvail ? t('Continue') : 'Start'}
        showStartButton={false}
        onClickItem={this.onClickFinancialStart.bind(this)}
      />
    )
  }

  renderRiskAssessmentProgress() {
    const progress = {
      icon: RiskAssessment,
      title: "Risk Assesment",
      info: "",
      priorityNo: "03",
      fill: "#0B2653"
    }
    return (
      <GoalsProcess
        progress={progress}
        buttonName={this.state.isRiskProfileAlreadyAvail ? t('Continue') : 'Start'}
        showStartButton={false}
        onClickItem={this.onClickRiskStart.bind(this)}
      />
    )
  }

  rendeAssetAllocationProgress() {
    const progress = {
      icon: AssetAllocation,
      title: "Asset Allocation & Cashladder",
      info: "",
      priorityNo: "04",
      fill: "#4081BD"
    }
    return (
      <GoalsProcess progress={progress} showStartButton={false} onClickItem={this.onClickAssetAllocation.bind(this)} />
    )
  }

  renderPortfolioConstuctionProgress() {
    const progress = {
      icon: PortfolioConstuction,
      title: "Portfolio Constuction ",
      info: "",
      priorityNo: "05",
      fill: "#A85CD7"
    }
    return (
      <GoalsProcess progress={progress} showStartButton={false} onClickItem={this.onClickPortfolio.bind(this)} />
    )
  }

  renderDisplayMsgView() {
    return (
      <div className='fl_alertView'>
        <DisplayAlertView
          onPressYes={this.onPressUpdateCashladder.bind(this)}
        />
      </div>
    );
  }

  renderLinksView() {
    const userData = this.state?.userData;
    const isGeneralUser = isRestrictedCustomer();
    return (
      <div className='fl_linkView'>
        {userData?.iscashladderupdated && this.renderDisplayMsgView()}
        {!isGeneralUser && userData?.isassetallocated &&
          <label
            className='fl_labelText'
            onClick={this.onClickGenerateCashladder.bind(this)}
          >
            {t("Generate Cash Ladder")}
          </label>}
        <label
          className='fl_labelText'
          onClick={this.onClickViewCustomer.bind(this)}
        >
          {t("View Customer Dashboard")}
        </label>
      </div>
    );
  }

  renderGoalsProgress() {
    return (
      <div className='fl_gp_view'>
        <div className='fl_titleView'>
          <label className='fl_gp_text'>{t("Let’s plan Step by step")}</label>
          {this.renderLinksView()}
        </div>
        <div className='fl_gp_content'>
          {this.renderGoalsAndExpenseProgress({
            goalurl: "/goals",
            categorizeurl: "/goalscategorize"
          })}
          {this.renderFinancialProgress()}
          {this.renderRiskAssessmentProgress()}
          <div className='fl_sepearatorView' />
          {this.rendeAssetAllocationProgress()}
          {this.renderPortfolioConstuctionProgress()}
        </div>
      </div>
    )
  }

  renderContentView() {
    return (
      <div className='fl_contentView'>
        {this.renderTitleView()}
        {this.renderGoalsProgress()}
      </div>
    );
  }

  render() {
    return (
      <div className='fl_container'>
        {this.renderNavView()}
        {this.renderContentView()}
      </div>
    );
  }
}

export default FinancialLandingPage;
