import { useState } from "react";

import "./collapseablemodal.css";
import { ReactComponent as ExpandMore } from "../../assets/svgs/expandmore.svg";

const CollapseableModal = ({
  className,
  renderContent,
  renderCollapseOpen,
  renderCollapseClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickArrow = () => {
    setIsOpen(!isOpen);
  };

  const OpenIcon = () => {
    return <ExpandMore />;
  };

  const CloseIcon = () => {
    return (
      <div className="openclose-icon-view">
        <ExpandMore />
      </div>
    );
  };

  return (
    <div className={`collapseable-container ${isOpen ? "" : "open"}`}>
      <div onClick={onClickArrow}>
        {isOpen ? (
          renderCollapseClose ? (
            renderCollapseClose()
          ) : (
            <CloseIcon />
          )
        ) : renderCollapseOpen ? (
          renderCollapseOpen()
        ) : (
          <OpenIcon />
        )}
      </div>
      <div className={"collapseable-view"}>
        <div className={`collapseable-content ${className}`}>
          {renderContent ? renderContent() : <></>}
        </div>
      </div>
    </div>
  );
};

export default CollapseableModal;
