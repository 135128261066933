import React, { Component } from "react";
import {
  Button,
  Header,
  BreadCrumb,
  TitleView,
  GoalsCategoryCard,
  Toast,
  ProgressDialog,
} from "../components";
import { t } from "i18next";
import "./goalscategorizepage.css";
import { Popover, Whisper } from "rsuite";
import InfoIcon from "../../assets/svgs/ic_info.svg";
import * as Bl from "../../businesslogic";
import {
  getCardDetailsByCategory,
  getFormatAmount,
  getGoalsPathName,
  getUserId,
} from "../common";
import { GOAL_BUCKET_CODE, GOAL_CATEGORY } from "../constants";

class GoalsCategorizePage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const prevPath = params?.path ? params.path : "";

    this.state = {
      uncategorizedGoals: [],
      needs: [],
      wants: [],
      desires: [],
      prevPath
    };
    this.viewRef = null;
    this.isRenderingAlready = false;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getGoalsByUser();
    }
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressAddMoreGoals() {
    this.updateGoalItems(this.navigateToGoalsLandingPage.bind(this));
  }

  onPressFinish() {
    const isGoalsCategorized = this.state?.uncategorizedGoals?.length > 0;
    if (isGoalsCategorized) {
      this.showToast(t("alluncategorizedgoalsneedstobemovedtocategorizedgoals"));
    } else {
      this.updateGoalItems(this.navigateToFinancialLandingPage.bind(this));
    }
  }

  onPressDeleteIcon(item) {
    this.deleteGoalItem(item);
  }

  onPressEditIcon(item) {
    const path = this.getNavigationPath(item.goalcategory);
    const goalItem = new Bl.Goals(item);
    console.log('Edit Item',goalItem)
    this.props.navigate(path, {
      state: {
        goalData: goalItem,
        isEditView: true,
      },
    });
  }

  onDragStartInUncategorized(event, item) {
    if (this.viewRef) {
      this.viewRef.scroll({
        top: 200,
        left: 0,
        behavior: "smooth",
      });
    }

    event.dataTransfer.setData("item", JSON.stringify(item));
    event.dataTransfer.setData("source", "Uncategorized");
  }

  onDragStartInList(event, source, item) {
    event.dataTransfer.setData("item", JSON.stringify(item));
    event.dataTransfer.setData("source", source);
  }

  onDragOverInList(e) {
    e.preventDefault();
  }

  onDropInList(event, list, destination) {
    const item = JSON.parse(event.dataTransfer.getData("item"));
    const source = event.dataTransfer.getData("source");
    const sourceArray = this.getListName(source);
    const destinationArray = this.getListName(destination);
    if (sourceArray !== destinationArray) {
      //Remove Item from Source List
      let sourceList = this.getList(source);
      const newSourceList = sourceList.filter((el) => el.id !== item.id);

      //Add Item into Destination List
      list.push(item);

      //Update lists
      this.setState({ [sourceArray]: newSourceList, [destinationArray]: list });
    } else {
      //duplicate items
      let newItems = [...list];

      //remove and save the dragged item content
      const draggedItemContent = newItems.splice(this.dragItem, 1)[0];

      //switch the position
      newItems.splice(this.dragOverItem, 0, draggedItemContent);

      //reset the position
      this.dragItem = null;
      this.dragOverItem = null;

      //update list
      this.setState({ [destinationArray]: newItems });
    }
  }

  getList(name) {
    switch (name) {
      case GOAL_BUCKET_CODE.NEEDS:
        return this.state.needs;
      case GOAL_BUCKET_CODE.WANTS:
        return this.state.wants;
      case GOAL_BUCKET_CODE.DESIRES:
        return this.state.desires;
      default:
        return this.state.uncategorizedGoals;
    }
  }

  getListName(name) {
    switch (name) {
      case GOAL_BUCKET_CODE.NEEDS:
        return "needs";
      case GOAL_BUCKET_CODE.WANTS:
        return "wants";
      case GOAL_BUCKET_CODE.DESIRES:
        return "desires";
      default:
        return "uncategorizedGoals";
    }
  }

  getNavigationPath(category) {
    switch (category) {
      case GOAL_CATEGORY.RETIREMENT:
        return '/retirement';
      case GOAL_CATEGORY.HEALTH_CARE:
        return "/healthcare";
      case GOAL_CATEGORY.GEN_WEALTH:
        return "/wealthtransfer";
      case GOAL_CATEGORY.EDUCATION:
        return "/education";
      case GOAL_CATEGORY.VACATION:
        return "/vacation";
      case GOAL_CATEGORY.VEHICLE:
        return "/vehicle";
      case GOAL_CATEGORY.REAL_ESTATE:
        return "/realestate";
      case GOAL_CATEGORY.WEDDING:
        return "/wedding";
      case GOAL_CATEGORY.EMI_PREMIUM:
        return "/emi";
      case GOAL_CATEGORY.CUSTOM_GOAL:
        return "/customgoal";
      default:
        return "/goals";
    }
  }

  getGoalsByUser() {
    const data = {
      uuid: getUserId(),
    };
    this.setState({ showProgress: true }, () => {
      Bl.Goals.getGoalsByUser(data)
        .then((list) => {
          const goals = this.prepareGoalsByType(list);
          const dbValue = JSON.parse(JSON.stringify(goals));
          this.setState({
            dbValue,
            showProgress: false,
            needs: goals.sortedNeeds,
            wants: goals.sortedWants,
            desires: goals.sortedDesires,
            uncategorizedGoals: goals.uncategorizedGoals,
          });
        })
        .catch((error) => {
          console.log("GoalsCategorizePage/getGoalsByUser Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  updateGoalItems(callbackFn) {
    const updateList = this.getUpdateList();
    if (updateList?.length > 0) {
      const updatePromises = [];
      // add promise for each item
      updateList.forEach((goal) => {
        const data = {
          id: goal.id,
          goalbucketcode: goal.goalbucketcode,
          goalpriority: goal.goalpriority,
        };
        updatePromises.push(Bl.Goals.updateCategoryStatus(data));
      });

      this.setState({ showProgress: true }, () => {
        Promise.allSettled(updatePromises)
          .then((result) => {
            //Promise.allSettled returns all item status, if all success(fulfilled), do next action
            //if any one get reject display the toast and stay in the same page
            const rejectedApis = result.filter((e) => e.status === "rejected");
            this.setState({ showProgress: false }, () => {
              if (rejectedApis?.length > 0) {
                this.showToast(t("someErrorHasOccured"));
              } else {
                callbackFn();
              }
            });
          })
          .catch((error) => {
            console.log("Promise All settled error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      callbackFn();
    }
  }

  deleteGoalItem(item) {
    const goalObj = new Bl.Goals(item);
    this.setState({ showProgress: true }, () => {
      goalObj
        .deleteGoal()
        .then(() => {
          //Remove deleted item from local arrays
          const localArray = this.getListName(item.goalbucketcode);
          let localList = this.getList(item.goalbucketcode);
          const newLocalList = localList.filter((el) => el.id !== item.id);

          this.setState({ [localArray]: newLocalList, showProgress: false });
        })
        .catch((error) => {
          console.log("GoalCategorizePage/deleteGoalItem Error:", error);
          this.showToast(t("someErrorHasOccurred"));
        });
    });
  }

  getModifiedList(dbList, localList) {
    let updateList = [];

    if (dbList.length === 0 && localList.length > 0) {
      // If the dbList is empty, use the localList as the updateList
      updateList = localList;
    } else {
      // If the length of the two arrays is different, compare the objects
      const modifiedObjects = localList.filter((localObj) => {
        const dbObj = dbList.find((dbObj) => dbObj.id === localObj.id);
        return JSON.stringify(localObj) !== JSON.stringify(dbObj);
      });
      updateList = modifiedObjects;
    }

    return updateList;
  }

  getUpdateList() {
    const dbValue = this.state.dbValue;
    let updateList = [];
    const modifiedNeedsList = this.getModifiedList(
      dbValue.sortedNeeds,
      this.state.needs
    );

    updateList.push(...modifiedNeedsList);

    const modifiedWantsList = this.getModifiedList(
      dbValue.sortedWants,
      this.state.wants
    );

    updateList.push(...modifiedWantsList);

    const modifiedDesiresList = this.getModifiedList(
      dbValue.sortedDesires,
      this.state.desires
    );

    updateList.push(...modifiedDesiresList);
    return updateList;
  }

  prepareGoalsByType(list) {
    const needs = [];
    const wants = [];
    const desires = [];
    const uncategorizedGoals = [];
    let sortedNeeds = [];
    let sortedWants = [];
    let sortedDesires = [];
    for (let index = 0; index < list.length; index++) {
      const item = list[index];
      if (item.goalbucketcode === GOAL_BUCKET_CODE.NEEDS) {
        needs.push(item);
      } else if (item.goalbucketcode === GOAL_BUCKET_CODE.WANTS) {
        wants.push(item);
      } else if (item.goalbucketcode === GOAL_BUCKET_CODE.DESIRES) {
        desires.push(item);
      } else {
        uncategorizedGoals.push(item);
      }
    }

    if (needs.length > 0) {
      sortedNeeds = this.sortGoalsByGoalPriority(needs);
    }

    if (wants.length > 0) {
      sortedWants = this.sortGoalsByGoalPriority(wants);
    }

    if (desires.length > 0) {
      sortedDesires = this.sortGoalsByGoalPriority(desires);
    }

    return { sortedNeeds, sortedWants, sortedDesires, uncategorizedGoals };
  }

  prepareCardDetail(goal) {
    const cardDetail = getCardDetailsByCategory(goal.goalcategory);
    return cardDetail;
  }

  prepareTotalAmount(list) {
    let total = 0;
    list.forEach((item) => {
      total += item.goalamountpv;
    });
    return getFormatAmount(total);
  }

  sortGoalsByGoalPriority(goals) {
    const sortedGoals = goals.sort((a, b) => {
      if (a.goalpriority === b.goalpriority) {
        return a > b ? -1 : 1;
      } else {
        return a.goalpriority < b.goalpriority ? -1 : 1;
      }
    });

    return sortedGoals;
  }

  navigateToFinancialLandingPage() {
    this.props.navigate("/financial");
  }

  navigateToGoalsLandingPage() {
    this.props.navigate("/goals");
  }

  hidePopOver() {
    if (this.infoViewRef) {
      this.infoViewRef.close();
    }
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  renderTitleView() {
    const information = " ";
    const title = t("completeYourFinancialPlan");
    return <TitleView title={title} information={information} />;
  }

  renderNavView() {
    const items = getGoalsPathName(this.state.prevPath);
    return (
      <BreadCrumb
        items={items}
        currentPath={this.state.prevPath}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderGoalsCategoryCardView(goal, index) {
    const cardDetail = this.prepareCardDetail(goal);
    cardDetail.description = goal.goaldescription;
    return (
      <GoalsCategoryCard
        draggable={true}
        category={cardDetail}
        showAmount={false}
        showPriority={false}
        showDelete={true}
        onDragStart={(event) => {
          this.onDragStartInUncategorized(event, goal);
        }}
        onPressYes={() => {
          this.onPressDeleteIcon(goal);
        }}
        onPressEdit={() => {
          this.onPressEditIcon(goal);
        }}
      />
    );
  }

  renderUnCategorizedListView() {
    const uncategorizedGoals = this.state.uncategorizedGoals;
    return (
      <div className="cg_uncategorizedListView">
        {uncategorizedGoals.map((goal, index) => (
          <div key={index}>{this.renderGoalsCategoryCardView(goal, index)}</div>
        ))}
      </div>
    );
  }

  renderInfoView(information) {
    return (
      <Whisper
        placement="right"
        speaker={(
          <Popover className="cg_info_view" onClose={this.hidePopOver.bind(this)}>
            <label className={"cg_info_data"}>{information}</label>
          </Popover>
        )}
        trigger="click"
        ref={(ref) => (this.infoViewRef = ref)}
      >
        <img src={InfoIcon} alt={"Info Icon"} className={"cg_info_icon"} />
      </Whisper>
    );
  }

  renderUnCategorizedTitleView() {
    const information = t("dragNdropGoalsToYourPriorityBuckets");
    const title = t("uncategorisedGoals");
    return (
      <div className="cg_content_row">
        <label className="cg_categorised_info">{title}</label>
        {/* {this.renderInfoView(information)} */}
        <label className={"cg_info_data"}>{information}</label>
      </div>
    );
  }

  renderUnCategorisedGoals() {
    return (
      <div id={"categorisedView"} className={"cg_content_view"}>
        {this.renderUnCategorizedTitleView()}
        {this.renderUnCategorizedListView()}
      </div>
    );
  }

  renderCategorisedGoals() {
    const title = t("categorisedGoals");
    return (
      <div className="cg_content_view">
        <div className="cg_content_row">
          <div className="cg_categorised_info">{title}</div>
        </div>
        {this.renderDraggableView()}
      </div>
    );
  }

  renderDropBoxView() {
    return (
      <div className={"cg_dropbox_view"}>
        <label className={"cg_drop_text"}>{t("Drop Here")}</label>
      </div>
    );
  }

  renderListCategoryCardView(item, index, source) {
    const category = this.prepareCardDetail(item);
    //add priority, description values in from goalobj category for display
    category.priority =
      item?.goalpriority === index + 1 ? item.goalpriority : index + 1;
    category.description = item.goaldescription;
    //update goalbucketcode and goalpriority in item
    item.goalbucketcode = source;
    item.goalpriority = index + 1;
    return (
      <GoalsCategoryCard
        draggable={true}
        category={category}
        showAmount={false}
        showPriority={true}
        showDelete={true}
        onDragStart={(event) => {
          this.onDragStartInList(event, source, item);
        }}
        onPressYes={() => {
          this.onPressDeleteIcon(item);
        }}
        onPressEdit={() => {
          this.onPressEditIcon(item);
        }}
      />
    );
  }

  renderListView(list, source) {
    return (
      <div className={"cg_listView"}>
        {list.map((item, index) => (
          <div
            key={index}
            onDragStart={() => {
              this.dragItem = index;
            }}
            onDragEnter={() => {
              this.dragOverItem = index;
            }}
          >
            {this.renderListCategoryCardView(item, index, source)}
          </div>
        ))}
        {this.renderDropBoxView()}
      </div>
    );
  }

  renderNeedsView() {
    const needs = this.state.needs;
    return (
      <div
        droppable={1}
        onDragOver={this.onDragOverInList.bind(this)}
        onDrop={(e) => {
          this.onDropInList(e, needs, GOAL_BUCKET_CODE.NEEDS);
        }} className={"cg_needs"}>
        <label className="cg_cat_title">{`${t("needs")} (${needs.length
          })`}</label>
        {this.renderListView(needs, GOAL_BUCKET_CODE.NEEDS)}
      </div>
    );
  }

  renderWantsView() {
    const wants = this.state.wants;
    return (
      <div
        droppable={1}
        onDragOver={this.onDragOverInList.bind(this)}
        onDrop={(e) => {
          this.onDropInList(e, wants, GOAL_BUCKET_CODE.WANTS);
        }}
        className={"cg_needs"}>
        <label className="cg_cat_title">{`${t("wants")} (${wants.length
          })`}</label>
        {this.renderListView(wants, GOAL_BUCKET_CODE.WANTS)}
      </div>
    );
  }

  renderDesiresView() {
    const desires = this.state.desires;
    return (
      <div
        droppable={1}
        onDragOver={this.onDragOverInList.bind(this)}
        onDrop={(e) => {
          this.onDropInList(e, desires, GOAL_BUCKET_CODE.DESIRES);
        }}
        className={"cg_needs"}>
        <label className="cg_cat_title">{`${t("desires")} (${desires.length
          })`}</label>
        {this.renderListView(
          desires,
          GOAL_BUCKET_CODE.DESIRES
        )}
      </div>
    );
  }

  renderDraggableView() {
    return (
      <div className="cg_draggable_view">
        {this.renderNeedsView()}
        <div className="cg_separatorView" />
        {this.renderWantsView()}
        <div className="cg_separatorView" />
        {this.renderDesiresView()}
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="cg_bottomView">
        <div className="cg_buttonsView">
          <div className="cg_buttonView">
            <Button
              buttonName={t("addMoreGoals")}
              emptyButton={true}
              onPressButton={this.onPressAddMoreGoals.bind(this)}
            />
          </div>
          <div className="cg_buttonView">
            <Button
              buttonName={t("finish")}
              onPressButton={this.onPressFinish.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderContentView() {
    const showUnCategorizedGoals = this.state?.uncategorizedGoals?.length > 0;
    return (
      <div ref={(ref) => (this.viewRef = ref)} className="cg_content">
        {this.renderTitleView()}
        {showUnCategorizedGoals && this.renderUnCategorisedGoals()}
        {this.renderCategorisedGoals()}
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  render() {
    return (
      <div className="cg_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default GoalsCategorizePage;
