import React, { Component } from "react";
import { Popover, Whisper } from "rsuite";
import EditIcon from "../../assets/svgs/ic_edit.svg";
import DeleteIcon from "../../assets/svgs/ic_delete.svg";
import MoreIcon from "../../assets/svgs/More_Icon.svg";
import "./carddetailcontainer.css";
import RecordModal from "./recordmodal";
import { t } from "i18next";
import { getFormatAmount } from "../common";

class CardDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  onClickEditIcon() {
    this.hidePopOver();
    if (this.props.onClickEdit) {
      this.props.onClickEdit();
    }
  }

  onClickDeleteIcon() {
    this.hidePopOver();
    this.setState({ showModal: true });
  }

  onHideModal = () => {
    this.hidePopOver();
    this.setState({ showModal: false });
  };

  handlePressNo = () => {
    this.onHideModal();
    if (this.props.onPressNo) {
      this.props.onPressNo();
    }
  };

  handlePressYes = () => {
    this.onHideModal();
    if (this.props.onPressYes) {
      this.props.onPressYes();
    }
  };

  hidePopOver() {
    if (this.editViewRef) {
      this.editViewRef.close();
    }
  }

  renderPopOverView(index) {
    return (
      <Popover className="cdc_edit_view" onClose={this.hidePopOver.bind(this)}>
        <div className="cdc_edit_row" onClick={this.onClickEditIcon.bind(this)}>
          <img src={EditIcon} alt={"Edit Icon"} className="cdc_more" />
          <label className="cdc_edit_text">{t('edit')}</label>
        </div>
        <div
          className="cdc_edit_row"
          onClick={this.onClickDeleteIcon.bind(this)}
        >
          <img src={DeleteIcon} alt={"Delete Icon"} className="cdc_more" />
          <label className="cdc_edit_text">{t('delete')}</label>
        </div>
      </Popover>
    );
  }

  renderMoreIconView(index) {
    return (
      <Whisper
        placement="left"
        speaker={this.renderPopOverView()}
        trigger="click"
        ref={(ref) => (this.editViewRef = ref)}
      >
        <img
          src={MoreIcon}
          alt={"More Icon"}
          className="cdc_more"
        />
      </Whisper>
    );
  }

  renderModalView() {
    const { showModal } = this.state;
    const buttonNames = { no: t("no"), yes: t("yes") };
    const title = t("areYouSureYouWantToDeleteTheSelectedRecords?");
    return (
      <RecordModal
        open={showModal}
        onClose={this.onHideModal.bind(this)}
        onPressNo={this.handlePressNo.bind(this)}
        onPressYes={this.handlePressYes.bind(this)}
        title={title}
        buttonNames={buttonNames}
      />
    );
  }

  render() {
    const { title, currentValue, money, extraContent, period, scheme } = this.props;
    const moneyValue = getFormatAmount(money)
    return (
      <div id="cdc_wrapper">
        <div id="cdc_content_view">
          <label className="cdc_title">{title}{scheme && ":" + scheme}</label>
          <label id="cdc_details">{currentValue}: ₹ {moneyValue} {period && " | " + period} </label>
          {extraContent && <label id="cdc_extra_info">{extraContent}</label>}
          <div className="cdc_extra_content"></div>
        </div>
        {this.renderMoreIconView()}
        {this.state.showModal && this.renderModalView()}
      </div >
    );
  }
}

export default CardDetailContainer;
