import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import EditIcon from "../../assets/svgs/ic_edit.svg";
import TickIcon from "../../assets/svgs/ic_tick.svg";
import CloseIcon from "../../assets/svgs/ic_close.svg";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Input } from 'rsuite';
import { SearchOutlined } from '@material-ui/icons';
import { t } from 'i18next';
import "./categorymgmt.css";
import { getFormattedDate, getSelectedUserData, resetSelectedUserDetail, resetUserCashLadderDetail } from "../common";
import { Button, ProgressDialog, Toast } from "../components";
import * as Bl from "../../businesslogic";

const CategoryManagement = (props) => {
  const [selectedRowData, setSelectedRowData] = useState(null);
  const gridRef = useRef(null)
  const isRenderedAlready = useRef(false);

  const [state, setState] = useState({
    isEditableView: false,
    showModal: false,
    rowData: [],
    showNewRow: false,
    gridColumnApi: null,
    editedRowData: null,
    gridApi: null,
    showConfirmationModal: false,
    showProgress: false,
    progressMessage: '',
    showToast: false,
    toastMessage: '',
    modifiedData: []
  })

  useEffect(() => {
    if (!isRenderedAlready.current) {
      isRenderedAlready.current = true;
      //When admin came out from the user screens,remove selectedUserDetail from localstorage
      if (getSelectedUserData()?.id) {
        resetSelectedUserDetail();
        resetUserCashLadderDetail();
      }

      getAllSebiCategoryValues();
    }
  }, []);

  const columnDefs = [
    { headerName: 'Category', field: 'mstarcategory', editable: false },
    {
      headerName: '5 Yr Cat Rate',
      field: 'fiveyearcategoryrate',
      cellRenderer: (params) => renderCatRateDisplayView(params),
      editable: true
    },
    { headerName: 'Last Updated Date', field: 'lastupdate', editable: false },
    {

      headerName: "Action",
      maxWidth: 100,
      cellRenderer: (params) => renderActionColumnView(params),
      editable: false,
      colId: "action"
    },
  ];

  const onRowEditingStarted = (params) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

  const onChangeSearchText = (value) => {
    state.gridApi.setQuickFilter(value)
  }

  const onRowEditingStopped = (params) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

  const onGridReady = (params) => {
    setState((prevState) => ({
      ...prevState,
      gridApi: params.api,
      gridColumnApi: params.columnApi
    }))
    gridRef.current = params.api;
    gridRef.current.sizeColumnsToFit();
  }

  const onClickSaveButton = (params) => {
    params.api.stopEditing();
    const newData = params.node.data;
    newData.fiveyearcategoryrate /= 100;
    newData.lastupdate = getFormattedDate(new Date());
    const modifiedData = state.modifiedData;
    modifiedData.push(newData)
    setState((prevState) => ({ ...prevState, modifiedData, isEditableView: false }))
  }

  const onClickCloseIcon = (params) => {
    params.api.stopEditing(true);
    setState((prevState) => ({ ...prevState, isEditableView: false }))
  }

  const onClickEditIcon = (params) => {
    setSelectedRowData(params.node);
    setState((prevState) => ({ ...prevState, isEditableView: true }))
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      colKey: params.column.colId
    });
  }

  const onPressSubmitButton = () => {
    state.modifiedData.forEach((item, index) => {
      setState((prevState) => ({
        ...prevState,
        showProgress: true
      }));
      Bl.AccountPortfolioRule.updateSebicategoryValues(item).then(response => {

        if (index === state.modifiedData.length - 1) {
          setState((prevState) => ({ ...prevState, showProgress: false, modifiedData: [] }));
          showToast('Category Rate updated for each Categories')
        }
      }).catch(error => {
        console.log('Categorymgmt/ onPressSubmitButton');
        showToast(error.messsage)
      })

    })
  }

  const getAllSebiCategoryValues = () => {
    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }))

    Bl.AccountPortfolioRule.getAllSebiCategoryValues().then(result => {
      const sortedData = result.sort((a, b) => a.mstarcategory?.localeCompare(b.mstarcategory));
      setState((prevState) => ({
        ...prevState,
        rowData: sortedData,
        showProgress: false
      }))
    }).catch((error) => {
      console.log('Categorymgmt/ getAllSebiCategoryValues Error:', error)
      showToast(error.messsage)
    })
  }

  const showToast = (toastMessage) => {
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }

  const renderCatRateDisplayView = (params) => {
    const displayData = Number(params?.data?.fiveyearcategoryrate * 100).toFixed(2);
    return (
      <label className="">
        {displayData}
      </label>
    );
  }

  const renderActionColumnView = (params) => {
    if (state.isEditableView && params.node === selectedRowData) {
      // Render save button for selected row
      return (
        <div className="cam_editNdeleteView">
          <img
            src={TickIcon}
            alt={"Save"}
            title="Save"
            className={"cam_actionIconStyle"}
            onClick={() => onClickSaveButton(params)}
          />
          <img
            src={CloseIcon}
            alt={"Clear"}
            title="Clear"
            className={"cam_actionIconStyle"}
            onClick={() => onClickCloseIcon(params)}
          />
        </div>
      );
    } else {
      // Render edit icon for other rows
      return (
        <div className="cam_editNdeleteView">
          <img
            src={EditIcon}
            alt={"Edit"}
            title="Edit"
            className={"cam_actionIconStyle"}
            onClick={() => onClickEditIcon(params)}
          />
        </div>
      );
    }
  }

  const renderSearchBar = () => {
    return (
      <div className="cam_searchView">
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search"
          onChange={onChangeSearchText}
        />
      </div>
    );
  };

  const renderTitleView = () => {
    return (
      <div className="cam_titleView">
        <div className="cam_lableView" >{t("Sebicategory")}</div>
        {renderSearchBar()}
      </div>
    )
  }

  const renderTabelView = () => {
    return (
      <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          rowData={state.rowData}
          columnDefs={columnDefs}
          editType="fullRow"
          suppressClickEdit={true}
          defaultColDef={{ editable: true }}
          onRowEditingStopped={onRowEditingStopped}
          onRowEditingStarted={onRowEditingStarted}
          onGridReady={onGridReady}
        />
      </div>
    )
  }

  const renderButtonsView = () => {
    return (
      <div className="cam_bottomView">
        <div className="cam_buttonView">
          <Button
            disabled={state.disableSubmit}
            buttonName={t("Submit")}
            onPressButton={onPressSubmitButton}
          />
        </div>
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  return (
    <div className="cam_container">
      {renderTitleView()}
      <div className="cam_content">
        {renderTabelView()}
      </div>
      {renderButtonsView()}
      {state.showToast && renderToastView()}
      {state.showProgress && renderProgressDialog()}
    </div>
  );
};


export default CategoryManagement;










