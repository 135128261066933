import React, { Component } from "react";
import { Button, BreadCrumb, ProgressDialog, Toast } from "../components";
import { t } from "i18next";
import * as Bl from "../../businesslogic";
import "./riskprofileend.css";
import RiskProfileEnds from "../../assets/svgs/riskprofile_end.svg";
import {
  getRiskProfileDescription,
  getRiskProfilePathName,
  getUserId,
} from "../common";
import { Modal } from "rsuite";

class RiskProfileEnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      showModal: false,
    };
    this.isRenderingAlready = false;
    this.userId = getUserId();
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getUserRiskProfile();
    }
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  /*
   * while press the button, delete all the user answers and move to questions screen
   */
  onPressRetakeButton() {
    const riskProfileObj = new Bl.RiskProfile({});
    riskProfileObj.setUserId(this.userId);
    this.setState({ showProgress: true }, () => {
      riskProfileObj
        .deleteUserAnswers()
        .then(() => {
          this.props.navigate("/riskprofile", {
            state: { isEditView: true },
          });
        })
        .catch((error) => {
          console.log("RiskProfileEnd/onPressRetakeButton Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  onPressShowButton() {
    //TODO: call the api to send email
    this.setState({ showModal: true });
  }

  getUserRiskProfile() {
    const data = {
      uuid: this.userId,
    };
    this.setState({ showProgress: true }, () => {
      Bl.RiskProfile.getUserRiskProfile(data)
        .then((response) => {
          const categoryType = response?.finalriskcategory;
          const status = getRiskProfileDescription(categoryType);
          this.setState((prevState) => ({
            ...prevState,
            showProgress: false,
            status: status,
          }));
        })
        .catch((error) => {
          console.log("RiskProfilePage/getRiskQuestions Error:", error);
          this.showToast(t("rpeDisplayToast"));
        });
    });
  }

  hideModal() {
    this.setState({ showModal: false });
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  renderNavView() {
    const items = getRiskProfilePathName();
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderButtonsView() {
    return (
      <div className="rpe_buttonsView">
        <div className="rpe_buttonView">
          <Button
            buttonName={t("rpeButton1")}
            emptyButton={true}
            onPressButton={this.onPressRetakeButton.bind(this)}
          />
        </div>
        <div className="rpe_buttonView">
          <Button
            buttonName={t("rpeButton2")}
            onPressButton={this.onPressShowButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderProfileEndView() {
    const status = this.state?.status;
    const displayResult = status ? `${t("rpeTitle")} ${status}` : "";
    return (
      <div className="rpe_profileView">
        <img src={RiskProfileEnds} alt={"end"} className="rpe_end_icon" />
        <label className="rpe_labelStyle">{displayResult}</label>
        {this.renderButtonsView()}
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  renderModalView() {
    return (
      <Modal
        open={this.state.showModal}
        onClose={this.hideModal.bind(this)}
        dialogClassName={"rpe_modalContainer"}
      >
        <div className={"rpe_modalContent"}>
          <label className={"rpe_modalLabelStyle"}>{t("rpeModalLabel")}</label>
          <div className={"rpe_modalButtonView"}>
            <Button
              emptyButton={true}
              buttonName={t("rpeButton3")}
              onPressButton={this.hideModal.bind(this)}
            />
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div className="rpe_container">
        {this.renderNavView()}
        <div className="rpe_content">{this.renderProfileEndView()}</div>
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {this.renderModalView()}
      </div>
    );
  }
}

export default RiskProfileEnd;
