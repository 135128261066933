import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class UserApi {

    static options(){

        const token = localStorage.getItem('userToken');
        
        if (!token || token.trim() === '') {
            console.log('Token is not present or is empty');
            return Promise.reject(new Error('Token is missing or empty'));
        }
        
        const bearerToken = token.replace(/"/g, ''); 
        const options = {
            headers: {
                'Authorization': `Bearer ${bearerToken}`, 
                'Content-Type': 'application/json'
            }
        };
        
        return options;
    }

    static createUser(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/`, 'POST', data, false, this.options())
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static updateUser(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/`,'PATCH', data, false, this.options())
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static deleteUser(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/${data.id}`, 'DELETE', null, false, this.options())
                .then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
        })
    }

    static validateVerifyPAN(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/verify_pan_number?first_name=${data.firstname}&pan_number=${data.pannumber}`, Methods.POST, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    static updateUserStatus(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/status_info/?user_id=${data.userId}`, Methods.POST, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static getUserDetails() {
       
    
        return new Promise((resolve, reject) => {
         
            console.log('Request options:', this.options()); // Log the options
    
            apiCall(`User/`, 'GET', null, false, this.options())
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error('API call failed:', error.response ? error.response.data : error.message);
                    reject(error);
                });
        });
    }
    
    
    static getUserDetailsById(id) {
        return new Promise((resolve, reject) => {
            apiCall(`User/${id}`, 'GET', null, false, this.options())
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static userLogin(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/login`, Methods.POST, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static userForgetPin(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/forget_pin`, Methods.POST, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static verifyEmail(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/verify_email?id=${data.id}&email_otp=${data.email_otp}`, Methods.POST)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static changePassword(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/change_pin`, Methods.POST, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

export default UserApi;
