import { useEffect, useState } from "react";
import "./multirangeslider.css";
const MultiRangeSlider = (props) => {
  const [minValue, setMinValue] = useState(props.min);
  const [maxValue, setMaxValue] = useState(props.max);

  useEffect(() => {
    setMinValue(props.min);
    setMaxValue(props.max);
  }, [props.min, props.max]);

  const minGap = 0;

  const onChangeMinSlider = (event) => {
    const minVal = event.target.value;
    if (maxValue - minVal > minGap) {
      setMinValue(minVal);
      if (props.onChangeValue) {
        props.onChangeValue(minVal, maxValue);
      }
    }
  };

  const onChangeMaxSlider = (event) => {
    const maxVal = event.target.value;
    if (maxVal - minValue > minGap) {
      setMaxValue(maxVal);
      if (props.onChangeValue) {
        props.onChangeValue(minValue, maxVal);
      }
    }
  };

  const renderValueDisplayView = (props) => {
    const firstValue = Number(minValue);
    const secondValue = Number(maxValue - minValue);
    const thirdValue = Number(100 - (firstValue + secondValue));
    return (
      <div className="mrs_valuesView">
        <label
          className={"mrs_valuesStyle"}
          style={{ left: firstValue / 2.3 + "%" }}
        >
          {firstValue + "%"}
        </label>
        <label
          className={"mrs_valuesStyle"}
          style={{ left: firstValue + secondValue / 2.3 + "%" }}
        >
          {secondValue + "%"}
        </label>
        <label
          className={"mrs_valuesStyle"}
          style={{ left: firstValue + secondValue + thirdValue / 2.3 + "%" }}
        >
          {thirdValue + "%"}
        </label>
      </div>
    );
  };

  const renderSliderView = () => {
    return (
      <div className="mrs_slider">
        <div
          className="mrs_progress"
          style={{
            left: minValue + "%",
            right: 100 - maxValue + "%",
          }}
        ></div>
      </div>
    );
  };

  const renderSliderInputsView = (props) => {
    return (
      <div className="mrs_range_input">
        <input
          type={"range"}
          className={"mrs_input_min"}
          min={0}
          max={100}
          value={minValue}
          onChange={onChangeMinSlider}
        />
        <input
          type={"range"}
          className={"mrs_input_max"}
          min={0}
          max={100}
          value={maxValue}
          onChange={onChangeMaxSlider}
        />
      </div>
    );
  };

  const renderLabelDisplayView = () => {
    const firstValue = Number(minValue);
    const secondValue = Number(maxValue - minValue);
    const thirdValue = Number(100 - (firstValue + secondValue));
    const labels = props.labels;
    return (
      <div className="mrs_labelsView">
        <label
          className={"mrs_labelStyle"}
          style={{ left: firstValue / 2.5 + "%" }}
        >
          {labels[0]}
        </label>
        <label
          className={"mrs_labelStyle"}
          style={{ left: firstValue + secondValue / 2.5 + "%" }}
        >
          {labels[1]}
        </label>
        <label
          className={"mrs_labelStyle"}
          style={{ left: firstValue + secondValue + thirdValue / 2.5 + "%" }}
        >
          {labels[2]}
        </label>
      </div>
    );
  };

  return (
    <div className="mrs_container">
      {renderValueDisplayView()}
      {renderSliderView()}
      {renderSliderInputsView()}
      {renderLabelDisplayView()}
    </div>
  );
};

export default MultiRangeSlider;
