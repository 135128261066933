import React from "react";
import './otpview.css';
import { t } from "i18next";
import Show from "../../assets/svgs/show.svg";
import Hide from "../../assets/svgs/hide.svg"
import { Link } from "react-router-dom";


class OtpView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showText: true,
            displayPin: false,
            ...this.props,
        }
        this.textInput = [];
    }

    onChangeOTP(value, selectedIndex) {
        const otp = this.props.otp;
        const otpValue = `${value}`;
        if (otpValue.length === 1) {
            otp[selectedIndex] = value;
        } else if (otpValue > 1) {
            let otpSelectedIndex = selectedIndex;
            for (let index = 0; index < otpValue.length; index++) {
                if (otpSelectedIndex < this.props.noOfInputs) {
                    otp[otpSelectedIndex++] = otpValue.charAt(index);
                }
            }
        } else if (otpValue.length === 0) {
            otp[selectedIndex] = '';
        }
        this.props.onChangeOTP(otp);
    }

    onClickShowHide() {
        this.setState({ showText: !this.state.showText })
    }

    onPressEnterKey() {
        if (this.props.onPressEnterKey) {
            this.props.onPressEnterKey()
        }
    }

    renderTextInput(index) {
        const otp = this.props.otp;
        return (
            <input
                ref={input => {
                    this.textInput[index] = input;
                }}
                id={"otp_input"}
                name="otp1"
                key={index}
                type="numeric"
                autoComplete="off"
                className='ov_inputStyle'
                value={this.state.showText ? otp[index] : '*'}
                onKeyPress={({ nativeEvent }) => {
                    console.log('onKey Pressed:', nativeEvent.key)
                    if (nativeEvent.key === 'Backspace' || nativeEvent.key === 'Delete') {
                        if (!otp[index] && index !== 0) {
                            this.textInput[index - 1].focus();
                        }
                    }

                    if (nativeEvent.key === "Enter") {
                        this.onPressEnterKey()
                    }
                }}
                onChange={event => {
                    const value = event.target.value;
                    this.onChangeOTP(value, index);
                    if (value !== '') {
                        if (this.props.noOfInputs !== index + 1) {
                            this.textInput[index + 1].focus();
                        } else {
                            this.textInput[index].blur();
                        }
                    }
                }}
                maxLength="1"
            />
        );
    }

    renderTextInputs() {
        const inputs = [];
        for (let inputIndex = 0; inputIndex < this.props.noOfInputs; inputIndex++) {
            inputs.push(this.renderTextInput(inputIndex));
        }
        return (
            <div className="ov_inputsView">
                {inputs}
            </div>
        );
    }

    renderShowHideView() {
        const eyeIcon = this.state.showText ? Show : Hide;
        const label = this.state.showText ? t('showPIN') : t('hidePIN');
        return (
            <div
                className="ov_showHideView"
                onClick={this.onClickShowHide.bind(this)}
            >
                <img src={eyeIcon} alt={eyeIcon} className="ov_eyeStyle" />
                <label className="ov_showHideLabel">{label}</label>
            </div>
        );
    }

    renderForgetPinView() {
        return (
            <div className="ov_forgetpinView">
                <nav>
                    <Link to={'/resetpin'}>
                        <label className="ov_forgetPinStyle">{t('forgotPIN?')}</label>
                    </Link>
                </nav>
            </div>
        );
    }

    renderResultView() {
        const result = this.props.isMatched ? t('pINMatches') : t('pINDoesNotMatch');
        const style = this.props.isMatched ? "ov_matchedStyle" : "ov_notMatchedStyle";
        return (
            <div className="ov_resultView">
                <label className={style}>{result}</label>
            </div>
        );
    }

    renderActionsView() {
        return (
            <div className="ov_actionsView">
                {this.renderShowHideView()}
                {this.props.showForgotPin && this.renderForgetPinView()}
                {this.props.showResult && this.renderResultView()}
            </div>
        );
    }

    render() {
        return (
            <div className="ov_container">
                {this.renderTextInputs()}
                {this.props.showActions && this.renderActionsView()}
            </div>
        );
    }
}

export default OtpView;