import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class PortfolioAllocationApi {

  static getRuleDetails() {

    return new Promise((resolve, reject) => {
      apiCall(`PortfolioAllocationMaster/RuleDetails`, Methods.POST).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static getPortfolioAllocationValues(data) {
    return new Promise((resolve, reject) => {
      apiCall(`PortfolioAllocationMaster/SerachByRule?ruleno=${data.ruleNo}`, Methods.POST).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static createPortfolioAllocationValues(data) {
    return new Promise((resolve, reject) => {
      apiCall(`PortfolioAllocationMaster/`, Methods.POST, data).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static updatePortfolioAllocationValues(data) {
    return new Promise((resolve, reject) => {
      apiCall(`PortfolioAllocationMaster/`, Methods.PATCH, data).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default PortfolioAllocationApi;