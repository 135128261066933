import BackendApi from "../backend/index";

class Liability {
  constructor(props) {
    this.userid = props.userid;
    this.liabilitiescategory = props.liabilitiescategory;
    this.description = props.description;
    this.loanissuedby = props.loanissuedby;
    this.maturityyear = props.maturityyear;
    this.currentvalue = props.currentvalue;
    this.maturityage = props.maturityage;
    this.insertby = props.insertby;
    this.updateby = props.updateby;
    this.inserttime = props.inserttime;
    this.updatetime = props.updatetime;
    this.isincludefinancialplan = props.isincludefinancialplan;

    this.dbValue = {
      userid: props.userid,
      liabilitiescategory: props.liabilitiescategory,
      description: props.description,
      loanissuedby: props.loanissuedby,
      maturityyear: props.maturityyear,
      currentvalue: props.currentvalue,
      maturityage: props.maturityage,
      insertby: props.insertby,
      updateby: props.updateby,
      inserttime: props.inserttime,
      updatetime: props.updatetime,
      isincludefinancialplan: props.isincludefinancialplan
    };

    if (props.id) {
      this.id = props.id;
      this.dbValue.id = props.id;
    }
  }

  setLiabilityId(id) {
    this.id = id;
    this.dbValue.id = id;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setLiabilitiesCategory(liabilitiescategory) {
    this.liabilitiescategory = liabilitiescategory;
  }

  setDescription(description) {
    this.description = description;
  }

  setLoanIssuedBy(loanissuedby) {
    this.loanissuedby = loanissuedby;
  }

  setMaturityYear(maturityyear) {
    this.maturityyear = maturityyear;
  }

  setCurrentValue(currentvalue) {
    this.currentvalue = currentvalue;
  }

  setInsertBy(insertby) {
    this.insertby = insertby;
  }

  setUpdateBy(updateby) {
    this.updateby = updateby;
  }

  setInsertTime(inserttime) {
    this.inserttime = inserttime;
  }

  setUpdateTime(updatetime) {
    this.updatetime = updatetime;
  }

  setMaturityAge(maturityage) {
    this.maturityage = maturityage;
  }

  setIsIncludeFinancialPlan(isincludefinancialplan){
    this.isincludefinancialplan = isincludefinancialplan;
  }
  
  revertUserId() {
    this.userId = this.dbValue.userId;
  }

  revertLiabilitiesCategory() {
    this.liabilitiescategory = this.dbValue.liabilitiescategory;
  }

  revertDescription() {
    this.description = this.dbValue.description;
  }

  revertLoanIssuedBy() {
    this.loanissuedby = this.dbValue.loanissuedby;
  }

  revertMaturityYear() {
    this.maturityyear = this.dbValue.maturityyear;
  }

  revertCurrentValue() {
    this.currentvalue = this.dbValue.currentvalue;
  }

  revertInsertBy() {
    this.insertby = this.dbValue.insertby;
  }

  revertUpdateBy() {
    this.updateby = this.dbValue.updateby;
  }

  revertInsertTime() {
    this.inserttime = this.dbValue.inserttime;
  }

  revertUpdateTime() {
    this.updatetime = this.dbValue.updatetime;
  }

  revertMaturityAge() {
    this.maturityage = this.dbValue.maturityage;
  }

  revertIsIncludeFinancialPlan() {
    this.isincludefinancialplan = this.dbValue.isincludefinancialplan
  }

  revertAllChanges() {
    this.revertUserId();
    this.revertLiabilitiesCategory();
    this.revertDescription();
    this.revertLoanIssuedBy();
    this.revertMaturityYear();
    this.revertCurrentValue();
    this.revertInsertBy();
    this.revertUpdateBy();
    this.revertInsertTime();
    this.revertUpdateTime();
    this.revertMaturityAge();
    this.revertIsIncludeFinancialPlan();
  }

  createLiability() {
    this.setInsertBy("Fliber");
    this.setInsertTime(new Date());
    this.setUpdateBy("Fliber");
    this.setUpdateTime(new Date());

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.LiabilityApi.createLiability(this)
        .then((response) => {
          const liabilityId = response && response.id ? response.id : "";
          this.setLiabilityId(liabilityId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateLiability() {
    this.setInsertBy("Fliber");
    this.setUpdateBy("Fliber");
    this.setUpdateTime(new Date());

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.LiabilityApi.updateLiability(this)
        .then((response) => {
          const liabilityId = response && response.id ? response.id : "";
          this.setLiabilityId(liabilityId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteLiability() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.LiabilityApi.deleteLiability(this)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserLiabilityByUserId(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.LiabilityApi.getUserLiabilityByUserId(data)
        .then((liabilityList) => {
          const liabilities = [];
          if (liabilityList && liabilityList.length > 0) {
            liabilityList.forEach((item) => {
              const liabilityObj = new Liability(item);
              liabilities.push(liabilityObj);
            });
          }
          resolve(liabilityList);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserLiabilityByCategory(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.LiabilityApi.getUserLiabilityByCategory(data)
        .then((liabilityList) => {
          const liabilities = [];
          if (liabilityList && liabilityList.length > 0) {
            liabilityList.forEach((item) => {
              const liabilityObj = new Liability(item);
              liabilities.push(liabilityObj);
            });
          }
          resolve(liabilities);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getAllUserLiabilities(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.LiabilityApi.getAllUserLiabilities(data)
        .then((liabilityList) => {
          const liabilities = [];
          if (liabilityList && liabilityList.length > 0) {
            liabilityList.forEach((item) => {
              const liabilityObj = new Liability(item);
              liabilities.push(liabilityObj);
            });
          }
          resolve(liabilityList);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default Liability;
