import React, { Component } from "react";
import "./costperyearview.css";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import InfoIcon from "../../assets/svgs/ic_info.svg";
import { getFormatAmount } from "../common";

class CostPerYearView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableArrows: false,
    };
    this.viewRef = null;
    this.isRenderingAlready = false;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.checkIsEnableArrows();
    }
  }

  checkIsEnableArrows() {
    const disableArrows =
      this.viewRef?.offsetWidth >= this.viewRef?.scrollWidth;
    this.setState({ disableArrows });
  }

  onPressLeftArrow = () => {
    if (this.viewRef) {
      this.viewRef.scrollLeft -= 500;
    }
  };

  onPressRightArrow = () => {
    if (this.viewRef) {
      this.viewRef.scrollLeft += 500;
    }
  };

  renderLeftView() {
    return (
      <div className="cpy_left_view">
        <img src={InfoIcon} alt={"Info Icon"} className="cpy_info_icon" />
        <label className="cpy_labelStyle">{this.props.labelText}</label>
      </div>
    );
  }

  renderValue() {
    const items = this.props.sliderContent;
    return (
      <div
        ref={(ref) => {
          this.viewRef = ref;
        }}
        className="cpy_contentView"
      >
        {items.map((item, index) => {
          const displayAmount = getFormatAmount(item.money);
          return (
            <div key={index} className="cpy_array_view">
              <div className="cpy_value">
                <label className="cpy_value_content1">{item.year}</label>
                <label className="cpy_value_content2">₹ {displayAmount} </label>
              </div>
              {index !== items.length - 1 && (
                <div className="cpy_separatorView" />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  renderRightView() {
    const disableArrows = this.state.disableArrows;
    return (
      <div className="cpy_right_view">
        <BiChevronLeft
          className={disableArrows ? "cpy_disable_arrow" : "cpy_left_arrow"}
          onClick={disableArrows ? () => {} : this.onPressLeftArrow.bind(this)}
        />
        {this.renderValue()}
        <BiChevronRight
          className={disableArrows ? "cpy_disable_arrow" : "cpy_right_arrow"}
          onClick={disableArrows ? () => {} : this.onPressRightArrow.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="cpy_container">
        {this.renderLeftView()}
        {this.renderRightView()}
      </div>
    );
  }
}

export default CostPerYearView;
