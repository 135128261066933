import React, { Component } from "react";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  TitleView,
  ProgressDialog,
  Toast,
} from "../components";
import { t } from "i18next";
import "./gwtexpensepage.css";
import { DEFAULT_OFFSET, GOAL_CATEGORY, GOAL_EXPENSE_TYPE, GOAL_FREQUENCY_TYPE } from "../constants";
import * as Bl from "../../businesslogic";
import { getAdvisorId, getGoalsPathName, getUserDOB, getUserId, getUserDetails } from "../common";
import { getCurrentAge } from "../utils/common";

const newGoal = {
  userid: '',
  goalcategory: GOAL_CATEGORY.GEN_WEALTH,
  goalstartyear: 0,
  goalendyear: 0,
  goalamountpv: 0,
  goalfrequency: 1,
  advisorid: getAdvisorId(),
  goalfrequencytype: GOAL_FREQUENCY_TYPE.YEARLY,
  goalbucketcode: "",
  retirewanttype: "",
  monthlyexpenselist: [],
  goaldescription: "",
  goalinflationrate: 0,
  goalamountfv: 0,
  homeloanstatus: false,
  downpayment: 0,
  goaldetails: [],
  goalstartage: 0,
  goalendage: 0,
  userage: 0,
  expensetype: GOAL_EXPENSE_TYPE.ONE_TIME
};

class GWTExpensePage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const isEditView = params?.isEditView ? params.isEditView : false;
    const goalData = params?.goalData;
    this.state = {
      amount: isEditView ? goalData.goalamountfv : "",
      isEditView: isEditView ? isEditView : false,
      newGoalData: isEditView ? new Bl.Goals(goalData) : new Bl.Goals({ ...newGoal, userid: getUserId() }),

    }
    this.isRenderingAlready = false;
    this.dbValue = this.state.newGoalData;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      //No need to pass the age from front end
      // this.getCurrentAge();
      this.getUserDetails()
    }
  }

  onSelectItem(path) {
    const localData = new Bl.Goals(this.state.newGoalData)
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true, fetchingCurrentAge: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const goalsObj = this.state.newGoalData;
          goalsObj.setUserAge(currentAge);
          goalsObj.setGoalStartAge(currentAge);
          this.setState({
            currentAge: currentAge,
            showProgress: false,
            fetchingCurrentAge: false
          }, () => {
            this.getDBValue();
          });
        })
        .catch((error) => {
          console.log("VacationExpensePage.js/getInflationRate Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getUserDetails() {
    const userDetails = getUserDetails();
    const goalsObj = this.state.newGoalData;
    goalsObj.setGoalStartAge(userDetails.lifeexpectancy);
    this.forceUpdate();
  }

  getDBValue() {
    if (this.state.fetchingCurrentAge === false) {
      const dbValue = this.state.newGoalData
        ? JSON.parse(JSON.stringify(this.state.newGoalData))
        : {};
      this.setState({ dbValue })
    }
  }

  createGwtExpenseGoal() {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    const isDataValid = this.checkIsDataValid(goalObj);
    console.log({ goalObj })
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        goalObj
          .createGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/goalscategorize", { state: { path: "GWT" } });
            });
          })
          .catch((error) => {
            console.log(
              "GwtExpensePage/Create Error:",
              error
            );
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateGwtExpenseGoal() {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        goalObj
          .updateGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/goalscategorize", { state: { path: "GWT" } });
            });
          })
          .catch((error) => {
            console.log(
              "GwtExpensePage/update Error:",
              error
            );
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  onChangeAmount(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalAmountFv(value ? Number(value) : 0);
    this.setState({ amount: value });
  }
  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    if (!this.isValueValid(data.goalamountfv)) {
      isValid = false;
      message = t("gwtToastAmount");
    }
    return { isValid, message };
  }
  isValueValid(value) {
    return value > 0;
  }
  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }
  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  onPressSaveButton() {
    if (!this.state.isEditView) {
      this.createGwtExpenseGoal();
    }
    else {
      this.updateGwtExpenseGoal();
    }
  }


  renderNavView() {
    const items = getGoalsPathName("GWT");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        dbData={this.dbValue}
        localData={this.state.newGoalData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t('gwtTitle');
    return (
      <TitleView title={title} information={""} />
    );
  }

  renderInputView(label, value, onChange, showRupeeSymbol = false, showPercentage = false) {
    return (
      <div className='gwt_input'>
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderContentView() {
    return (
      <div className='gwt_content_view'>
        {this.renderTitleView()}
        {this.renderInputView(
          t('gwtInput'),
          this.state.amount,
          this.onChangeAmount.bind(this),
          true
        )}
      </div>
    )
  }

  renderButton() {
    return (
      <div className="gwt_bottomView">
        <div className="gwt_buttonView">
          <div className='gwt_save'>
            <Button
              buttonName={t('gwtButton')}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  // renderDrawerContent() {
  //   const headerTitle = t("financialPlan");
  //   const step = 2;
  //   return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  // }
  render() {
    return (
      <div className='gwt_container'>
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButton()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default GWTExpensePage;
