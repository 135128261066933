import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { t } from "i18next";
import "./assets/fonts/font.css";
import Menu from "./pages/menu";
import React, { useState, useRef } from "react";
import { Header } from "./pages/components";
import {
  RiskProfileHome,
  RiskProfilePage,
  GoalsLandingPage,
  FinancialLandingPage,
  RiskProfileEnd,
  RetirementExpensePage,
  GoalsCategorizePage,
  HealthCareExpensePage,
  GWTExpensePage,
  CustomGoalExpensePage,
  EducationExpensePage,
  EmiExpensePage,
  VacationExpensePage,
  VehicleExpensePage,
  WeddingExpensePage,
  RealEstateExpensePage,
  FinancialDetailsPage,
  BondHome,
  BondDetailPage,
  BondDashBoard,
  EPFDashBoard,
  EpfHome,
  EPFDetails,
  FDHome,
  FDDetailPage,
  FDDashBoard,
  GSSHome,
  GSSDetailPage,
  GSSDashBoard,
  PPFHome,
  PPFDetailPage,
  PPFDashBoard,
  StocksHome,
  StocksDetailPage,
  StocksDashBoard,
  NPSHome,
  NPSDetailPage,
  NPSDashBoard,
  RealEstateHome,
  RealEstateDetail,
  RealEstateDashBoard,
  HousingLoanHome,
  HousingLoanDetail,
  HousingLoanDashBoard,
  JewelleryHome,
  JewelleryDetail,
  JewelleryDashBoard,
  VehicleLoanHome,
  VehicleLoanDetail,
  VehicleLoanDashBoard,
  ArtAntiquesHome,
  ArtAntiquesDetail,
  ArtAntiquesDashBoard,
  PhysicalAssetHome,
  PhysicalAssetDetail,
  PhysicalAssetDashBoard,
  OtherFinancialHome,
  OtherFinancialDetail,
  OtherFinancialDashBoard,
  BankSavingsHome,
  BankSavingsDetail,
  BankSavingsDashBoard,
  MutualFund,
  PersonalLoanHome,
  PersonalLoanDetail,
  PersonalLoanDashBoard,
  OtherLoanHome,
  OtherLoanDetail,
  OtherLoanDashBoard,
  CreditCardHome,
  CreditCardDetail,
  CreditCardDashBoard,
  OtherNonMarketHome,
  OtherNonMarketDetail,
  OtherNonMarketDashBoard,
  AnnuityHomePage,
  AnnuityDetails,
  AnnuityDashboard,
  BusinessHomePage,
  BusinessDetails,
  BusinessDashboard,
  RentalHomePage,
  RentalDetails,
  RentalDashboard,
  InsuranceHomePage,
  InsuranceDetails,
  InsuranceDashboard,
  OtherHomePage,
  OtherIncomeDetails,
  OtherIncomeDashboard,
  AssetAllocDetails,
  Dashboard,
  LoginScrn,
  RegisterScrn,
  ResetPin,
  CashLadderPage,
  CustomerProfile,
  PortfolioAssetAllocation,
  Configuration,
  AdminRiskProfile,
  CustomerMgmt,
  AssetAllocationRuleTable,
  RiskProfileUrlPage,
  PortfolioRuleTable,
  WebPageScrn,
  CategoryManagement,
} from "./pages";

import AppListener from "./applistener";
import { getRoutes } from "./rbac";

const AccessDenied = () => {
  return (
    <label>{'Access denied'}</label>
  );
}

const RouteComponent = (Component, props, route) => {
  const routes = getRoutes();
  return routes.includes(route) ?
    <Component location={props.location} navigate={props.navigate} /> :
    <AccessDenied {...props} />;
}

const PrivateRoute = (component, route, props = {}, auth, loading) => {
  return RouteComponent(component, props, route);
}

const App = () => {
  let menuRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [displayFullView, setDisplayFullView] = useState(false);
  const props = { navigate, location };

  function onClickMenu() {
    setDisplayFullView(!displayFullView);
    if (menuRef && typeof menuRef?.current?.onChangeMenu === "function") {
      menuRef?.current?.onChangeMenu();
    }
  }

  const showMenu = () => {
    let isShowMenu = true;

    //If the route is riskprofile web link, then no need to show the menu
    const pathname = location.pathname;
    const url = pathname.split('/');
    const isWebUrl = url && (url[1] === 'riskprofile' || url[1] === 'resetpin') && url[2] && url[2] !== '';
    if (isWebUrl) {
      isShowMenu = false;
    }

    //In login screens no need to show the menu
    if (pathname === '/' ||
      pathname === '/webpage' ||
      pathname === '/loginscrn' ||
      pathname === '/resetpin' ||
      pathname === '/registerscrn') {
      isShowMenu = false;
    }

    return isShowMenu;
  }

  const showHeader = () => {
    let isShowHeader = true;

    const pathname = location.pathname;

    //In webpage no need to show the header
    if (pathname === '/' ||
      pathname === '/webpage') {
      isShowHeader = false;
    }

    return isShowHeader;
  }

  function getContainerStyle() {
    if (!showMenu()) {
      return "app-contetStyle_3";
    } else if (displayFullView) {
      return "app-contentStyle_2";
    } else {
      return "app-contentStyle";
    }
  }


  function PageNotFound() {
    return <label>Page not found</label>;
  }

  const renderHeaderView = () => {
    return (
      <Header
        hideMenu={!showMenu()}
        onClickMenu={onClickMenu} />
    );
  }

  return (
    <AppListener>
      <div className={"app-containerStyle"}>
        {showMenu() && <Menu ref={menuRef} navigate={navigate} />}
        <div className={getContainerStyle()}>
          {showHeader() && renderHeaderView()}
          <Routes>
            <Route exact path={"/"} element={PrivateRoute(WebPageScrn, '/webpage', props)} />
            <Route exact path={"/configuration"} element={PrivateRoute(Configuration, '/configuration', props)} />
            <Route exact path={"/loginscrn"} element={PrivateRoute(LoginScrn, '/loginscrn', props)} />
            <Route exact path={"/registerscrn"} element={PrivateRoute(RegisterScrn, '/registerscrn', props)} />
            <Route exact path={"/resetpin"} element={PrivateRoute(ResetPin, '/resetpin', props)} />
            <Route exact path={"/dashboard"} element={PrivateRoute(Dashboard, '/dashboard', props)} />
            <Route exact path={"/othernonmarkethome"} element={PrivateRoute(OtherNonMarketHome, '/othernonmarkethome', props)} />
            <Route exact path={"/othernonmarketdetail"} element={PrivateRoute(OtherNonMarketDetail, '/othernonmarketdetail', props)} />
            <Route exact path={"/othernonmarketdashboard"} element={PrivateRoute(OtherNonMarketDashBoard, '/othernonmarketdashboard', props)} />
            <Route exact path={"/banksavingsdashboard"} element={PrivateRoute(BankSavingsDashBoard, '/banksavingsdashboard', props)} />
            <Route exact path={"/banksavingsdetail"} element={PrivateRoute(BankSavingsDetail, '/banksavingsdetail', props)} />
            <Route exact path={"/banksavingshome"} element={PrivateRoute(BankSavingsHome, '/otherfinancialhome', props)} />
            <Route exact path={"/otherfinancialhome"} element={PrivateRoute(OtherFinancialHome, '/otherfinancialhome', props)} />
            <Route exact path={"/otherfinancialdetail"} element={PrivateRoute(OtherFinancialDetail, '/otherfinancialdashboard', props)} />
            <Route exact path={"/otherfinancialdashboard"} element={PrivateRoute(OtherFinancialDashBoard, '/otherfinancialdashboard', props)} />
            <Route exact path={"/physicalassetdashboard"} element={PrivateRoute(PhysicalAssetDashBoard, '/physicalassetdashboard', props)} />
            <Route exact path={"/physicalassetdetail"} element={PrivateRoute(PhysicalAssetDetail, '/physicalassetdetail', props)} />
            <Route exact path={"/physicalassethome"} element={PrivateRoute(PhysicalAssetHome, '/physicalassethome', props)} />
            <Route exact path={"/artantiquesdashboard"} element={PrivateRoute(ArtAntiquesDashBoard, '/artantiquesdashboard', props)} />
            <Route exact path={"/artantiquesdetail"} element={PrivateRoute(ArtAntiquesDetail, '/artantiquesdetail', props)} />
            <Route exact path={"/artantiqueshome"} element={PrivateRoute(ArtAntiquesHome, '/artantiqueshome', props)} />
            <Route exact path={"/npsdashboard"} element={PrivateRoute(NPSDashBoard, '/npsdashboard', props)} />
            <Route exact path={"/npsdetailpage"} element={PrivateRoute(NPSDetailPage, '/npsdetailpage', props)} />
            <Route exact path={"/gssdashboard"} element={PrivateRoute(GSSDashBoard, '/gssdashboard', props)} />
            <Route exact path={"/fddashboard"} element={PrivateRoute(FDDashBoard, '/fddashboard', props)} />
            <Route exact path={"/gssdetailpage"} element={PrivateRoute(GSSDetailPage, '/gssdetailpage', props)} />
            <Route exact path={"/fddetailpage"} element={PrivateRoute(FDDetailPage, '/fddetailpage', props)} />
            <Route exact path={"/gsshome"} element={PrivateRoute(GSSHome, '/gsshome', props)} />
            <Route exact path={"/fdhome"} element={PrivateRoute(FDHome, '/fdhome', props)} />
            <Route exact path={"/bonddashboard"} element={PrivateRoute(BondDashBoard, '/bonddashboard', props)} />
            <Route exact path={"/bonddetailpage"} element={PrivateRoute(BondDetailPage, '/bonddetailpage', props)} />
            <Route exact path={"/bondhome"} element={PrivateRoute(BondHome, '/bondhome', props)} />
            <Route exact path={"/stocksdashboard"} element={PrivateRoute(StocksDashBoard, '/stocksdashboard', props)} />
            <Route exact path={"/stocksdetailpage"} element={PrivateRoute(StocksDetailPage, '/stocksdetailpage', props)} />
            <Route exact path={"/stockshome"} element={PrivateRoute(StocksHome, '/stockshome', props)} />
            <Route exact path={"/npshome"} element={PrivateRoute(NPSHome, '/npshome', props)} />
            <Route exact path={"/ppfdashboard"} element={PrivateRoute(PPFDashBoard, '/ppfdashboard', props)} />
            <Route exact path={"/ppfdetailspage"} element={PrivateRoute(PPFDetailPage, '/ppfdetailspage', props)} />
            <Route exact path={"/ppfhome"} element={PrivateRoute(PPFHome, '/ppfhome', props)} />
            <Route exact path={"/epfdashboard"} element={PrivateRoute(EPFDashBoard, '/epfdashboard', props)} />
            <Route exact path={"/epfdetails"} element={PrivateRoute(EPFDetails, '/epfdetails', props)} />
            <Route exact path={"/epfhome"} element={PrivateRoute(EpfHome, '/epfhome', props)} />
            <Route exact path={"/riskprofilehome"} element={PrivateRoute(RiskProfileHome, '/riskprofilehome', props)} />
            <Route exact path={`/riskprofile`} element={PrivateRoute(RiskProfilePage, '/riskprofile', props)} />
            <Route path="/riskprofile/:id" element={PrivateRoute(RiskProfileUrlPage, '/riskprofile', props)} />
            <Route path="/resetpin/:id" element={PrivateRoute(ResetPin, '/resetpin', props)} />
            <Route exact path={"/riskprofileend"} element={PrivateRoute(RiskProfileEnd, '/riskprofileend', props)} />
            <Route exact path={"/goals"} element={PrivateRoute(GoalsLandingPage, '/goals', props)} />
            <Route exact path={"/retirement"} element={PrivateRoute(RetirementExpensePage, '/retirement', props)} />
            <Route exact path={"/financial"} element={PrivateRoute(FinancialLandingPage, '/financial', props)} />
            <Route exact path={"/goalscategorize"} element={PrivateRoute(GoalsCategorizePage, '/goalscategorize', props)} />
            <Route exact path={"/healthcare"} element={PrivateRoute(HealthCareExpensePage, '/healthcare', props)} />
            <Route exact path={"/wealthtransfer"} element={PrivateRoute(GWTExpensePage, '/wealthtransfer', props)} />
            <Route exact path={"/customgoal"} element={PrivateRoute(CustomGoalExpensePage, '/customgoal', props)} />
            <Route exact path={"/education"} element={PrivateRoute(EducationExpensePage, '/education', props)} />
            <Route exact path={"/vacation"} element={PrivateRoute(VacationExpensePage, '/vacation', props)} />
            <Route exact path={"/vehicle"} element={PrivateRoute(VehicleExpensePage, '/vehicle', props)} />
            <Route exact path={"/wedding"} element={PrivateRoute(WeddingExpensePage, '/wedding', props)} />
            <Route exact path={"/emi"} element={PrivateRoute(EmiExpensePage, '/emi', props)} />
            <Route exact path={"/realestate"} element={PrivateRoute(RealEstateExpensePage, '/realestate', props)} />
            <Route exact path={"/realestatehome"} element={PrivateRoute(RealEstateHome, '/realestatehome', props)} />
            <Route exact path={"/realestatedetail"} element={PrivateRoute(RealEstateDetail, '/realestatedetail', props)} />
            <Route exact path={"/realestatedashboard"} element={PrivateRoute(RealEstateDashBoard, '/realestatedashboard', props)} />
            <Route exact path={"/jewellerydashboard"} element={PrivateRoute(JewelleryDashBoard, '/jewellerydashboard', props)} />
            <Route exact path={"/jewelleryhome"} element={PrivateRoute(JewelleryHome, '/jewelleryhome', props)} />
            <Route exact path={"/jewellerydetail"} element={PrivateRoute(JewelleryDetail, '/jewellerydetail', props)} />
            <Route exact path={"/financialdetails"} element={PrivateRoute(FinancialDetailsPage, '/financialdetails', props)} />
            <Route exact path={"/housingloanhome"} element={PrivateRoute(HousingLoanHome, '/housingloanhome', props)} />
            <Route exact path={"/housingloandetail"} element={PrivateRoute(HousingLoanDetail, '/housingloandetail', props)} />
            <Route exact path={"/housingloandashboard"} element={PrivateRoute(HousingLoanDashBoard, '/housingloandashboard', props)} />
            <Route exact path={"/vehicleloanhome"} element={PrivateRoute(VehicleLoanHome, '/vehicleloanhome', props)} />
            <Route exact path={"/vehicleloandetail"} element={PrivateRoute(VehicleLoanDetail, '/vehicleloandetail', props)} />
            <Route exact path={"/vehicleloandashboard"} element={PrivateRoute(VehicleLoanDashBoard, '/vehicleloandashboard', props)} />
            <Route exact path={"/personalloanhome"} element={PrivateRoute(PersonalLoanHome, '/personalloanhome', props)} />
            <Route exact path={"/personalloandetail"} element={PrivateRoute(PersonalLoanDetail, '/personalloandetail', props)} />
            <Route exact path={"/personalloandashboard"} element={PrivateRoute(PersonalLoanDashBoard, '/personalloandashboard', props)} />
            <Route exact path={"/otherloanhome"} element={PrivateRoute(OtherLoanHome, '/otherloanhome', props)} />
            <Route exact path={"/otherloandetail"} element={PrivateRoute(OtherLoanDetail, '/otherloandetail', props)} />
            <Route exact path={"/otherloandashboard"} element={PrivateRoute(OtherLoanDashBoard, '/otherloandashboard', props)} />
            <Route exact path={"/creditcardhome"} element={PrivateRoute(CreditCardHome, '/creditcardhome', props)} />
            <Route exact path={"/creditcarddetail"} element={PrivateRoute(CreditCardDetail, '/creditcarddetail', props)} />
            <Route exact path={"/creditcarddashboard"} element={PrivateRoute(CreditCardDashBoard, '/creditcarddashboard', props)} />
            <Route exact path={"/annuityhomepage"} element={PrivateRoute(AnnuityHomePage, '/annuityhomepage', props)} />
            <Route exact path={"/annuitydetails"} element={PrivateRoute(AnnuityDetails, '/annuitydetails', props)} />
            <Route exact path={"/annuitydashboard"} element={PrivateRoute(AnnuityDashboard, '/annuitydashboard', props)} />
            <Route exact path={"/rentalhomepage"} element={PrivateRoute(RentalHomePage, '/rentalhomepage', props)} />
            <Route exact path={"/rentaldetails"} element={PrivateRoute(RentalDetails, '/rentaldetails', props)} />
            <Route exact path={"/rentaldashboard"} element={PrivateRoute(RentalDashboard, '/rentaldashboard', props)} />
            <Route exact path={"/businesshomepage"} element={PrivateRoute(BusinessHomePage, '/businesshomepage', props)} />
            <Route exact path={"/businessdetails"} element={PrivateRoute(BusinessDetails, '/businessdetails', props)} />
            <Route exact path={"/businessdashboard"} element={PrivateRoute(BusinessDashboard, '/businessdashboard', props)} />
            <Route exact path={"/insurancehomepage"} element={PrivateRoute(InsuranceHomePage, '/insurancehomepage', props)} />
            <Route exact path={"/insurancedetails"} element={PrivateRoute(InsuranceDetails, '/insurancedetails', props)} />
            <Route exact path={"/insurancedashboard"} element={PrivateRoute(InsuranceDashboard, '/insurancedashboard', props)} />
            <Route exact path={"/otherhomepage"} element={PrivateRoute(OtherHomePage, '/otherhomepage', props)} />
            <Route exact path={"/otherincomedetails"} element={PrivateRoute(OtherIncomeDetails, '/otherincomedetails', props)} />
            <Route exact path={"/otherincomedashboard"} element={PrivateRoute(OtherIncomeDashboard, '/otherincomedashboard', props)} />
            <Route exact path={"/mutualfund"} element={PrivateRoute(MutualFund, '/mutualfund', props)} />
            <Route exact path={"/assetallocdetails"} element={PrivateRoute(AssetAllocDetails, '/assetallocdetails', props)} />
            <Route exact path={"/cashladder"} element={PrivateRoute(CashLadderPage, '/cashladder', props)} />
            <Route exact path={"/customerprofile"} element={PrivateRoute(CustomerProfile, '/customerprofile', props)} />
            <Route exact path={"/portfolioassetallocation"} element={PrivateRoute(PortfolioAssetAllocation, '/portfolioassetallocation', props)} />
            <Route exact path={"/adminriskprofile"} element={PrivateRoute(AdminRiskProfile, '/adminriskprofile', props)} />
            <Route exact path={"/customermgmt"} element={PrivateRoute(CustomerMgmt, '/customermgmt', props)} />
            <Route exact path={"/assestallocationruletable"} element={PrivateRoute(AssetAllocationRuleTable, '/assestallocationruletable', props)} />
            <Route exact path={"/portfoliorule"} element={PrivateRoute(PortfolioRuleTable, '/portfoliorule', props)} />
            <Route exact path={"/categorymgmt"} element={PrivateRoute(CategoryManagement, '/categorymgmt', props)} />
            <Route path={"*"} element={<PageNotFound />} />
          </Routes>
        </div>
      </div>
    </AppListener>
  );
};
export default App;