import React, { Component } from "react";
import "./personalloandetail.css";
import { t } from "i18next";
import * as Bl from "../../../businesslogic";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  Slider,
  TitleView,
  SideModel,
  ProgressDialog,
  Toast,
} from "../../components";
import { getCurrentYear } from "../../utils/datetime";
import { calculate_age, getLiabilityPathName, getUserDOB } from "../../common";
import * as logger from "../../utils/logger/index";
import { DEFAULT_OFFSET } from "../../constants";
import { getCurrentAge } from "../../utils/common";

class PersonalLoanDetail extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const liabilityData = params?.liabilityData ? params.liabilityData : "";
    const isEditView = params?.isEditView ? params.isEditView : false;
    this.state = {
      liabilityData: liabilityData ? new Bl.Liability(liabilityData) : {},
      isEditView,
      currentAge:0,
    };
    this.isRenderingAlready = false;
    this.dbData = liabilityData
      ? JSON.parse(JSON.stringify(new Bl.Liability(liabilityData)))
      : {};
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getCurrentAge();
    }
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
          });
        })
        .catch((error) => {
          console.log("PersonalLoanDetailsPage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }
  onClickBackIcon(path) {
    this.props.navigate(path);
  }

  onSelectItem(path) {
    const localData = new Bl.Liability(this.state.liabilityData);
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onPressSaveButton() {
    if (this.state.isEditView) {
      this.updateUserLiability();
    } else {
      this.createUserLiability();
    }
  }

  onChangeDescription(value) {
    const liability = this.state.liabilityData;
    liability.setDescription(value);
    this.forceUpdate();
  }

  onChangeIssuedBy(value) {
    const liability = this.state.liabilityData;
    liability.setLoanIssuedBy(value);
    this.forceUpdate();
  }
  onChangeMaturity(value) {
    const liability = this.state.liabilityData;
    liability.setCurrentValue(value ? Number(value) : 0);
    this.forceUpdate();
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const maturityAge = calculate_age(newValue,currentYear,this.state.currentAge)
    const liability = this.state.liabilityData;
    liability.setMaturityYear(newValue ? Number(newValue) : newValue);
    liability.setMaturityAge(maturityAge)
    this.forceUpdate();
  }

  createUserLiability() {
    const currentYear = getCurrentYear();
    const liabilityData = this.state.liabilityData;
    const startValue = liabilityData?.maturityyear
    ? liabilityData.maturityyear
    : currentYear + 1;
    const maturityAge = calculate_age(startValue,currentYear,this.state.currentAge)
    const liabilityObj = new Bl.Liability(this.state.liabilityData);
    if (liabilityObj.maturityyear === 0) {
      liabilityObj.setMaturityYear(getCurrentYear() + 1);
      liabilityObj.setMaturityAge(maturityAge)
    }
    const isDataValid = this.checkIsDataValid(liabilityObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        liabilityObj
          .createLiability()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/personalloandashboard");
            });
          })
          .catch((error) => {
            logger.error({error})
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserLiability() {
    const currentYear = getCurrentYear();
    const liabilityData = this.state.liabilityData;
    const startValue = liabilityData?.maturityyear
    ? liabilityData.maturityyear
    : currentYear + 1;
    const maturityAge = calculate_age(startValue,currentYear,this.state.currentAge)
    const liabilityObj = new Bl.Liability(this.state.liabilityData);
    if (liabilityObj.maturityyear === 0) {
      liabilityObj.setMaturityYear(getCurrentYear() + 1);
      liabilityObj.setMaturityAge(maturityAge)
    }
    const isDataValid = this.checkIsDataValid(liabilityObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        liabilityObj
          .updateLiability()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/personalloandashboard");
            });
          })
          .catch((error) => {
            logger.error({error})
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  isAmountValid(amount) {
    return amount && amount > 0;
  }

  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    if (!data.description) {
      isValid = false;
      message = t("plToastDescription");
    } else if (!data.loanissuedby) {
      isValid = false;
      message = t("plToastIssuedBy")
    } else if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("plToastBalance");
    }
    return { isValid, message };
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }
  
  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

 

  renderNavView() {
    const items = getLiabilityPathName("PersonalLoan");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        dbData={this.dbData}
        localData={this.state.liabilityData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("plTitle");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="pdetail_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderDescNIssuedBy() {
    const liabilityData = this.state.liabilityData;
    const description = liabilityData?.description
      ? liabilityData.description
      : "";
    const loanissuedby = liabilityData?.loanissuedby
      ? liabilityData.loanissuedby
      : "";
    return (
      <div className="pdetail_description_view">
        {this.renderInputView(
          t("plInputDescription"),
          description,
          this.onChangeDescription.bind(this)
        )}
        {this.renderInputView(
          t("plInputLoanIssued"),
          loanissuedby,
          this.onChangeIssuedBy.bind(this)
        )}
      </div>
    );
  }

  renderExpectedReturn() {
    const currentYear = getCurrentYear();
    const liabilityData = this.state.liabilityData;
    const startValue = liabilityData?.maturityyear
      ? liabilityData.maturityyear
      : currentYear + 1;
    const age = calculate_age(startValue,currentYear,this.state.currentAge);
    return (
      <div className="pdetail_start_year_row">
        <label className="pdetail_start_text">{t("plSliderStart")}</label>
        <div className="pdetail_slider_content">
          <Slider
            value={startValue}
            age={age}
            min={currentYear + 1}
            max={currentYear + 51}
            markStep={10}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="pdetail_bottomView">
        <div className="pdetail_buttonView">
          <div className="pdetail_save">
            <Button
              buttonName={t("plButton1")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderBalance() {
    const liabilityData = this.state.liabilityData;
    const currentValue = liabilityData?.currentvalue
      ? liabilityData.currentvalue
      : "";
    return (
      <div className="pdetail_description_view">
        {this.renderInputView(
          t("plInputBalance"),
          currentValue,
          this.onChangeMaturity.bind(this),
          true
        )}
      </div>
    );
  }

  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderContentView() {
    return (
      <div className="pdetail_content_view">
        {this.renderTitleView()}
        {this.renderDescNIssuedBy()}
        <div className="pdetail_seperatorStyle" />
        {this.renderExpectedReturn()}
        <div className="pdetail_seperatorStyle" />
        {this.renderBalance()}
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="pdetail_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default PersonalLoanDetail;
