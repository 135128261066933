import React, { Component } from "react";
import "./bonddetailpage.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  Slider,
  TitleView,
  ToggleSwitch,
  SideModel,
  Toast,
  ProgressDialog,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getCurrentYear } from "../../utils/datetime";
import { calculate_age, getFinancialMarketPath, getUserDOB } from "../../common";
import * as logger from "../../utils/logger/index";
import { DEFAULT_OFFSET } from "../../constants";
import { getCurrentAge } from "../../utils/common";

class BondDetailPage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const investmentData = params?.investmentData ? params.investmentData : "";
    const isEditView = params?.isEditView ? params.isEditView : false;
    this.state = {
      startValue: getCurrentYear() + 1,
      startAge: getCurrentYear() + 1,
      investmentsData: investmentData ? new Bl.Investment(investmentData) : {},
      fliberAdvise: investmentData?.investmentdetails[0]?.isfliberadvise
        ? investmentData?.investmentdetails[0]?.isfliberadvise
        : false,
      isEditView,
      currentAge: 0,
    };
    this.isRenderingAlready = false;
    this.dbData = investmentData
      ? JSON.parse(JSON.stringify(new Bl.Investment(investmentData)))
      : {};
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getCurrentAge();
    }
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const investmentObj = this.state.investmentsData;
          investmentObj.setUserAge(currentAge);
          this.setState({
            currentAge: currentAge,
            showProgress: false,
          });
        })
        .catch((error) => {
          console.log("BondDetailsPage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }
  onSelectItem(path) {
    const localData = new Bl.Investment(this.state.investmentsData);
    localData.revertAllChanges();
    this.setState(
      {
        fliberAdvise: this.dbData?.investmentdetails[0]?.isfliberadvise
          ? this.dbData?.investmentdetails[0]?.isfliberadvise
          : false,
      },
      () => {
        this.props.navigate(path);
      }
    );
  }

  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    if (!this.isDescValid(data.investmentdescription)) {
      isValid = false;
      message = t("bondsToastDesc");
    } else if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("Bond: Please enter the Investment Amount");
    } else if (!this.isAmountValid(data.maturityvalue)) {
      isValid = false;
      message = t("bondsToastMaturityValue");
    } else if (!this.isMaturityYearValid(data.maturityyear)) {
      isValid = false;
      message = t("bondsToastMaturityYear");
    }
    return { isValid, message };
  }

  isDescValid(desc) {
    return desc && desc.length > 0;
  }
  isAmountValid(amount) {
    return amount && amount > 0;
  }
  isMaturityYearValid(maturityyear) {
    return maturityyear !== 0;
  }

  onChangeInvestmentAmount(value) {
    const investment = this.state.investmentsData;
    investment.setCurrentValue(value ? Number(value) : 0);
    this.forceUpdate();
  }

  prepareBondsData() {
    const fields = {
      isfliberadvise: this.state.fliberAdvise,
    };
    const bondDetails = [];
    const bondDetail = {};
    let oneFieldAvail = false;
    Object.keys(fields).forEach((key) => {
      if (fields[key] !== "") {
        oneFieldAvail = true;
        bondDetail[key] = fields[key];
      }
    });
    if (oneFieldAvail) {
      bondDetails.push(bondDetail);
    }
    return { bankDetails: bondDetails };
  }
  createUserInvestment(data) {
    const currentYear = getCurrentYear();
    const investmentData = this.state.investmentsData;
    const startValue = investmentData?.maturityyear
      ? investmentData.maturityyear
      : currentYear + 1;
    const maturityAge = calculate_age(startValue, currentYear, this.state.currentAge)
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    if (investmentObj.maturityyear === 0) {
      investmentObj.setMaturityYear(getCurrentYear() + 1);
      investmentObj.setMaturityAge(maturityAge)
    }
    investmentObj.setInvestmentDetails(data.bankDetails);
    const isDataValid = this.checkIsDataValid(investmentObj);
    console.log({ investmentObj })
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .createInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/bonddashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserInvestment(data) {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    if (investmentObj.maturityyear === 0) {
      investmentObj.setMaturityValue(getCurrentYear() + 1);
    }
    investmentObj.setInvestmentDetails(data.bankDetails);
    const isDataValid = this.checkIsDataValid(investmentObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .updateInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/bonddashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  onPressSaveButton() {
    const data = this.prepareBondsData();
    if (this.state.isEditView) {
      this.updateUserInvestment(data);
    } else {
      this.createUserInvestment(data);
    }
  }
  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  onChangeDescription(value) {
    const investment = this.state.investmentsData;
    investment.setInvestmentDescription(value);
    this.forceUpdate();
  }

  onChangeMaturity(value) {
    const investment = this.state.investmentsData;
    investment.setMaturityValue(value ? Number(value) : 0);
    this.forceUpdate();
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const investment = this.state.investmentsData;
    const currentYear = getCurrentYear();
    const maturityAge = calculate_age(newValue, currentYear, this.state.currentAge)
    investment.setMaturityYear(newValue ? Number(newValue) : newValue);
    investment.setMaturityAge(maturityAge)
    this.forceUpdate();
  }

  handleToggleSwitchChange(selectedItem, isSelected) {
    const investment = this.state.investmentsData;
    investment.setIsIncludeFinancialPlan(isSelected);
    this.forceUpdate();
  }

  handleFliberToggleSwitch(selectedItem, isSelected) {
    this.setState({ fliberAdvise: isSelected }, () => {
      const data = this.prepareBondsData();
      const investment = this.state.investmentsData;
      investment.setInvestmentDetails(data.bankDetails);
    });
  }

  renderNavView() {
    const items = getFinancialMarketPath("Bonds");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        dbData={this.dbData}
        localData={this.state.investmentsData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsFM3");
    return <TitleView title={title} information={""} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="bdp_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderToggleView(label, checked, onChange) {
    return (
      <div className="bdp_toggle_view">
        <label className="bdp_toggle_text">{label}</label>
        <ToggleSwitch
          checkedChildren={t("bondToggle1")}
          unCheckedChildren={t("bondToggle2")}
          onChangeToggle={onChange}
          defaultChecked={checked}
          style={{ width: "60px" }}
        />
      </div>
    );
  }

  renderBonds() {
    const investmentData = this.state.investmentsData;
    const balance = investmentData?.maturityvalue
      ? investmentData?.maturityvalue
      : "";
    const description = investmentData?.investmentdescription;
    const current = investmentData?.currentvalue
      ? investmentData?.currentvalue
      : "";
    return (
      <div className="bdp_expenselist">
        <div className="bdp_input_row">
          <div className="bdp_fir_row">
            {this.renderInputView(
              t("bondInputDescription"),
              description,
              this.onChangeDescription.bind(this)
            )}
            {this.renderInputView(
              t("Bond: Investment Amount"),
              current,
              this.onChangeInvestmentAmount.bind(this),
              true
            )}
          </div>
          <div className="bdp_sec_row">
            {this.renderInputView(
              t("Bond: Maturity Value"),
              balance,
              this.onChangeMaturity.bind(this),
              true
            )}
          </div>
        </div>
      </div>
    );
  }

  renderExpectedReturn() {
    const investmentData = this.state.investmentsData;
    const currentYear = getCurrentYear();
    const maturityyear =
      investmentData && investmentData.maturityyear
        ? investmentData.maturityyear
        : getCurrentYear() + 1;
    const age = calculate_age(maturityyear, currentYear, this.state.currentAge);
    return (
      <div className="bdp_start_year_row">
        <label className="bdp_start_text">{t("Bond: Maturity Year")}</label>
        <div className="bdp_slider_content">
          <Slider
            value={maturityyear}
            age={age}
            min={getCurrentYear() + 1}
            max={getCurrentYear() + 31}
            markStep={10}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="bdp_bottomView">
        <div className="bdp_buttonView">
          <div className="bdp_save">
            <Button
              buttonName={t("bondInputButton1")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderContentView() {
    const investment = this.state.investmentsData;
    return (
      <div className="bdp_content_view">
        {this.renderTitleView()}
        {this.renderBonds()}
        <div className="bdp_seperatorStyle" />
        {this.renderExpectedReturn()}
        <div className="bdp_seperatorStyle" />
        <div className="bdp_retirement_toggle">
          {this.renderToggleView(
            t("bondTg1Text"),
            investment?.isincludefinancialplan,
            this.handleToggleSwitchChange.bind(this)
          )}
          {this.renderToggleView(
            t("Fliber Advised"),
            this.state.fliberAdvise,
            this.handleFliberToggleSwitch.bind(this)
          )}
        </div>
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="bdp_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default BondDetailPage;
