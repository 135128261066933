import React from "react";
import "./tabselectionview.css";
import ToggleSingleSelection from "./togglesingleselection";

class TabSelectionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      activeTab: props.tabItems[0],
    };
  }

  onSelectTab(index) {
    const activeTab = this.getTabs(index);
    this.setState({ selectedIndex: index, activeTab }, () => {
      if (this.props.onSelectTabItem) {
        this.props.onSelectTabItem(activeTab, index)
      }
    });
  }

  getTabs(index) {
    const tabs = this.props.tabItems;
    return tabs[index];
  }

  renderTabView(index) {
    const tab = this.getTabs(index);
    return (
      <div className={"tsv_tabStyle"}>
        <label className={"tsv_itemStyle"}>{tab}</label>
      </div>
    );
  }

  renderSelectedTabView(index) {
    const tab = this.getTabs(index);
    return (
      <div className={"fdb_selectedTabStyle"}>
        <label className={"tsv_selectedItemStyle"}>{tab}</label>
      </div>
    );
  }

  renderTabSelectionView() {
    const { tabItems } = this.props;
    return (
      <div className="tsv_tabSelectionView">
        <ToggleSingleSelection
          noOfRows={1}
          noOfCols={tabItems.length}
          selectedIndex={this.state.selectedIndex}
          selectedContentCb={this.renderSelectedTabView.bind(this)}
          contentCb={this.renderTabView.bind(this)}
          onSelectItem={this.onSelectTab.bind(this)}
        />
      </div>
    );
  }

  renderTabContent() {
    const activeTabIndex = this.state.selectedIndex;
    const tabContents = this.props.tabContents;
    const TabComponent = tabContents[activeTabIndex];
    return <div className="tsv_tabContentView"><TabComponent /></div>
  }

  render() {
    return (
      <div className="tsv_container">
        {this.renderTabSelectionView()}
        {this.renderTabContent()}
      </div>
    );
  }
}

export default TabSelectionView;