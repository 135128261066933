import React from "react";
import './header.css';
import Menu from '../../assets/svgs/menu.svg';
import Notification from '../../assets/svgs/notification.svg';
import Help from '../../assets/svgs/help.svg';
import { t } from "i18next";
import SupportEnquiryModal from "./supportenquirymodal";
import { getSelectedUserData, getUserDetails } from "../common";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEnquiryModal: false
        };
    }

    onClickMenu() {
        if (this.props.onClickMenu) {
            this.props.onClickMenu();
        }
    }

    onClickSupport() {
        this.setState({ showEnquiryModal: true })
    }

    hideEnquiryModal() {
        this.setState({ showEnquiryModal: false })
    }

    getTitle() {
        const currentUrl = window.location.href?.split('/');
        const url = currentUrl[3];
        if (url === 'customermgmt' ||
            url === 'customerprofile' ||
            url === 'configuration' ||
            url === 'categorymgmt' ||
            url === 'assestallocationruletable' ||
            url === 'portfoliorule' ||
            url === 'loginscrn' ||
            url === 'resetpin' ||
            url === 'riskprofile') {
            return t('yourFinancialJourney');
        } else {
            const userDetail = getUserDetails();
            const displayName = userDetail !== null ? `${userDetail.firstname} ${userDetail.lastname}` : '';
            return displayName?.length > 0 ? `Financial Journey of ${displayName}` : t('yourFinancialJourney');
        }
    }

    renderMenuIconView() {
        return (
            <div
                className="h_logoView"
                onClick={this.onClickMenu.bind(this)}
            >
                <img src={Menu} alt={Menu}></img>
            </div>
        );
    }

    renderTitleView() {
        const title = this.getTitle();
        return (
            <div className="h_titleView">
                <label className="h_titleStyle">{title}</label>
            </div>
        );
    }

    renderLeftItems() {
        return (
            <div className="h_leftItemsView">
                {!this.props.hideMenu && this.renderMenuIconView()}
                {this.renderTitleView()}
            </div>
        );
    }

    renderNotificationView() {
        return (
            <div className="h_noticationView">
                <img src={Notification} alt={Notification}></img>
            </div>
        );
    }

    renderHelpNSupportView() {
        return (
            <div
                className="h_helpNsupportView"
                onClick={this.onClickSupport.bind(this)}
            >
                <label className="h_helpNsupportStyle">{t('support')}</label>
                <img src={Help} alt={Help}></img>
            </div>
        );
    }
    renderRightItems() {
        return (
            <div className="h_rightItemsView">
                {/* {this.renderNotificationView()} */}
                <div className="h_seperatorStyle" />
                {this.renderHelpNSupportView()}
            </div>
        );
    }

    renderEnquiryModal() {
        return (
            <SupportEnquiryModal
                showModal={this.state.showEnquiryModal}
                hideModal={this.hideEnquiryModal.bind(this)}
            />
        );
    }

    render() {
        return (
            <div className="h_container">
                {this.renderLeftItems()}
                {this.renderRightItems()}
                {this.state.showEnquiryModal && this.renderEnquiryModal()}
            </div>
        );
    }
}

export default Header;