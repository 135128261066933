import React from "react";
import { t } from "i18next";
import Button from "./button";
import FloatingLabelInput from "./floatinglabelinput";
import OtpView from "./otpview";
import './resetform.css';

class ResetForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailId: '',
            dob: '',
            otpArray: ["", "", "", "", "", ""],
            showVerifyOtpView: false,
            showSetNewPin: false,
            pinArray: ["", "", "", "", "", ""],
            reEnterPinArray: ["", "", "", "", "", ""],
            newPwd: '',
            reEnterNewPwd: ''
        }
    }

    onChangeEmailId(value) {
        const mailId = value.toLowerCase();
        this.setState({ emailId: mailId })
    }

    onChangeDOB(value) {
        this.setState({ dob: value })
    }

    onChangeOTP(otp) {
        this.setState({ otpArray: otp })
    }

    onChangeNewPwd(value) {
        this.setState({ newPwd: value })
    }

    onChangeReEnterNewPwd(value) {
        this.setState({ reEnterNewPwd: value })
    }

    onPressContinue() {
        if (this.props.onPressContinue) {
            this.props.onPressContinue(this.state.newPwd, this.state.reEnterNewPwd)
        }
    }

    onPressGetOTP() {
        if (this.props.onPressGetOTP) {
            this.props.onPressGetOTP(this.state.emailId, this.state.dob)
        }
    }

    onPressVerifyOTP() {
        if (this.props.onPressVerifyOTP) {
            this.props.onPressVerifyOTP(this.state.otpArray)
        }
    }

    onClickResendOtp() {
        if (this.props.onPressGetOTP) {
            this.props.onPressGetOTP(this.state.emailId, this.state.dob)
        }
    }

    renderTitleView() {
        const title = this.props.showSetNewPin ? t('setNewPassword') : t('forgotpassword');
        return (
            <div className="rf_titleView">
                <label className="rf_titleStyle">{title}</label>
            </div>
        );
    }

    renderEmailInputView() {
        return (
            <div className="rf_inputView">
                <FloatingLabelInput
                    label={t('emailID')}
                    value={this.state.emailId}
                    onChangeValue={this.onChangeEmailId.bind(this)}
                />
            </div>
        );
    }

    renderInfoView() {
        return (
            <div className="rf_infoView">
                <label className="rf_infoStyle">{t('youWillReceiveAnOTPToThisEmailIDForVerification')}</label>
            </div>
        );
    }

    renderDOBInputView() {
        return (
            <div className="rf_inputView">
                <FloatingLabelInput
                    label={t('dateOfBirth(DDMMYYYY)')}
                    value={this.state.dob}
                    type={"date"}
                    onChangeValue={this.onChangeDOB.bind(this)}
                    onPressEnterKey={this.onPressGetOTP.bind(this)}
                />
            </div>
        );
    }

    renderButtonView(buttonName, onPress) {
        return (
            <div className="rf_buttonView">
                <Button
                    buttonName={buttonName}
                    onPressButton={onPress}
                />
            </div>
        );
    }

    renderGetOtpContentView() {
        return (
            <div className="rf_contentView">
                <label className="rf_labelStyle">{t('dontWorryLetsResetYourPassword')}</label>
                {this.renderEmailInputView()}
                {this.renderInfoView()}
                {this.renderDOBInputView()}
                {this.renderButtonView(t('getOTP'), this.onPressGetOTP.bind(this))}
            </div>
        );
    }

    renderOTPView() {
        return (
            <div className="lf_otpView">
                <OtpView
                    otp={this.state.otpArray}
                    noOfInputs={6}
                    onChangeOTP={this.onChangeOTP.bind(this)}
                    onPressEnterKey={this.onPressVerifyOTP.bind(this)}
                />
            </div>
        )
    }

    renderResendLinkView() {
        return (
            <div
                className="rf_resendLinkView"
                onClick={this.onClickResendOtp.bind(this)}
            >
                <label className="rf_resendLabelStyle">{t('didtReceiveTheCode')}</label>
                <label className="rf_resendStyle">{t('resend')}</label>
            </div>
        );
    }

    renderVerifyOtpContentView() {
        return (
            <div className="rf_contentView">
                <label className="rf_verifyLabelStyle">{t("weHaveSendAVerificationCodeTo")}</label>
                <label className="rf_emailIdStyle">{this.state.emailId}</label>
                {this.renderOTPView()}
                {this.renderResendLinkView()}
                {this.renderButtonView(t('verifyOTP'), this.onPressVerifyOTP.bind(this))}
            </div>
        );
    }

    renderNewPinView() {
        return (
            <div className="rf_pinView">
                <label className="rf_pinLabelStyle">{t("pleaseEnter6DigitPINNewPIN")}</label>
                <OtpView
                    otp={this.state.pinArray}
                    noOfInputs={6}
                    showActions={true}
                    onChangeOTP={() => { }}
                />
            </div>
        );
    }

    renderReEnterPinView() {
        return (
            <div className="rf_pinView">
                <label className="rf_pinLabelStyle">{t("pleaseReEnter6DigitNewPIN")}</label>
                <OtpView
                    otp={this.state.reEnterPinArray}
                    noOfInputs={6}
                    showActions={true}
                    showResult={true}
                    isMatched={true}
                    onChangeOTP={() => { }}
                />
            </div>
        );
    }

    renderNewPwdView() {
        return (
            <div className="rf_pinView">
                <label className="rf_pinLabelStyle">{t("pleaseEnterNewPassword")}</label>
                <FloatingLabelInput
                    label={t('New password')}
                    value={this.state.newPwd}
                    onChangeValue={this.onChangeNewPwd.bind(this)}
                />
            </div>
        );
    }

    renderReEnterPwdView() {
        return (
            <div className="rf_pinView">
                <label className="rf_pinLabelStyle">{t("pleaseReEnterNewPassword")}</label>
                <FloatingLabelInput
                    label={t('password')}
                    type={"password"}
                    value={this.state.reEnterNewPwd}
                    onChangeValue={this.onChangeReEnterNewPwd.bind(this)}
                    onPressEnterKey={this.onPressContinue.bind(this)}
                />
            </div>
        );
    }

    renderSetNewPinContentView() {
        return (
            <div className="rf_contentView">
                {/* {this.renderNewPinView()}
                {this.renderReEnterPinView()} */}
                {this.renderNewPwdView()}
                {this.renderReEnterPwdView()}
                {this.renderButtonView(t('continue'), this.onPressContinue.bind(this))}
            </div>
        );
    }

    renderContentView() {
        const { showVerifyOtpView, showSetNewPin } = this.props
        return (
            <div className="rf_contentContainer">
                {!showVerifyOtpView && !showSetNewPin && this.renderGetOtpContentView()}
                {showVerifyOtpView && !showSetNewPin && this.renderVerifyOtpContentView()}
                {!showVerifyOtpView && showSetNewPin && this.renderSetNewPinContentView()}
            </div>
        );
    }

    render() {
        return (
            <div className="rf_container">
                {this.renderTitleView()}
                {this.renderContentView()}
            </div>
        );
    }
}

export default ResetForm;